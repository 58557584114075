import React from "react";

const HearthIcon = () => {
    return (
        <svg
            width="81"
            height="80"
            viewBox="0 0 81 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M45.0441 15.0578C51.6103 8.33457 62.2792 8.31112 68.8737 15.0054C75.4682 21.6997 75.4912 32.5767 68.925 39.2999L49.4938 59.1956L43.4281 65.4261C42.7234 66.15 41.8242 66.5565 40.901 66.6457C39.7127 66.7669 38.4824 66.3618 37.5744 65.4304L12.0776 39.2999C5.51145 32.5767 5.5344 21.6997 12.1289 15.0054C18.7234 8.31113 29.3924 8.33458 35.9585 15.0578L40.5013 19.7093L45.0441 15.0578Z"
                fill="#F16C60"
            />
        </svg>
    )
}

export default HearthIcon;