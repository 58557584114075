import axios from "axios";
const WEB_API = process.env.REACT_APP_WEB_API;

export const checkCouponApiCall = async ({ coupon, ...payload }) => {
    const { data } = await axios.post(`${WEB_API}/codes/check/${coupon}`, payload);
    return data;
};

export const checkCustomCouponApiCall = async ({ coupon, ...payload }) => {
    const { data } = await axios.post(`${WEB_API}/codes/check/${coupon}/custom`, payload);
    return data;
};
