import { object, string, boolean, ref } from 'yup';

import { emailRegex, passwordRegex } from '../../../../utils/validationUtils';
import {
  INVALID_PASSWORD_LENGTH,
  INVALID_PASSWORD_CHARS,
} from '../../../../constants/messages';

const validationSchema = object({
  email: string()
    .required('First Name is required')
    .matches(emailRegex, 'Must be a valid email'),
  password: string()
    .required('Password is required')
    .min(8, INVALID_PASSWORD_LENGTH)
    .matches(passwordRegex, INVALID_PASSWORD_CHARS),
  confirmPassword: string()
    .required('Confirm Password is required')
    .oneOf([ref('password')], 'Password must match'),
  newsLetter: boolean(),
}).required();

export default validationSchema;
