import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DEFAULT_APP_COUNTRY_CODE } from '../../constants/constants';
import useUserGeoLocation from '../../hooks/useUserGeoLocation';

import {
  clearAllCouponInfo,
  getCurrentSection,
  getRegistrationData,
} from '../../stores/registration';
import {
  CHECKOUT,
  FORM,
  REGISTRATION_SUMMARY,
  TFA,
} from '../../constants/routes';

import { UserDataForm, CouponsForm } from '../../components/Redeem';
import TwoFactorAuthentication from '../../components/TwoFactorAuthentication';
import RegistrationSummary from '../../components/DefaultFlow/Step3';

const RedeemMathMastersFlowContainer = () => {
  const dispatch = useDispatch();

  const section = useSelector(getCurrentSection);
  const { email, password } = useSelector(getRegistrationData);
  const { countryCode } = useUserGeoLocation({ redirect: false });

  const [coupons, setCoupons] = useState([]);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const [validated, setValidated] = useState(null);
  const [state, setState] = useState({
    email,
    password,
    country: DEFAULT_APP_COUNTRY_CODE,
    newsletter: false,
    customerExists: false,
    delayed: false,
    redeem: true,
  });

  const setStateFunc = (newState) => setState({ ...state, ...newState });

  useEffect(() => {
    return () => dispatch(clearAllCouponInfo());
  }, []);

  useEffect(() => {
    setState((prevState) => ({ ...prevState, country: countryCode }));
  }, [countryCode]);

  return (
    <>
      <div className="steps-container">
        {section === FORM && (
          <UserDataForm
            state={state}
            setState={setStateFunc}
            validated={validated}
            setValidated={setValidated}
            isMathMaster
          />
        )}
        {section === TFA && <TwoFactorAuthentication state={state} />}
        {section === CHECKOUT && (
          <CouponsForm
            state={state}
            setState={setStateFunc}
            selectedPrice={selectedPrice}
            setSelectedPrice={setSelectedPrice}
            subscription={subscription}
            setSubscription={setSubscription}
            coupons={coupons}
            setCoupons={setCoupons}
            isMathMaster
          />
        )}
        {section === REGISTRATION_SUMMARY && (
          <RegistrationSummary
            state={state}
            selectedPrice={selectedPrice}
            subscription={subscription}
            percentOff={100}
            coupons={coupons}
            isMathMasters
          />
        )}
      </div>
    </>
  );
};

export default RedeemMathMastersFlowContainer;
