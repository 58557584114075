import { object, string, ref } from 'yup';

import { emailRegex } from '../../../utils/validationUtils';

const validationSchema = object({
  firstName: string().required('First Name is required'),
  lastName: string().required('Last Name is required'),
  gifterEmail: string()
    .required('Email is required')
    .matches(emailRegex, 'Must be a valid email'),
  gifteeFirstName: string().required('Recipient First Name is required'),
  gifteeLastName: string().required('Recipient Last Name is required'),
  gifteeEmail: string()
    .required('Recipient email is required')
    .matches(emailRegex, 'Must be a valid email'),
  confirmGifteeEmail: string()
    .required('Confirm recipient email is required')
    .oneOf(
      [ref('gifteeEmail')],
      'Make sure this matches the recipients email field',
    ),
  giftMessage: string().optional(),
}).required();

export default validationSchema;
