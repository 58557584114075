export const EVENTS = {
  BEFORE_VALIDATION: 'before_validation',
  AFTER_VALIDATION: 'after_validation',
  COUPON_APPLIED_REDEEM: 'coupon_applied_redeem',
  COUPON_APPLIED_READ_ALONG: 'coupon_applied_read_along',
  COUPON_APPLIED_TRIAL_7: 'coupon_applied_trial_7',
  PRODUCT_SELECTED_SUFFIX: 'selected',
};

export const SOURCES = {
  DEFAULT_FLOW: {
    WEB: 'web-join',
    BEFORE_VALIDATION: 'before_validation_join',
    AFTER_VALIDATION: 'after_validation_join',
    STEP_ONE: 'Step_One_default_join',
    STEP_TWO: 'Step_Two_default_join',
  },
  REDEEM_FLOW: {
    WEB: 'web-redeem',
    BEFORE_VALIDATION: 'before_validation_redeem',
    AFTER_VALIDATION: 'after_validation_redeem',
    STEP_ONE: 'Step_One_redeem',
    STEP_TWO: 'Step_Two_redeem',
  },
  START_FLOW: {
    WEB: 'web-start',
    BEFORE_VALIDATION: 'before_validation_start',
    AFTER_VALIDATION: 'after_validation_start',
    STEP_ONE: 'Step_One_start',
    STEP_TWO: 'Step_Two_start',
  },
  FREE_TEACHER_FLOW: {
    WEB: 'web-free-teacher',
    BEFORE_VALIDATION: 'before_validation_free_teacher',
    AFTER_VALIDATION: 'after_validation_free_teacher',
    STEP_ONE: 'Step_One_free_teacher',
    STEP_TWO: 'Step_Two_free_teacher',
  },
  FREE_HOMESCHOOL: {
    WEB: 'web-free-homeschool',
    BEFORE_VALIDATION: 'before_validation_free_homeschool',
    AFTER_VALIDATION: 'after_validation_free_homeschool',
    STEP_ONE: 'Step_One_free_homeschool',
    STEP_TWO: 'Step_Two_free_homeschool',
  },
  READ_ALONG_FLOW: {
    WEB: 'web-read-along',
    BEFORE_VALIDATION: 'before_validation_read-along',
    AFTER_VALIDATION: 'after_validation_read-along',
    STEP_ONE: 'Step_One_read-along',
    STEP_TWO: 'Step_Two_read-along',
  },
  LIFETIME_FLOW: {
    WEB: 'web-lifetime',
    BEFORE_VALIDATION: 'before_validation_lifetime',
    AFTER_VALIDATION: 'after_validation_lifetime',
    STEP_ONE: 'Step_One_lifetime',
    STEP_TWO: 'Step_Two_lifetime',
  },
  GIFT_FLOW: {
    WEB: 'web-gift',
  },
  MATH_MASTERS_FLOW: {
    WEB: 'web-math-masters',
    BEFORE_VALIDATION: 'before_validation_redeem_math_masters',
    AFTER_VALIDATION: 'after_validation_redeem_math_masters',
    STEP_ONE: 'Step_One_redeem_math_masters',
    STEP_TWO: 'Step_Two_redeem_math_masters',
  },
};
