import React from "react";
import {useSelector} from "react-redux";
import {getAllPrices} from "../../stores/prices";
import {selectPrice} from "../../utils/planUtils";
import {roundCentsAmount} from "../../utils/currencyUtils";

const StartFlowPricesList = ({ selectedPrice, handleChange }) => {
    const prices = useSelector(getAllPrices);
    const friendsNFamilyPrice = selectPrice(prices, 'yearly_2');
    const { metadata: { slug: friendsNFamilyPriceSlug }} = friendsNFamilyPrice;

    const slug = selectedPrice ? selectedPrice.metadata.slug : null;

    return (
        <div>
            <label
                className={`radio-container radio-container--yearly ${
                    slug === friendsNFamilyPriceSlug
                        ? "radio-container--checked"
                        : ""
                }`}
                onClick={handleChange}
            >
                <input
                    type="radio"
                    name="subscription"
                    value={friendsNFamilyPriceSlug}
                    checked={slug === friendsNFamilyPriceSlug}
                    readOnly
                />
                {`${friendsNFamilyPrice.currencySymbol}${roundCentsAmount(friendsNFamilyPrice.amount)} `}
                <span className="step-time">/ Year</span>
                <span className="radio-checkmark"></span>
            </label>
        </div>
    )
}

export default StartFlowPricesList;
