import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Text } from '@chakra-ui/react';

import { REGISTRATION_SUMMARY } from '../../constants/routes';
import { EVENTS, SOURCES } from '../../constants/events';
import { DONT_FORGET_TO_APPLY_CODE } from '../../constants/messages';
import { USER_CREATION_ENDPOINT } from '../../constants/backend-sources';
import { eraseCookie } from '../../utils/cookies';
import useCustomToast from '../../lib/hooks/toast';
import useUTMs from '../../lib/hooks/useUTMs';
import { klaviyoEvent } from '../../utils/events';
import {
  DEFAULT_REDEEM_SLUG,
  MATH_MASTERS_COUNTRY_CODE,
  VOOKS_LOGIN_FORCELOGOUT_URL,
} from '../../constants/constants';

import { getPrices, getPricesState } from '../../stores/prices';
import {
  clearAllCouponInfo,
  createSubscription,
  getCouponInfoState,
  getCreateSubscriptionState,
  goToSection,
} from '../../stores/registration';

import CheckoutForm from './CheckoutForm';
import Loading from '../Loading/Loading';
import Spinner from '../Spinner/Spinner';
import UserForm from '../UserForm';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const CouponsForm = ({
  state,
  setState,
  selectedPrice,
  setSelectedPrice,
  setSubscription,
  coupons,
  setCoupons,
  isMathMaster,
}) => {
  const UTMs = useUTMs();

  const screenStep = isMathMaster ? 2 : 3;
  const { REDEEM_FLOW, MATH_MASTERS_FLOW } = SOURCES;
  const { email, password, country, newsletter } = state;
  const userToken = localStorage.getItem('userToken');
  const COOKIE_DOMAIN = process.env.REACT_APP_COOKIE_DOMAIN;
  const SUCCESS_REDIRECT_MM =
    process.env.REACT_APP_WEB + VOOKS_LOGIN_FORCELOGOUT_URL;

  const {
    prices,
    loading: loadingPrices,
    error: pricesError,
  } = useSelector(getPricesState);
  const { coupon: currentCoupon } = useSelector(getCouponInfoState);
  const { fulfilled, error, loading } = useSelector(getCreateSubscriptionState);

  const dispatch = useDispatch();
  const toast = useCustomToast();

  const [showSpinner, setShowSpinner] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();


  const handleChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = name === 'newsletter' ? target.checked : target.value;
    if (name === 'subscription') {
      setSelectedPrice(DEFAULT_REDEEM_SLUG);
      return;
    }
    setState({ [name]: value });
  };

  useEffect(() => {
    setShowSpinner(loading);
  }, [loading]);

  const wipeTokenAndRedirect = () => {
    eraseCookie('token', COOKIE_DOMAIN);
    window.location.href = SUCCESS_REDIRECT_MM;
  };

  useEffect(() => {
    if (error) toast({ type: 'error', message: error.message, duration: 5000 });
  }, [error]);

  useEffect(() => {
    if (fulfilled) {
      setSubscription(fulfilled.subscription);
      klaviyoEvent(email, password, EVENTS.COUPON_APPLIED_REDEEM, {
        email,
        coupons: coupons?.join(','),
        uuid: fulfilled?.user?.uuid,
        subscriptionType: fulfilled?.subscription?.plan?.interval,
        subscriptionEndDate: fulfilled?.subscription?.current_period_end,
        source: isMathMaster
          ? MATH_MASTERS_FLOW.STEP_TWO
          : REDEEM_FLOW.STEP_TWO,
        ...UTMs,
      });

      !userToken
        ? dispatch(goToSection(REGISTRATION_SUMMARY))
        : wipeTokenAndRedirect();
    }
  }, [fulfilled]);

  const handleSubmit = async (couponInput) => {
    if (
      coupons?.every((coupon) => coupon.name !== couponInput) &&
      !!couponInput
    ) {
      toast({
        type: 'error',
        message: DONT_FORGET_TO_APPLY_CODE,
        duration: 5000,
      });
      return;
    }

    const token = await executeRecaptcha('submit');


    dispatch(
      createSubscription({
        payload: {
          email,
          password,
          password_confirmation: password,
          join_newsletter: newsletter,
          country_code: isMathMaster ? MATH_MASTERS_COUNTRY_CODE : country,
          coupons: coupons.map(({ name }) => name),
          source: isMathMaster ? MATH_MASTERS_FLOW.WEB : REDEEM_FLOW.WEB,
          UTMs,
          userToken,
          token,
        },
        userCreationEndpoint: USER_CREATION_ENDPOINT.CUSTOM_REDEEM,
      }),
    );
  };

  useEffect(() => {
    klaviyoEvent(email, password, EVENTS.AFTER_VALIDATION, {
      email,
      source: isMathMaster
        ? MATH_MASTERS_FLOW.AFTER_VALIDATION
        : REDEEM_FLOW.AFTER_VALIDATION,
      ...UTMs,
    });
  }, []);

  useEffect(() => {
    dispatch(getPrices(country));
  }, [country]);

  useEffect(() => {
    dispatch(clearAllCouponInfo());
  }, [prices]);

  useEffect(() => {
    if (currentCoupon && prices) setSelectedPrice(DEFAULT_REDEEM_SLUG);
  }, [currentCoupon, prices]);

  if (pricesError) return null;

  return (
    <div>
      <div className="hero">
        <Text
          fontFamily="proxima-soft"
          fontSize="32px"
          fontWeight="800"
          margin="8px"
          lineHeight="110%"
        >
          Join 1 Million Readers
        </Text>
        <span>Kid-safe Ages 2-8, No Ads</span>
      </div>
      <div className="step">
        {showSpinner && <Loading />}
        <h2>{`Step ${screenStep} of ${screenStep}`}</h2>
        <div className="form">
          {!isMathMaster && (
            <>
              <UserForm handleChange={handleChange} country={country} />
              <hr className="topic-divider" />
            </>
          )}
          <Box mt="12px">
            {loadingPrices && (
              <div className="centered-spinner">
                <Spinner className="spinner-non-fixed" />
              </div>
            )}
            {!loadingPrices && (
              <CheckoutForm
                prices={prices}
                state={state}
                setState={setState}
                selectedPrice={selectedPrice}
                setSelectedPrice={setSelectedPrice}
                setShowSpinner={setShowSpinner}
                handleSubmit={handleSubmit}
                handleChange={handleChange}
                coupons={coupons}
                setCoupons={setCoupons}
              />
            )}
          </Box>
        </div>
      </div>
    </div>
  );
};

export default CouponsForm;
