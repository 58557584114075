import {finishSubscriptionCreation} from "../api/subscriptions";

export const completeRegistration = async ({email, password, country, subscription, UTMs}) => {
    try {
        await finishSubscriptionCreation({
            email,
            password,
            stripe_customer_id: subscription?.customer,
            stripe_subscription_id: subscription?.id,
            payment_method_id: subscription?.latest_invoice?.payment_intent?.payment_method,
            country_code: country,
            ...UTMs,
        });
        return true;
    } catch (error) {
        return false;
    }
};
