import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

import amazonRegistrationReducer from './amazonRegistration';
import TFAReducer from './TFA';
import registrationReducer from './registration';
import pricesReducer from './prices';
import stripeReducer from './stripe';
import giftingReducer from './gifting';

import { configureStore } from '@reduxjs/toolkit';

const registrationPersistConfig = {
    key: 'amazonRegistration',
    storage,
    whitelist: ['data'],
};

const TFAPersistConfig = {
    key: 'TFA',
    storage,
    whitelist: ['requestRestrictions'],
}

const reducer = combineReducers({
    amazonRegistration: persistReducer(registrationPersistConfig, amazonRegistrationReducer),
    registration: registrationReducer,
    TFA: persistReducer(TFAPersistConfig, TFAReducer),
    prices: pricesReducer,
    stripe: stripeReducer,
    gifting: giftingReducer
});

export const initializeStore = () =>
    configureStore({
        reducer,
        middleware: [thunk],
        devTools: process.env.NODE_ENV !== 'production'
    });

