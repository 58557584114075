import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from '@chakra-ui/react';

const InputForm = ({
  id,
  type,
  label,
  register,
  control,
  placeholder,
  errors,
  required,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const value = useWatch({ control, name: id });

  const error = errors[id];

  const isLabelMinified = isFocused || !!value || placeholder;

  return (
    <FormControl
      isInvalid={errors[id]}
      id={id}
      position="relative"
      fontFamily="proxima-nova"
      mb="2"
      {...props}
    >
      <FormLabel
        htmlFor={id}
        position="absolute"
        top={isLabelMinified ? '12px' : '20px'}
        left="4"
        color={error ? 'errorText' : 'label'}
        fontSize={isLabelMinified ? '10px' : '16px'}
        lineHeight={isLabelMinified ? '12px' : 'initial'}
        p="0"
        m="0"
        transition="top 0.2s, font-size 0.2s"
        zIndex={10}
      >
        {`${label}${required ? '*' : ''}`}
      </FormLabel>
      <Input
        id={id}
        onFocus={() => setIsFocused(true)}
        {...register(id, { onBlur: () => setIsFocused(false) })}
        type={type}
        fontSize="md"
        bg={error && 'errorBg'}
        color={error && 'errorText'}
        borderColor={error && 'error'}
        w="100%"
        h="56px"
        pt="16px"
        placeholder={placeholder}
        _focus={{ color: 'primary' }}
        _placeholder={{ color: error ? 'errorText' : 'placeholder' }}
      />
      <FormErrorMessage fontSize="xs" ml="2" color="errorText">
        {error && error.message}
      </FormErrorMessage>
    </FormControl>
  );
};

InputForm.defaultProps = {
  label: '',
  errors: {},
  type: 'text',
  required: false,
  placeholder: '',
  defaultValue: '',
};

InputForm.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string,
  label: PropTypes.string,
  register: PropTypes.func.isRequired,
  control: PropTypes.shape().isRequired,
  errors: PropTypes.shape({}),
  required: PropTypes.bool,
  placeholder: PropTypes.string,
};

export default InputForm;
