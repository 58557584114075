// Payment options

import {
  NO_COMMITMENT_CA,
  PURCHASE_AND_RECEIVE_CODES_TO_SHARE,
} from './messages';

export const LIFETIME_DISABLED =
  process.env.REACT_APP_LIFETIME_FLOW_DISABLED === 'true';

export const FREE_TEACHER_DISABLED =
  process.env.REACT_APP_FREE_TEACHER_DISABLED === 'true';

export const FREE_HOMESCHOOL_DISABLED =
  process.env.REACT_APP_FREE_HOMESCHOOL_DISABLED === 'true';

export const DEFAULT_APP_COUNTRY_CODE = 'US';

export const COMMON_PAY = 'common';
export const AMAZON_PAY = 'amazon_pay';

// TFA
export const MAX_TFA_CODE_LENGTH = 6;

// Form options

export const PRE_K = 'Pre-K';
export const KINDERGARTEN = 'Kindergarten';

// Formatting
export const DATE_FORMAT = 'MMMM DD, YYYY';

// Free teacher form options

export const FREE_TEACHER_GRADE_1 = 'Grade 1 (6-7)';
export const FREE_TEACHER_GRADE_2 = 'Grade 2 (7-8)';
export const FREE_TEACHER_GRADE_3 = 'Grade 3 (8-9)';
export const FREE_TEACHER_GRADE_4 = 'Grade 4 (9-10)';
export const FREE_TEACHER_GRADE_5_N_UP = 'Grade 5 and up (10+)';
export const FREE_TEACHER_MULTIPLE_GRADE_LEVELS = 'Multiple Grade Levels';
export const FREE_TEACHER_OTHER = 'Other';

export const STUDENTS_1_4 = '1-4 students';
export const STUDENTS_5_10 = '5-10 students';
export const STUDENTS_11_20 = '11-20 students';
export const STUDENTS_21_30 = '21-30 students';
export const STUDENTS_31_MORE = '31 or more students';

export const TEACHERS_FREE_GRADE_LEVEL = [
  { key: 'PRE_K', value: PRE_K, label: PRE_K },
  { key: 'KINDERGARTEN', value: KINDERGARTEN, label: KINDERGARTEN },
  { key: 'GRADE_1', value: FREE_TEACHER_GRADE_1, label: FREE_TEACHER_GRADE_1 },
  { key: 'GRADE_2', value: FREE_TEACHER_GRADE_2, label: FREE_TEACHER_GRADE_2 },
  { key: 'GRADE_3', value: FREE_TEACHER_GRADE_3, label: FREE_TEACHER_GRADE_3 },
  { key: 'GRADE_4', value: FREE_TEACHER_GRADE_4, label: FREE_TEACHER_GRADE_4 },
  {
    key: 'GRADE_5_N_UP',
    value: FREE_TEACHER_GRADE_5_N_UP,
    label: FREE_TEACHER_GRADE_5_N_UP,
  },
  {
    key: 'MULTIPLE_GRADE_LEVELS',
    value: FREE_TEACHER_MULTIPLE_GRADE_LEVELS,
    label: FREE_TEACHER_MULTIPLE_GRADE_LEVELS,
  },
  { key: 'OTHER', value: FREE_TEACHER_OTHER, label: FREE_TEACHER_OTHER },
];

export const TEACHERS_FREE_STUDENTS_PER_CLASS = [
  { key: 'STUDENTS_1_4', value: STUDENTS_1_4, label: STUDENTS_1_4 },
  { key: 'STUDENTS_5_10', value: STUDENTS_5_10, label: STUDENTS_5_10 },
  { key: 'STUDENTS_11_20', value: STUDENTS_11_20, label: STUDENTS_11_20 },
  { key: 'STUDENTS_21_30', value: STUDENTS_21_30, label: STUDENTS_21_30 },
  { key: 'STUDENTS_31_MORE', value: STUDENTS_31_MORE, label: STUDENTS_31_MORE },
];

// Free homeschool form options

export const STUDENTS_1 = '1';
export const STUDENTS_2 = '2';
export const STUDENTS_3 = '3';
export const STUDENTS_4 = '4';
export const STUDENTS_5p = '5+';

export const FREE_HOMESCHOOL_GRADE_1 = '1st Grade';
export const FREE_HOMESCHOOL_GRADE_2 = '2nd Grade';
export const FREE_HOMESCHOOL_GRADE_3 = '3rd Grade';
export const FREE_HOMESCHOOL_GRADE_4 = '4th Grade';
export const FREE_HOMESCHOOL_GRADE_5_N_UP = '5th Grade or above';

export const HEARD_FROM_FRIEND = 'Friend/word of mouth';
export const HEARD_FROM_SEARCH = 'Search';
export const HEARD_FROM_SOCIAL_MEDIA = 'Social media';
export const HEARD_FROM_EMAIL = 'Email';
export const HEARD_FROM_SCHOOL = 'School';
export const HEARD_FROM_ADVERTISEMENT = 'Advertisement';
export const HEARD_FROM_OTHER = 'Other';

export const HOMESCHOOL_STUDENTS_PER_CLASS = [
  { key: 'STUDENTS_1', value: STUDENTS_1, label: STUDENTS_1 },
  { key: 'STUDENTS_2', value: STUDENTS_2, label: STUDENTS_2 },
  { key: 'STUDENTS_3', value: STUDENTS_3, label: STUDENTS_3 },
  { key: 'STUDENTS_4', value: STUDENTS_4, label: STUDENTS_4 },
  { key: 'STUDENTS_5p', value: STUDENTS_5p, label: STUDENTS_5p },
];

export const HOMESCHOOL_GRADE_LEVEL = [
  { value: PRE_K, label: PRE_K },
  { value: KINDERGARTEN, label: KINDERGARTEN },
  { value: FREE_HOMESCHOOL_GRADE_1, label: FREE_HOMESCHOOL_GRADE_1 },
  { value: FREE_HOMESCHOOL_GRADE_2, label: FREE_HOMESCHOOL_GRADE_2 },
  { value: FREE_HOMESCHOOL_GRADE_3, label: FREE_HOMESCHOOL_GRADE_3 },
  { value: FREE_HOMESCHOOL_GRADE_4, label: FREE_HOMESCHOOL_GRADE_4 },
  { value: FREE_HOMESCHOOL_GRADE_5_N_UP, label: FREE_HOMESCHOOL_GRADE_5_N_UP },
];

export const HEARD_FROM = [
  {
    key: 'HEARD_FROM_FRIEND',
    value: HEARD_FROM_FRIEND,
    label: HEARD_FROM_FRIEND,
  },
  {
    key: 'HEARD_FROM_SEARCH',
    value: HEARD_FROM_SEARCH,
    label: HEARD_FROM_SEARCH,
  },
  {
    key: 'HEARD_FROM_SOCIAL_MEDIA',
    value: HEARD_FROM_SOCIAL_MEDIA,
    label: HEARD_FROM_SOCIAL_MEDIA,
  },
  { key: 'HEARD_FROM_EMAIL', value: HEARD_FROM_EMAIL, label: HEARD_FROM_EMAIL },
  {
    key: 'HEARD_FROM_SCHOOL',
    value: HEARD_FROM_SCHOOL,
    label: HEARD_FROM_SCHOOL,
  },
  {
    key: 'HEARD_FROM_ADVERTISEMENT',
    value: HEARD_FROM_ADVERTISEMENT,
    label: HEARD_FROM_ADVERTISEMENT,
  },
  { key: 'HEARD_FROM_OTHER', value: HEARD_FROM_OTHER, label: HEARD_FROM_OTHER },
];

// Default values

export const DEFAULT_REDEEM_YEARLY_PRICE_SLUG = 'yearly_1';
export const DEFAULT_REDEEM_MONTHLY_PRICE_SLUG = 'monthly_1';
export const DEFAULT_FREE_TEACHERS_PRICE_SLUG = 'teacher_1';
export const DEFAULT_FREE_HOMESCHOOL_PRICE_SLUG = 'homeschool_1';
export const DEFAULT_READ_ALONG_PRICE_SLUG = 'monthly_1';
export const DEFAULT_REDEEM_SLUG = 'promotional';

// Plan presets for future changes and decoration

export const PLAN_PRICES_PRESETS = {
  yearly_3: {
    title: 'Teachers & student',
    description: {
      common: 'Buy 1 give 35',
    },
    bestDeal: false,
    score: 2,
    detail: {
      common: PURCHASE_AND_RECEIVE_CODES_TO_SHARE,
      with_coupon: NO_COMMITMENT_CA,
    },
    color: '#805AD5',
    eventSlug: 'teachers_and_students',
  },
  yearly_2: {
    title: 'Friends & family',
    description: {
      common: 'Buy 1 give 5',
    },
    bestDeal: true,
    score: 3,
    detail: {
      common: PURCHASE_AND_RECEIVE_CODES_TO_SHARE,
      with_coupon: 'Billed Annually',
    },
    color: '#63B3ED',
    eventSlug: 'friends_and_family',
  },
  yearly_1: {
    title: 'Friends & family',
    description: {
      common: 'Buy 1 give 5',
    },
    bestDeal: true,
    score: 3,
    detail: {
      common: PURCHASE_AND_RECEIVE_CODES_TO_SHARE,
      with_coupon: 'Billed Annually',
    },
    color: '#63B3ED',
  },
  monthly_1: {
    title: 'Individual',
    description: {
      common: '',
    },
    bestDeal: false,
    score: 1,
    detail: {
      common: NO_COMMITMENT_CA,
      with_coupon: 'Billed Monthly',
    },
    color: 'tomato',
  },
  generic: {
    title: 'Subscription',
    description: {
      common: '',
      with_coupon: '',
    },
    bestDeal: false,
    score: 1,
    detail: {
      common: '',
    },
    color: 'gray',
  },
};

export const MATH_MASTERS_NOT_ALLOWED_COUNTRIES = ['KR'];
export const MATH_MASTERS_COUNTRY_CODE = 'KR';

export const VOOKS_LOGIN_FORCELOGOUT_URL = '/login?forceLogout=true';

export const KLAVIYO_DISABLED = process.env.REACT_APP_KLAVIYO_DISABLED === 'true';