import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    data: {
        email: null,
        password: null,
        plan: {
            interval: null,
            amount: null,
            hasTrial: null,
        }
    }
};

const amazonRegistrationSlice = createSlice({
    name: 'amazonRegistration',
    initialState,
    reducers: {
        setRegistrationData(state, {payload}) {
            return {
                ...state,
                data: {
                    ...state.data,
                    ...payload,
                }
            };
        },
        flushRegistrationData() {
            return initialState;
        },
    }
});

const {
    reducer,
    actions: { setRegistrationData, flushRegistrationData }
} = amazonRegistrationSlice;

export const getRegistrationState = state => state.amazonRegistration;
export const getRegistrationData = state => getRegistrationState(state).data;

export { setRegistrationData, flushRegistrationData }

export default reducer;
