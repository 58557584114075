import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {wrapObject} from "../../utils/objectWrapper";
import {requestTFATokenAPI, verifyTFATokenAPI} from "../../api/TFA";
import {TFA_CODE_REQUEST_FAILED, TFA_CODE_VERIFICATION_FAILED} from "../../constants/messages";
import {getNextTryAttempt, getNextTryDate, getTriesExpirationDate} from "../../utils/TFA";

export const requestTFAToken = createAsyncThunk(
    'TFA/requestTFAToken',
    async (payload, thunkAPI) => {
        try {
            const { data } = await requestTFATokenAPI(payload);
            thunkAPI.dispatch(clearVerificationState());
            return data;
        } catch (e) {
            if(e.response?.status === 429){
                return thunkAPI.rejectWithValue({ message: e.response.data.error.details.message || 'Too many request, please try again later' });
            }

            if(e.response?.data?.length){
                return thunkAPI.rejectWithValue(wrapObject(e, { message: 'response.data' }));
            }

            return thunkAPI.rejectWithValue({ message: TFA_CODE_REQUEST_FAILED});
        }
    },
);

export const verifyTFAToken = createAsyncThunk(
    'TFA/verifyTFAToken',
    async (payload, thunkAPI) => {
        try {
            const { data: { valid }, data } = await verifyTFATokenAPI(payload);
            return valid ? data : thunkAPI.rejectWithValue({ message: TFA_CODE_VERIFICATION_FAILED});
        } catch (e) {
            return thunkAPI.rejectWithValue(wrapObject(e, { message: 'response.data.error.details' }));
        }
    },
);

const initialRequestRestriction = {
    tries: 0,
    triesExpirationDate: null,
    nextTryDate: null,
}

const initialState = {
    request: {
        fulfilled: null,
        loading: false,
        error: null,
    },
    requestRestrictions: {},
    verification: {
        fulfilled: null,
        loading: false,
        error: null,
    },
};

const registrationSlice = createSlice({
    name: 'TFA',
    initialState,
    reducers: {
        clearRequestRestrictionsState(state, { payload }) {
            return {
                ...state,
                requestRestrictions: {
                    ...state.requestRestrictions,
                    [payload]: initialRequestRestriction,
                },
            };
        },
        clearRequestState(state) {
            return {
                ...state,
                request: initialState.request,
            };
        },
        clearVerificationState(state) {
            return {
                ...state,
                verification: initialState.verification,
            };
        },
        clearTFAState() {
            return initialState;
        },
    },
    extraReducers: builder => {
        builder.addCase(requestTFAToken.pending, state => {
            state.request = {
                ...state.request,
                loading: true,
            };
        });
        builder.addCase(requestTFAToken.fulfilled, (state, { payload, payload: { to: email} }) => {
            state.request = {
                ...state.request,
                loading: false,
                fulfilled: payload,
                error: null,
            };
            const currentTries = getNextTryAttempt({
                triesExpirationDate: state.requestRestrictions?.[email]?.triesExpirationDate,
                tries: state?.requestRestrictions?.[email]?.tries
            });
            state.requestRestrictions = {
                ...state.requestRestrictions,
                [email]: {
                    triesExpirationDate: getTriesExpirationDate(),
                    nextTryDate: getNextTryDate(currentTries),
                    tries: currentTries
                },
            };
        });
        builder.addCase(requestTFAToken.rejected, (state, { payload }) => {
            state.request = {
                ...state.request,
                loading: false,
                error: payload,
                fulfilled: null,
            };
        });
        builder.addCase(verifyTFAToken.pending, state => {
            state.verification = {
                ...state.verification,
                loading: true,
            };
        });
        builder.addCase(verifyTFAToken.fulfilled, (state, { payload }) => {
            state.verification = {
                loading: false,
                fulfilled: payload,
                error: null,
            };
        });
        builder.addCase(verifyTFAToken.rejected, (state, { payload }) => {
            state.verification = {
                loading: false,
                fulfilled: null,
                error: payload,
            };
        });
    }
});

const { reducer, actions: { clearRequestState, clearVerificationState, clearTFAState, clearRequestRestrictionsState } } = registrationSlice;

export const getTFAState = state => state.TFA;
export const getRequestState = state => getTFAState(state).request;
export const getRequestRestrictionsState = state => getTFAState(state).requestRestrictions;
export const getVerificationState = state => getTFAState(state).verification;

export { clearRequestState, clearVerificationState, clearTFAState, clearRequestRestrictionsState };

export default reducer;
