import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Stack,
  Text,
} from '@chakra-ui/react';

const DuplicatedAttemptModal = ({ isOpen, onClose, onContinue }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      blockScrollOnMount={false}
      isCentered
    >
      <ModalOverlay />
      <ModalContent borderRadius={10} w="20.43rem" h="fit-content">
        <ModalHeader
          mt={4}
          fontSize="xl"
          textAlign="center"
          fontWeight="800"
          pb={0}
          display="flex"
          justifyContent="center"
        >
          Warning
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          display="flex"
          flexDirection="column"
          alignItems="center"
          pb="0"
        >
          <Stack w="16.438rem" mb="1.5rem">
            <Text textAlign="center">
              <p>You already attempted to gift a suscription to this email address.  Continue to send your gift or cancel to review the information.</p>
            </Text>
          </Stack>
        </ModalBody>
        <ModalFooter alignSelf="center" py="0" mb="1.5rem">
          <Stack direction="row" spacing="1rem">
            <Button
              width="5.563rem"
              variant="outline"
              onClick={onClose}
              h="auto"
              py="3"
              fontSize="lg"
              fontWeight="800"
              lineHeight="none"
              rounded="full"
              bg="white"
              color="gray"
              _hover={{ bg: 'highlightBg' }}
              _focus={{ outline: 'none' }}
            >
              Cancel
            </Button>
            <Button
              width="5.438rem"
              variant="outline"
              onClick={onContinue}
              h="auto"
              py="3"
              fontSize="lg"
              fontWeight="800"
              lineHeight="none"
              rounded="full"
              bg="highlight"
              color="white"
              _hover={{ bg: 'hover' }}
              _focus={{ outline: 'none' }}
            >
              Next
            </Button>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
};

DuplicatedAttemptModal.propTypes = {
  isOpen: PropTypes.func,
  onClose: PropTypes.func,
  onContinue: PropTypes.func,
}

export default DuplicatedAttemptModal;
