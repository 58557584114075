import { roundCentsAmount } from '../currencyUtils';
import { isLifetimePrice } from '../planUtils';

export const getLegalTermsDecoration = ({
  amount,
  interval,
  currencySymbol,
  currency,
}) => {
  return ` By clicking below you agree you are enrolling in automatic payments of
          ${currencySymbol}${roundCentsAmount(
    amount
  )} ${currency.toUpperCase()} (plus any tax) per ${interval} until your
          subscription is cancelled. You can cancel at any time, effective at
          the end of the billing period. Refunds are available upon request and at our discretion within ${
            interval === 'month' ? '7' : '15'
          } days of the transaction for ${interval}ly subscriptions`;
};

export const getLifetimeLegalsDecoration = (selectedPrice) => {
  if (!isLifetimePrice(selectedPrice))
    return getLegalTermsDecoration(selectedPrice);
  const { amount, currencySymbol, currency } = selectedPrice;
  return `With your Lifetime subscription,
        you will have full Lifetime access to the Vooks Library, which features hundreds of entertaining children’s storybooks.
        By clicking below, you agree to pay a one-time payment of ${currencySymbol}${roundCentsAmount(
    amount
  )} ${currency.toUpperCase()},
        plus any applicable taxes, for the Lifetime subscription. Payment will be charged to your account at the confirmation of purchase.
        Lifetime subscriptions are subject to Vooks’ terms and conditions. Refunds are available upon request and at our discretion within 48 hours for lifetime subscriptions.`;
};

export const getOneTimePaymentDecoration = ({
  amount,
  currencySymbol,
  currency,
}) => {
  return `With your 1 Year subscription, you will have access to the Vooks Library, which features hundreds of entertaining children’s storybooks.
        By clicking below, you agree to a one-time payment of ${currencySymbol}${roundCentsAmount(
    amount
  )} ${currency.toUpperCase()},
        plus any applicable taxes, for the 1 Year Subscription. Payment will be charged to your account at the confirmation of purchase.
        One-time payment subscriptions are subject to Vooks’ terms and conditions. Refunds are available upon request and at our discretion within 48 hours.`;
};

export const getRedeemDecoration = () =>{
return `By clicking below you agree that your subscription will be automatically canceled at the end of the promotion period. You can subscribe at any time to prevent your account from being automatically canceled.`

}