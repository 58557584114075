import { backendClient } from '../lib/axios';
import {
  COMPLETE_USER_CREATION,
  REGISTER,
  USER_CREATION_ENDPOINT,
} from '../constants/backend-sources';

export const createSubscriptionApiCall = async ({
  payload,
  userCreationEndpoint = USER_CREATION_ENDPOINT.DEFAULT,
}) => {
  const userToken = payload.userToken;
  delete payload.userToken;
  if (userToken) {
    return backendClient.post(`${REGISTER}/${userCreationEndpoint}`, payload, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
  }
  return backendClient.post(`${REGISTER}/${userCreationEndpoint}`, payload);
};

export const finishSubscriptionCreation = async (payload) =>
  backendClient.post(COMPLETE_USER_CREATION, payload);

export const fetchCurrentSubscription = async (token) => {
  const currentSubscriptionResponse = await backendClient.get(
    '/subscriptions/current',
    {
      headers: { Authorization: `Bearer ${token}` },
    },
  );
  return currentSubscriptionResponse?.data;
};
