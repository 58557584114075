const colors = {
  background: '#ffffff',
  disabled: '#eceff1',
  text: '#000000',
  disabledText: '#a0b1b9',
  disabledButton: '#494f50',
  primary: '#1c2428',
  secondary: '#03a9f4',
  highlight: '#2452f6',
  highlightLt: '#e1f5fe',
  highlightBg: '#e5f0ff',
  highlightPlaceholder: '#9ec0f3',
  favorite: '#ef6d64',
  placeholder: '#79909b',
  hover: '#103083',
  success: '#6a9e3f',
  error: '#c22e2d',
  errorText: '#d8453e',
  errorBg: '#fff5f5',
  errorHighlight: '#ef6c00',
  delete: '#f16c60',
  promoCodeText: '#01589b',
  label: '#465a63',
  formText: '#2d3748',
  border: '#cfd8dc',
  light: '#91a4ad',
  playerBg: '#263238',
  title: '#286abe',
  discount: '#f1f8ea',
  discountBorder: '#8cc252',
  deal: '#5383ff',
  warning: '#fb8c00',
  cyanCustom: '#6b9dfd',
  standardPlan: '#103083',
  standardPlanBg: '#E5F0FF',
  subscribeNowBanner: '#6B9DFD',
  currentPlanBanner: '#E5F0FF',
  activePlanLabelBg: '#E0F2F1',
  activePlanLabelTxt: '#13887B',
  expiredPlanLabelBg: '#FFF5F5',
  expiredPlanLabelTxt: '#C22E2D',
  vooksBlue: '#0071E2',
};

export default colors;
