import { object, string } from 'yup';

const cardNumberRegexp =
  /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6(?:011|5[0-9]{2})[0-9]{12}|3[47][0-9]{13})$/;
const cardExpiryDateRegexp = /^(0[1-9]|1[0-2])\/([0-9]{2})$/;
const cardCVVRegexp = /^[0-9]{3,4}$/;

const validationSchema = object({
  cardName: string().required('Card name is required'),
  cardNumber: string()
    .required('Card number is required')
    .test('test-card', 'Must be a valid credit card number', (value) =>
      cardNumberRegexp.test(value.replace(/\s/g, '')),
    ),
  cardExpiryDate: string()
    .required('Card expiration date is required')
    .matches(cardExpiryDateRegexp, 'Must be a valid expiration date'),
  cardCvc: string()
    .required('Card security code is required')
    .matches(cardCVVRegexp, 'Must be a valid security code'),
}).required();

export default validationSchema;
