import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormLabel, Checkbox, Flex } from '@chakra-ui/react';

const CheckboxForm = ({ id, label, register, ...props }) => {
  return (
    <FormControl id={id}>
      <Flex alignItems="center" mb={4} {...props}>
        <Checkbox {...register(id)} mr={2} size="lg" color="vooksBlue" />
        <FormLabel htmlFor={id} m={0} fontSize="sm">
          {label}
        </FormLabel>
      </Flex>
    </FormControl>
  );
};

CheckboxForm.defaultProps = {
  label: '',
};

CheckboxForm.propTypes = {
  id: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  label: PropTypes.string,
};

export default CheckboxForm;
