export const LENGTH_STANDARDS = {
    MAX_ZIPCODE_LENGTH: 10,
    MIN_ZIPCODE_LENGTH: 5,
    MAX_STRING_LENGTH: 254,
    MAX_NOUN_STRING_LENGTH: 50,
};

export const COUNTRIES_ZIP_REQUIRED = ['US'];

export const COUPON_CONTEXTS = {
    READ_ALONG_COUPON_CONTEXT: 'read-along',
    DEFAULT_COUPON_CONTEXT: 'default',
};

export const QUERY_PARAMS_IDS = {
    UTM: {
        UTM_SOURCE: 'utm_source',
        UTM_MEDIUM: 'utm_medium',
        UTM_CAMPAIGN: 'utm_campaign',
        UTM_ID: 'utm_id',
        UTM_TERM: 'utm_term',
        UTM_CONTENT: 'utm_content',
        GCLID: 'gclid',
    },
    PROMO: 'coupon',
    CODE: 'code',   
    GIFT_CODE: 'giftCode',   
};
