// External routes

export const VOOKS_URL = 'https://vooks.com/';
export const VOOKS_RESOURCES_URL = 'https://www.vooks.com/pages/resources';

// Routes

export const LANDING = '/';
export const START = '/start';
export const REDEEM = '/redeem';
export const REDEEM_MATH_MASTERS = '/redeem/korea';
export const FREE_TEACHER = '/free-teacher';
export const FREE_HOMESCHOOL = '/free-homeschool';
export const LIFETIME = '/lifetime';
export const READ_ALONG = '/read-along';
export const AMAZON_CHECKOUT = '/amazon-pay';
export const AMAZON_REGISTRATION_SUMMARY = '/amazon-pay/registration-summary';
export const GIFT = '/gift';
export const GIFT_REDEEM = `${GIFT}-redeem`;
export const NOT_FOUND = '/404';

// Sections

export const FORM = 'form';
export const TFA = '2fa';
export const CHECKOUT = 'checkout';
export const REGISTRATION_SUMMARY = 'registration_summary';

// Gifting section

export const GIFT_USERS_DATA = 'gift_users_data';
export const GIFT_PAYMENT = 'gift_payment';
export const GIFT_SUCCESS = 'gift_success';
export const GIFT_REDEEM_CODE = 'gift_redeem_code';
