import { checkCustomer } from '../api/customers';
import {
  CUSTOMER_ALREADY_EXISTS,
  INVALID_EMAIL,
  INVALID_PASSWORD_CHARS,
  INVALID_PASSWORD_LENGTH,
  NO_CC_CUSTOMER_NAME,
  COUPON_APPLY_ERROR,
  COUPON_APPLY_UNAVAILABLE,
} from '../constants/messages';
import { HOMESCHOOL_GRADE_LEVEL } from '../constants/constants';
import {
  COUNTRIES_ZIP_REQUIRED,
  LENGTH_STANDARDS,
} from '../constants/standards';

export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const passwordRegex =
  /^(?=.*[!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~\s])[A-Za-z\d!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~\s]{8,}$/;

export const validateEmail = (email) =>
  emailRegex.test(String(email).toLowerCase());

export const validatePasswordChars = (password) =>
  passwordRegex.test(String(password));

  export const validateLoginPasswordChars = (password) =>
  password.length >= 8

export const validatePasswordEspecialCharacter = (password) =>
  /[!¡"#$%&'()*+,\-./:;<=>¿?@[\\\]^_`{|}~\s]/.test(String(password));

export const validatePasswordLength = (password) => password.length >= 8;

export const validateStep1Form = async ({ state, email, password }) =>
  validateBasicForm({ state, email, password });

export const validateBasicForm = async ({
  state,
  email,
  password,
  validateUser = true,
}) => {
  if (!validateEmail(email)) return INVALID_EMAIL;
  if (!validatePasswordLength(password)) return INVALID_PASSWORD_LENGTH;
  if (validateUser && !validatePasswordChars(password)) return INVALID_PASSWORD_CHARS;
  return validateUser ? await verifyCustomerExistence(state) : false;
};

export const validateFreeTeacherForm = ({ state }) => {
  const { firstName, lastName, schoolName, schoolAddress, country } = state;
  if (!firstName) return 'First name is required';
  if (!lastName) return 'Last name is required';
  if (!schoolName) return 'School name is required';
  if (!schoolAddress) return 'School address is required';
  if (!country) return 'Country is required';
};

export const validateFreeHomeschoolForm = ({ state }) => {
  const {
    firstName,
    lastName,
    address,
    country,
    yearCurriculum,
    homeschoolingGrading,
  } = state;
  if (!firstName) return 'First name is required';
  if (!lastName) return 'Last name is required';
  if (!address) return 'School address is required';
  if (!country) return 'Country is required';
  if (!yearCurriculum)
    return 'The curriculum that you are using this year is required';
  if (!homeschoolingGrading?.length)
    return 'The homeschool grades are required ';
};

// Brings the grading array sorted from smallest to largest
export const normalizeHomeschoolGradingArray = (homeschoolingGrading) =>
  HOMESCHOOL_GRADE_LEVEL.map(
    ({ value }) =>
      homeschoolingGrading.find(
        ({ value: selectedValue }) => value === selectedValue,
      )?.value,
  ).filter((x) => x);

export const verifyCustomerExistence = async (payload) => {
  try {
    const {
      data: { userExists },
    } = await checkCustomer(payload);
    if (userExists) return CUSTOMER_ALREADY_EXISTS;
  } catch (error) {
    return error.toString();
  }
};

export const validateStep2Form = ({ error, customerName }) => {
  if (error) return error.message;
  if (!customerName) return NO_CC_CUSTOMER_NAME;
};

export const checkCouponErrors = (coupon) => {
  let error = '';
  const { percent_off: percentOff, duration_days: durationDays } = coupon;

  if (percentOff !== 100) error = COUPON_APPLY_ERROR;
  if (durationDays === 0) error = COUPON_APPLY_UNAVAILABLE;

  return error;
};

export const isPostalCodeValid = (postalCode) =>
  !!postalCode &&
  postalCode.length >= LENGTH_STANDARDS.MIN_ZIPCODE_LENGTH &&
  postalCode.length <= LENGTH_STANDARDS.MAX_ZIPCODE_LENGTH;

export const isSameEmail = ({ currentEmail, savedRequest }) =>
  currentEmail === savedRequest?.to;

export const onlyNumericValues = (e) =>
  ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault();

export const countryRequireZIPCode = (country) =>
  COUNTRIES_ZIP_REQUIRED.includes(country);
