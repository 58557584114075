import React from 'react';
import {
    Button,
    Modal,
    ModalBody, ModalCloseButton,
    ModalContent, ModalFooter, ModalHeader,
    ModalOverlay, Text,
} from "@chakra-ui/react";

const AFAFailed = ({checkAFAStatus, handleClick = () => window.location.reload()}) => {
    return (
        <Modal
            isOpen
            closeOnOverlayClick={true}
            scrollBehavior="inside"
            onClose={checkAFAStatus}
            size="xl"
        >
            <ModalOverlay />
            <ModalContent borderRadius={20}>
                <ModalHeader fontSize="xl" fontFamily="proxima-soft,sans-serif" textAlign="center" fontWeight="800">
                    <Text>
                        The Additional factor authentication (AFA) failed!
                    </Text>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody textAlign="center" mb={4}>
                    <Text fontFamily="proxima-soft,sans-serif">
                        Please try again!
                    </Text>
                </ModalBody>
                <ModalFooter>
                    <Button
                        width="full"
                        variantColor="blue"
                        onClick={handleClick}
                        h="auto"
                        py="3"
                        fontSize="lg"
                        fontWeight="800"
                        lineHeight="none"
                        rounded="full"
                        bg="highlight"
                        _hover={{ bg: 'hover' }}
                    >
                        <Text fontFamily="proxima-soft,sans-serif" color="white">
                            Try again!
                        </Text>
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default AFAFailed;