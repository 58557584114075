import React from 'react';
import MainLayout from "../layouts/components/MainLayout/MainLayout";
import StartFlowContainer from "../containers/StartFlowContainer";

const Start = () => {
    return (
        <MainLayout>
            <StartFlowContainer/>
        </MainLayout>
    );
};

export default Start;