export const TFA = '/twoFactorAuth';
export const TFA_TOKEN = `${TFA}/token`;
export const REQUEST_TFA_TOKEN = `${TFA_TOKEN}/request`;
export const VERIFY_TFA_TOKEN = `${TFA_TOKEN}/verify`;

// Backend sources

export const API_AUTH = '/auth';
export const COMPLETE_USER_CREATION = `${API_AUTH}/complete_user_creation`;
export const REGISTER = `${API_AUTH}/register`;
export const USER_CREATION_ENDPOINT = {
  DEFAULT: 'default',
  START: 'start',
  REDEEM: 'redeem',
  CUSTOM_REDEEM: 'redeem/custom',
  FREE_TEACHER: 'free-teacher',
  FREE_HOMESCHOOL: 'free-homeschool',
  READ_ALONG: 'read-along',
  LIFETIME: 'lifetime',
  MATH_MASTERS: 'redeem/math-masters',
};

export const CHECK_EMAIL_ENDPOINT = `${API_AUTH}/check-email`;
