import React, {useEffect, useState} from 'react';

import {useDispatch} from "react-redux";
import {getSign} from "../../api/amazonPay";
import {capitalize} from "lodash";
import {singularInterval} from "../../utils/subscriptionUtils";
import {setRegistrationData} from "../../stores/amazonRegistration";

const AMAZON_PAY_BUTTON_ID = "amazon-pay-btn";
const {
    REACT_APP_MERCHANT_ID: merchantId,
    REACT_APP_PUBLIC_KEY_ID: publicKeyId,
    REACT_APP_STORE_ID: storeId
} = process.env;


const AmazonPayButton = ({registrationDetails, ...props}) => {

    const {plan: {interval, amount}} = registrationDetails;
    const dispatch = useDispatch();
    const [amazonPayButton, setAmazonPayButton] = useState(null);

    const payload = {
        webCheckoutDetails: {
            checkoutReviewReturnUrl: window.location.origin + "/amazon-pay/",
        },
        storeId,
        scopes: ["name", "email"],
        chargePermissionType: "Recurring",
        recurringMetadata: {
            frequency: {
                unit: capitalize(singularInterval[interval]),
                value: 1,
            },
            amount: {
                amount: amount,
                currencyCode: "USD",
            }
        },
    }

    const handleClick = async () => {
        dispatch(setRegistrationData(registrationDetails))
        amazonPayButton.initCheckout({
            createCheckoutSessionConfig: {
                payloadJSON: payload,
                signature: await getSign(payload),
                publicKeyId
            }
        });
    }

    useEffect(() => {
        if (amazonPayButton) amazonPayButton.onClick(handleClick);
    }, [amazonPayButton, handleClick])

    useEffect(() => setAmazonPayButton(window.amazon.Pay.renderButton(`#${AMAZON_PAY_BUTTON_ID}`, {
        merchantId,
        publicKeyId,
        ledgerCurrency: 'USD',
        checkoutLanguage: 'en_US',
        productType: 'PayOnly',
        placement: 'Cart',
        buttonColor: 'Gold',
    })), [])

    return <div id={AMAZON_PAY_BUTTON_ID} {...props} />
}

export default AmazonPayButton;
