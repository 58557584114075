import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Stack, Text, Textarea } from '@chakra-ui/react';

import { ERRORS } from '../texts';
import { verifyEmail } from '../../../api/auth';
import { verifyGift } from '../../../api/gifting';
import { GIFT_PAYMENT } from '../../../constants/routes';

import {
  goToSection,
  setGiftingData,
  getGiftingData,
} from '../../../stores/gifting';

import GiftingTitle from '../GiftingTitle';
import Terms from '../../Terms/Terms';
import { InputForm } from '../../ReactHookForm';

import validationSchema from './validationSchema';
import DuplicatedAttemptModal from './DuplicatedAttemptModal';

const infoInputs = [
  { id: 'firstName', label: 'Your First Name' },
  { id: 'lastName', label: 'Your Last Name' },
  { id: 'gifterEmail', label: 'Your Email' },
];

const recipientInputs = [
  { id: 'gifteeFirstName', label: 'Recipient First Name' },
  { id: 'gifteeLastName', label: 'Recipient Last Name' },
  { id: 'gifteeEmail', label: 'Recipient Email' },
  { id: 'confirmGifteeEmail', label: 'Confirm Recipient Email' },
];

const Step1 = ({ onCancel }) => {
  const dispatch = useDispatch();

  const giftingData = useSelector(getGiftingData);

  const {
    handleSubmit,
    register,
    control,
    setError,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues: { ...giftingData, confirmGifteeEmail: '' },
  });

  const [submitLoading, setSubmitLoading] = useState(false);
  const [duplicatedAttempt, setDuplicatedAttempt] = useState(false);

  const checkDuplicatedGifting = async (data) => {
    const { gifteeEmail, gifterEmail } = data;
    try {
      await verifyGift({ gifteeEmail, gifterEmail });
      return true;
    } catch (err) {
      setDuplicatedAttempt(true);
      return false;
    }
  }

  const submitForm = async (data) => {
    const formData = data || getValues();
    setDuplicatedAttempt(false);

    delete formData.confirmGifteeEmail;
    if (!formData?.giftMessage) delete formData.giftMessage;

    dispatch(setGiftingData(formData));
    dispatch(goToSection(GIFT_PAYMENT));
  }

  const handleOnSubmit = async (data, _, bypassDuplicatedGiftingCheck) => {
    setSubmitLoading(true);
    try {
      await verifyEmail(data?.gifteeEmail);
      const canSubmit = bypassDuplicatedGiftingCheck || await checkDuplicatedGifting(data);
      setSubmitLoading(false);
      if (canSubmit) submitForm(data);
    } catch (error) {
      setError('gifteeEmail', {
        type: 'custom',
        message: ERRORS.accountAlreadyExists,
      });
      setSubmitLoading(false);
    }
  };

  return (
    <Box as="form" textAlign="left" onSubmit={handleSubmit(handleOnSubmit)}>
      <DuplicatedAttemptModal
        isOpen={duplicatedAttempt}
        onContinue={() => handleOnSubmit(getValues(), null, true)}
        onClose={() => setDuplicatedAttempt(false)}
      />
      <GiftingTitle />
      <Text fontWeight={800} mb="6">
        Step 1 of 2
      </Text>
      <Stack spacing="1.5rem" mb="1.5rem">
        <Stack spacing="0.5rem">
          <Text color="label">Your Information</Text>
          {infoInputs.map(({ id, label }) => (
            <InputForm
              key={id}
              id={id}
              label={label}
              register={register}
              control={control}
              errors={errors}
              required
            />
          ))}
        </Stack>
        <Stack spacing="0.5rem">
          <Text color="label">Recipient Details</Text>
          {recipientInputs.map(({ id, label }) => (
            <InputForm
              key={id}
              id={id}
              label={label}
              register={register}
              control={control}
              errors={errors}
              required
            />
          ))}
        </Stack>
        <Stack spacing="0.5rem">
          <Text color="label">Add an optional message</Text>
          <Textarea {...register('giftMessage')} fontFamily="proxima-nova" />
        </Stack>
      </Stack>
      <Terms className="terms" style={{ fontFamily: 'proxima-nova' }} />
      <Button
        type="submit"
        background="highlight"
        color="white"
        mt="8px"
        borderRadius="full"
        fontWeight="bold"
        fontSize="lg"
        w="full"
        size="lg"
        _hover={{ background: 'hover' }}
        isDisabled={!isValid}
        isLoading={submitLoading}
      >
        Continue
      </Button>
      <Button
        onClick={onCancel}
        variant="ghost"
        color="playerBg"
        mt="8px"
        borderRadius="full"
        fontWeight="bold"
        fontSize="lg"
        w="full"
        _hover={{ background: 'none' }}
        size="lg"
      >
        Cancel
      </Button>
    </Box>
  );
};

Step1.propTypes = {
  onCancel: PropTypes.func.isRequired,
};

export default Step1;
