import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {getCurrentSection, getRegistrationData} from "../../stores/registration";
import {CHECKOUT, FORM, REGISTRATION_SUMMARY, TFA} from "../../constants/routes";
import Step3 from "../../components/DefaultFlow/Step3";
import TwoFactorAuthentication from "../../components/TwoFactorAuthentication";
import Step2FreeTeacher from "../../components/FreeTeacherFlow/Step2FreeTeacher";
import {
    DEFAULT_APP_COUNTRY_CODE,
    HEARD_FROM_FRIEND,
    PRE_K,
    STUDENTS_1_4,
} from "../../constants/constants";
import FreeTeacher from "../../components/FreeTeacherFlow/FreeTeacher";

const FreeTeacherContainer = () => {
    const section = useSelector(getCurrentSection);
    const { email, password } = useSelector(getRegistrationData);
    const [selectedPrice, setSelectedPrice] = useState(null);
    const [subscription, setSubscription] = useState(null);
    const [validated, setValidated] = useState(null);
    const [state, setState] = useState({
        email,
        password,
        firstName: '',
        lastName: '',
        schoolName: '',
        schoolAddress: '',
        schoolAddress2: '',
        city: '',
        province: '',
        zip: '',
        country: DEFAULT_APP_COUNTRY_CODE,
        gradeLevel: PRE_K,
        studentsPerClass: STUDENTS_1_4,
        heardFrom: HEARD_FROM_FRIEND,
        interestedAtHomeVooks: true,
        emailNewsletter: true,
        smsNewsletter: true,
        newsletter: false,
        isSubscription: false,
    })
    
    const setStateFunc = (newState) => setState({ ...state, ...newState});
   
    return (
        <div className="steps-container">
            {section === FORM && (
                <FreeTeacher
                    state={state}
                    setState={setStateFunc}
                    validated={validated}
                    setValidated={setValidated}
                    selectedPrice={selectedPrice}
                    setSelectedPrice={setSelectedPrice}
                />
            )}
            {section === TFA && <TwoFactorAuthentication state={state} />}
            {section === CHECKOUT && (
                <Step2FreeTeacher
                    state={state}
                    setState={setStateFunc}
                    selectedPrice={selectedPrice}
                    setSelectedPrice={setSelectedPrice}
                    subscription={subscription}
                    setSubscription={setSubscription}
                />
            )}
            {section === REGISTRATION_SUMMARY && (
                <Step3
                    state={state}
                    selectedPrice={selectedPrice}
                    subscription={subscription}
                />
            )}
        </div>
    )
}

export default FreeTeacherContainer;
