import dayjs from "dayjs";
import { DATE_FORMAT } from "../constants/constants";

export const getRemainingSecondsUntilDate = (date) => {
    if (!date) return 0;
    const currentDate = dayjs();
    const remainingSeconds = dayjs(date).diff(currentDate, 'second');
    return remainingSeconds >= 0 ? remainingSeconds : 0;
}

export const formatSecondsToMin = (seconds) => `${Math.floor(seconds / 60)}:${String(seconds % 60).padStart(2,'0')}`

export const getSubscriptionExpirationDate = (
    subscription,
    percentOff,
    daysAccess,
    dateFormat = DATE_FORMAT
  ) => {
    if (daysAccess) return dayjs().add(daysAccess, 'day').format(dateFormat);
    if (percentOff === 100 && subscription.discount?.end)
      return dayjs.unix(subscription.discount.end).format(dateFormat);

    return dayjs
      .unix(subscription.cancel_at || subscription.current_period_end)
      .format(dateFormat);
  };
