import { useEffect, useRef, useState } from "react"

const usePreApplyCode = (couponCodeQuery, handleApplyFunc, code, setCodeFunc, dependencies = []) => {
    const [lastCodeApplied, setLastCodeApplied] = useState('')
    const codeAppliedRef = useRef(false)

    const couponQueryExists = typeof couponCodeQuery === 'string' && couponCodeQuery

    const codeAppliedAlreadyAndNoCustomCode = codeAppliedRef.current && couponCodeQuery !== code

    const sameCodeAppliedTwice = code === lastCodeApplied && codeAppliedRef.current

    useEffect(() => {
        codeAppliedRef.current = false
    },[...dependencies])

    useEffect(() => {     
        if(!couponQueryExists || codeAppliedAlreadyAndNoCustomCode || sameCodeAppliedTwice) return
        if(setCodeFunc) setCodeFunc(couponCodeQuery)
        handleApplyFunc(null, couponCodeQuery)
        setLastCodeApplied(couponCodeQuery)
        codeAppliedRef.current = true
    },[code, ...dependencies])

}

export default usePreApplyCode
