import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@chakra-ui/react';

import {
  clearGiftingState,
  getCurrentSection,
  getGiftingRedeemState,
  replaceSection,
} from '../../stores/gifting';

import {
  GIFT_USERS_DATA,
  TFA,
  GIFT_REDEEM_CODE,
  GIFT_SUCCESS,
} from '../../constants/routes';

import Step1 from '../../components/Gifting/Redeem/Step1';
import TwoFactorAuthentication from '../../components/TwoFactorAuthentication';
import Step3 from '../../components/Gifting/Redeem/Step3';
import Success from '../../components/Gifting/Redeem/Success';

const RedeemFlowContainer = () => {
  const dispatch = useDispatch();

  const section = useSelector(getCurrentSection);
  const giftingRedeemState = useSelector(getGiftingRedeemState);

  useEffect(() => {
    return () => dispatch(clearGiftingState());
  }, [dispatch]);

  return (
    <Box className="steps-container" py="12" w="sm" bg="white">
      {section === GIFT_USERS_DATA && <Step1 />}
      {section === TFA && (
        <TwoFactorAuthentication
          state={giftingRedeemState}
          replaceSectionCallback={() =>
            dispatch(replaceSection(GIFT_REDEEM_CODE))
          }
        />
      )}
      {section === GIFT_REDEEM_CODE && <Step3 />}
      {section === GIFT_SUCCESS && <Success />}
    </Box>
  );
};

export default RedeemFlowContainer;
