export const cardElementDecoration = {
    style: {
        base: {
            fontSize: "16px",
            fontFamily: "proxima-soft, sans-serif",
            fontWeight: "300",
            color: "text",
            "::placeholder": {
                color: "#aab7c4",
                fontWeight: "normal",
                fontSmoothing: "antialiased",
            },
        },
        invalid: {
            color: "errorText",
            "::placeholder": {
                color: "#aab7c4",
                fontWeight: "normal",
                fontSmoothing: "antialiased",
            },
        },
        empty: {
            "::placeholder": {
                color: "#aab7c4",
                fontWeight: "normal",
                fontSmoothing: "antialiased",
            },
        },
    },
};
