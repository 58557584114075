import React, {useEffect, useState} from "react";
import {
    createSubscription,
    getCreateSubscriptionState,
    goToSection
} from "../../stores/registration";
import {REGISTRATION_SUMMARY} from "../../constants/routes";
import {useDispatch, useSelector} from "react-redux";
import Loading from "../Loading/Loading";
import {Box, Button, Text} from "@chakra-ui/react";
import useCustomToast from "../../lib/hooks/toast";
import FreeTeacherForm from "./FreeTeacherForm";
import {getFreeTeacherPrices, getPricesState} from "../../stores/prices";
import {DEFAULT_FREE_TEACHERS_PRICE_SLUG} from "../../constants/constants";
import {GENERIC_ERROR} from "../../constants/messages";
import {validateFreeTeacherForm} from "../../utils/validationUtils";
import {EVENTS, SOURCES} from "../../constants/events";
import {klaviyoEvent} from "../../utils/events";
import {USER_CREATION_ENDPOINT} from "../../constants/backend-sources";
import useUTMs from "../../lib/hooks/useUTMs";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const Step2FreeTeacher = ({state, setState, setSelectedPrice}) => {
    const UTMs = useUTMs();

    const {
        email,
        password,
        country,
        firstName,
        lastName,
        newsletter,
        schoolAddress,
        schoolAddress2,
        city,
        gradeLevel,
        schoolName,
        province,
        studentsPerClass,
        heardFrom,
        interestedAtHomeVooks,
        emailNewsletter,
        smsNewsletter,
    } = state;

    const {prices, error: pricesError, loading: pricesLoading} = useSelector(getPricesState);
    const {fulfilled, error, loading} = useSelector(getCreateSubscriptionState);

    const dispatch = useDispatch();
    const toast = useCustomToast();

    const [showSpinner, setShowSpinner] = useState(false);
    const { executeRecaptcha } = useGoogleReCaptcha();


    const handleChange = (event) => {
        const target = event?.target;
        const name = target?.name;
        const type = target?.type
        const value = type === 'checkbox' ? target?.checked :  target?.value;
        setState({[name]: value});
    }

    useEffect(() => {
        klaviyoEvent(email, password, EVENTS.AFTER_VALIDATION, { email, source: SOURCES.FREE_TEACHER_FLOW.AFTER_VALIDATION, ...UTMs });
    }, []);

    useEffect(() => {
        if (prices) setSelectedPrice(prices.find((price) => price.metadata.slug === DEFAULT_FREE_TEACHERS_PRICE_SLUG))
    }, [prices])

    useEffect(() => {
        if (pricesError) toast({type: 'error', message: GENERIC_ERROR, duration: 5000});
    }, [pricesError])

    useEffect(() => {
        setShowSpinner(loading || pricesLoading);
    }, [loading, pricesLoading])

    useEffect(() => {
        if (fulfilled) dispatch(goToSection(REGISTRATION_SUMMARY));
    }, [fulfilled])

    useEffect(() => {
        if (error) toast({type: 'error', message: error.message, duration: 5000});
    }, [error]);

    useEffect(() => {
        dispatch(getFreeTeacherPrices(country));
    }, [country])

    const handleSubmit = async () => {
        const validationMessage = validateFreeTeacherForm({state});
        if (validationMessage) {
            toast({type: 'error', message: validationMessage, duration: 5000})
            return;
        }

        const token = await executeRecaptcha('submit');

        dispatch(createSubscription({
            payload: {
                first_name: firstName,
                last_name: lastName,
                email,
                password,
                password_confirmation: password,
                join_newsletter: newsletter,
                country_code: country,
                source: SOURCES.FREE_TEACHER_FLOW.WEB,
                otherInfo: {
                    'Country': country,
                    'Address': schoolAddress,
                    'Address 2': schoolAddress2,
                    'City': city,
                    'Grade Level': gradeLevel,
                    'School Name': schoolName,
                    'State': province,
                    'Students Per Class': studentsPerClass,
                    'Heard From': heardFrom,
                    'Interested at home Vooks': interestedAtHomeVooks,
                    'Email Newsletter': emailNewsletter,
                    'SMS Newsletter': smsNewsletter,
                },
                UTMs,
                token,
            }, userCreationEndpoint: USER_CREATION_ENDPOINT.FREE_TEACHER
        }));
    };

    return (
        <div>
            <div className="hero">
                <Text
                    fontFamily="proxima-soft"
                    fontSize="32px"
                    fontWeight="800"
                    margin="8px"
                    lineHeight="110%"
                >
                    Create Free Account
                </Text>
                <Text>For Teacher In-Class Use</Text>
                <Text>New Teacher Accounts Only</Text>
            </div>
            <div className="step">
                {showSpinner && <Loading/>}
                <h2>Step 3 of 3</h2>
                <FreeTeacherForm handleChange={handleChange} state={state} my="20px"/>
                <Box className="legal">
                    <Text className="legal-terms">
                        This offer is exclusively available for teachers and educators of public schools, private
                        schools, preschool programs and qualified homeschooling parents worldwide. Each account is
                        limited to 1 device or terminal, bulk devices and terminals purchased separately. No purchase
                        necessary. You will receive an email confirmation once the form is submitted. No credit card
                        required. Must be at least 18 years of age to be eligible for this offer. Free access duration
                        can be changed at any time. Vooks, Inc. | support@vooks.com | PO Box 6449 Beaverton, OR 97007.
                    </Text>
                </Box>
                <Button
                    onClick={handleSubmit}
                    background="highlight"
                    color="white"
                    mt="8px"
                    borderRadius="100px"
                    fontFamily="proxima-soft"
                    fontWeight={800}
                    fontSize="18px"
                    h="56px"
                    w="100%"
                    border="none"
                    outline="none"
                    cursor="pointer"
                    _hover={{background: "hover"}}
                >
                    Agree &amp; Continue
                </Button>
            </div>
        </div>
    );
}

export default Step2FreeTeacher;
