import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@chakra-ui/react';

import {
  GIFT_USERS_DATA,
  GIFT_PAYMENT,
  GIFT_SUCCESS,
  LANDING,
} from '../../constants/routes';

import { getCurrentSection, clearGiftingState } from '../../stores/gifting';

import Step1 from '../../components/Gifting/Step1';
import Step2 from '../../components/Gifting/Step2';
import Success from '../../components/Gifting/Success';

const GiftingContainer = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const section = useSelector(getCurrentSection);

  useEffect(() => {
    return () => dispatch(clearGiftingState());
  }, [dispatch]);

  const handleCancel = () => {
    dispatch(clearGiftingState());
    history.push(LANDING);
  };

  return (
    <Box
      className="steps-container"
      py="12"
      w="sm"
      bg="white"
      position="relative"
    >
      {section === GIFT_USERS_DATA && <Step1 onCancel={handleCancel} />}
      {section === GIFT_PAYMENT && <Step2 onCancel={handleCancel} />}
      {section === GIFT_SUCCESS && <Success onCancel={handleCancel} />}
    </Box>
  );
};

export default GiftingContainer;
