export const stackSection = (stack, section) => {
    const result = [...stack];
    result.push(section);
    return result;
};

export const unstackSection = stack => {
    const result = [...stack];
    result.pop();
    return result;
};
