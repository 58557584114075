import React from 'react';

import MainLayout from '../layouts/components/MainLayout/MainLayout';
import GiftingContainer from '../containers/GiftingContainer';

const Gift = () => {
  return (
    <MainLayout>
      <GiftingContainer />
    </MainLayout>
  );
};

export default Gift;
