import React from 'react';
import './index.css';
import ComboBox from '../Form/ComboBox';
import { getCountryComboBoxList } from '../../utils/countryUtils';
import { Box } from '@chakra-ui/react';

const UserForm = ({ country, handleChange, ...props }) => {
  return (
    <Box className="form" {...props}>
      <ComboBox
        value={country}
        label="Country"
        inputName="country"
        values={getCountryComboBoxList()}
        handleChange={handleChange}
        mandatory
      />
    </Box>
  );
};

export default UserForm;
