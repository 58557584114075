import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';

const Field = ({ id, label, error, required, children, ...props }) => {
  return (
    <FormControl
      isInvalid={error}
      id={id}
      position="relative"
      fontFamily="proxima-nova"
      mb="2"
      {...props}
    >
      <FormLabel
        htmlFor={id}
        position="absolute"
        top="12px"
        left="4"
        color={error ? 'errorText' : 'label'}
        fontSize="10px"
        lineHeight="12px"
        p={0}
        m={0}
        transition="top 0.2s, font-size 0.2s"
        zIndex={10}
      >
        {`${label}${required ? '*' : ''}`}
      </FormLabel>
      {children}
      <FormErrorMessage fontSize="xs" ml="2" color="errorText">
        {error && error.message}
      </FormErrorMessage>
    </FormControl>
  );
};

Field.defaultProps = {
  label: '',
  error: null,
  required: false,
};

Field.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  error: PropTypes.shape(),
  required: PropTypes.bool,
  children: PropTypes.node,
};

export default Field;
