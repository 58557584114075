import { get } from 'lodash';

// Wraps an object within a new minified object according to a wrappingDescription
// The wrappedObject will include each of the keys of the wrappingDescription with its
// values as the safe navigated paths from the target object
export const wrapObject = (object, wrappingDescription = {}) => {
  const wrappedObject = {};
  Object.keys(wrappingDescription).forEach(key => {
    wrappedObject[key] = get(object, wrappingDescription[key]);
  });
  return wrappedObject;
};
