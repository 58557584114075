import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { FORM } from '../../constants/routes';
import lodash from 'lodash';
import { stackSection, unstackSection } from '../../utils/navigationUtils';
import {
  checkCouponApiCall,
  checkCustomCouponApiCall,
} from '../../api/coupons';
import { checkCouponErrors } from '../../utils/validationUtils';
import { getRandomBackgroundSlug } from '../../utils/decorators/backgroundDecorator';
import { createSubscriptionApiCall } from '../../api/subscriptions';
import { GENERIC_COUPON_APPLY_ERROR } from '../../constants/messages';
import { wrapObject } from '../../utils/objectWrapper';

const checkCoupon = createAsyncThunk(
  'prices/checkCoupon',
  async (payload, thunkAPI) => {
    try {
      const response = await checkCouponApiCall(payload);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(
        wrapObject(e, { message: 'response.data.error.details' }),
      );
    }
  },
);

const checkRedeemCoupon = createAsyncThunk(
  'prices/checkRedeemCoupon',
  async (payload, thunkAPI) => {
    try {
      const coupon = await checkCustomCouponApiCall(payload);
      const error = checkCouponErrors(coupon);

      if (error && error.length > 0)
        return thunkAPI.rejectWithValue({ message: error });
      return coupon;
    } catch (e) {
      const rejectValue = wrapObject(e, { message: 'response.data.error.details' });
      if (!rejectValue?.message) {
        rejectValue.message = GENERIC_COUPON_APPLY_ERROR;
      }
      return thunkAPI.rejectWithValue(rejectValue);
    }
  },
);

const createSubscription = createAsyncThunk(
  'registration/createSubscription',
  async ({ payload, userCreationEndpoint }, thunkAPI) => {
    try {
      const { data } = await createSubscriptionApiCall({
        payload,
        userCreationEndpoint,
      });
      return data;
    } catch (e) {

      if(e.response?.status === 429){
        return thunkAPI.rejectWithValue({ message: e.response.data.error.details.message || 'Too many request, please try again later' });
    }

      if(e.response?.data?.length){
        return thunkAPI.rejectWithValue({message: e.response?.data});
      }
      const rejectValue = wrapObject(e, { message: 'response.data.error.details' });
      if (!rejectValue?.message) {
        rejectValue.message = GENERIC_COUPON_APPLY_ERROR;
      }
      return thunkAPI.rejectWithValue(rejectValue);
    }
  },
);

const initialState = {
  data: {
    email: '',
    password: '',
  },
  couponInfo: {
    coupon: null,
    loading: false,
    error: null,
  },
  createSubscription: {
    fulfilled: null,
    loading: false,
    error: null,
  },
  currentBackgroundSlug: getRandomBackgroundSlug(),
  navigation: [FORM],
};

const registrationSlice = createSlice({
  name: 'registration',
  initialState,
  reducers: {
    setRegistrationData(state, { payload }) {
      return {
        ...state,
        data: {
          ...state.data,
          ...payload,
        },
      };
    },
    goToSection(state, { payload }) {
      return {
        ...state,
        currentBackgroundSlug: getRandomBackgroundSlug(
          state.currentBackgroundSlug,
        ),
        navigation: stackSection(state.navigation, payload),
      };
    },
    replaceSection(state, { payload }) {
      return {
        ...state,
        currentBackgroundSlug: getRandomBackgroundSlug(
          state.currentBackgroundSlug,
        ),
        navigation: stackSection(unstackSection(state.navigation), payload),
      };
    },
    goToPreviousSection(state) {
      return {
        ...state,
        currentBackgroundSlug: getRandomBackgroundSlug(
          state.currentBackgroundSlug,
        ),
        navigation: unstackSection(state.navigation),
      };
    },
    clearAllCouponInfo(state) {
      return {
        ...state,
        couponInfo: {
          ...initialState.couponInfo,
        },
      };
    },
    clearNavigationStack(state) {
      return {
        ...state,
        navigation: initialState.navigation,
      };
    },
    shuffleBackgroundImage(state) {
      return {
        ...state,
        currentBackgroundSlug: getRandomBackgroundSlug(
          state.currentBackgroundSlug,
        ),
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(checkCoupon.pending, (state) => {
      state.couponInfo = {
        coupon: null,
        loading: true,
        error: false,
      };
    });
    builder.addCase(checkCoupon.fulfilled, (state, { payload }) => {
      state.couponInfo = {
        error: null,
        coupon: payload,
        loading: false,
      };
    });
    builder.addCase(checkCoupon.rejected, (state, { payload }) => {
      state.couponInfo = {
        coupon: null,
        error: payload,
        loading: false,
      };
    });
    builder.addCase(checkRedeemCoupon.pending, (state) => {
      state.couponInfo = {
        coupon: null,
        loading: true,
        error: false,
      };
    });
    builder.addCase(checkRedeemCoupon.fulfilled, (state, { payload }) => {
      state.couponInfo = {
        error: null,
        coupon: payload,
        loading: false,
      };
    });
    builder.addCase(checkRedeemCoupon.rejected, (state, { payload }) => {
      state.couponInfo = {
        coupon: null,
        error: payload,
        loading: false,
      };
    });
    builder.addCase(createSubscription.pending, (state) => {
      state.createSubscription = {
        fulfilled: null,
        loading: true,
        error: false,
      };
    });
    builder.addCase(createSubscription.fulfilled, (state, { payload }) => {
      state.createSubscription = {
        fulfilled: payload,
        loading: true,
        error: null,
      };
    });
    builder.addCase(createSubscription.rejected, (state, { payload }) => {
      state.createSubscription = {
        fulfilled: null,
        loading: false,
        error: payload,
      };
    });
  },
});

const {
  reducer,
  actions: {
    setRegistrationData,
    goToSection,
    replaceSection,
    goToPreviousSection,
    clearNavigationStack,
    clearAllCouponInfo,
  },
} = registrationSlice;

export const getRegistrationState = (state) => state.registration;
export const getCreateSubscriptionState = (state) =>
  getRegistrationState(state).createSubscription;
export const getRegistrationData = (state) => getRegistrationState(state).data;
export const getNavigationState = (state) =>
  getRegistrationState(state).navigation;
export const getHasPreviousSection = (state) =>
  getNavigationState(state).length > 1;
export const getCurrentSection = (state) =>
  lodash.last(getNavigationState(state));

export const getCouponInfoState = (state) =>
  getRegistrationState(state).couponInfo;

export const getCurrentBackgroundSlug = (state) =>
  getRegistrationState(state).currentBackgroundSlug;

export {
  setRegistrationData,
  goToSection,
  replaceSection,
  goToPreviousSection,
  clearNavigationStack,
  checkCoupon,
  checkRedeemCoupon,
  clearAllCouponInfo,
  createSubscription,
};

export default reducer;
