import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './utils/serviceWorker';
import {ChakraProvider, extendTheme} from "@chakra-ui/react"
import { Provider } from 'react-redux'
import {initializeStore} from "./stores/store";
import {PersistGate} from "redux-persist/integration/react";
import {persistStore} from "redux-persist";
import customTheme from "./layouts/components/ThemeProvider/customTheme";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const CAPTCHA_SITE_KEY = process.env.REACT_APP_CAPTCHA_SITE_KEY;

const isLocalHost = hostname => !!(
    hostname === 'localhost' ||
    hostname === '[::1]' ||
    hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/) ||
    hostname.match(/^192.168(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){2}$/)
);

if (typeof window !== 'undefined' && window.location && window.location.protocol === 'http:' && !isLocalHost(window.location.hostname)) {
  window.location.href = window.location.href.replace(/^http(?!s)/, 'https');
}

const theme = extendTheme(customTheme);

const store = initializeStore();

ReactDOM.render(
  <React.StrictMode>
      <ChakraProvider theme={theme}>
          <GoogleReCaptchaProvider reCaptchaKey={CAPTCHA_SITE_KEY}>
              <Provider store={store}>
                  <PersistGate loading={null} persistor={persistStore(store)}>
                      <App />
                  </PersistGate>
              </Provider>
          </GoogleReCaptchaProvider>
      </ChakraProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
