import {PLAN_PRICES_PRESETS} from "../../constants/constants";
import {isLifetimePrice} from "../planUtils";
import {roundCentsAmount, totalTodayPrice} from "../currencyUtils";
import {CC_WITH_CODE, CODE, CREDIT_CARD} from "../../constants/messages";
import AmazonPayLogo from "../../static/assets/images/AmazonPayLogo";
import React from "react";

export const getPlanPricePreset = planSlug => {
    if (!planSlug) return PLAN_PRICES_PRESETS.generic;
    return PLAN_PRICES_PRESETS[planSlug] || PLAN_PRICES_PRESETS.generic;
};

export const decorateSubscriptionOnelineText = (price) =>
    isLifetimePrice(price) ? 'Lifetime Subscription' : `${price.interval}ly Subscription`;

export const decoratePriceAmountOnelineText = (price) =>
    `${price.currencySymbol}${roundCentsAmount(price.amount)}`

export const decoratePaymentMethod = (redeem, readAlong, isSubscription, isAmazonPaySubscription, usedCode) => {
    if (redeem || readAlong) return CODE;
    if (isSubscription) return usedCode ? CC_WITH_CODE : CREDIT_CARD;
    if (isAmazonPaySubscription)  return <AmazonPayLogo className="amazon-pay-logo"/>;
    return "N/D";
}

export const decorateTotalTitle = (price, percentOff) => isLifetimePrice(price) || percentOff === 100 ? 'Total' : 'Total Today'

export const decorateTodayPayment = (price, buyNGive, coupon) =>
    `${price.currencySymbol}${totalTodayPrice(isLifetimePrice(price), buyNGive, price.amount, coupon)}`;

export const decoratePaymentDateTitleOneLineText = (defaultFlow) => defaultFlow ? 'Next Payment Date' : 'Expiration Date'
