import React from "react";
import {Box} from "@chakra-ui/react";

const ComboBox = ({ value, values, label, inputName, handleChange, mandatory }) => {
  return (
      <Box className="input-with-label combo-box-container" >
          <select
          className="combo-box"
          name={inputName}
          value={value}
          onChange={handleChange}
          >
              {values.map(({ key, value, label }) => (
                  <option key={key} value={value}>{label}</option>
              ))}
          </select>
          <div className="combo-box-arrow">
              <svg viewBox="0 0 24 24" focusable="false" role="presentation" aria-hidden="true">
                  <path fill="currentColor" d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
              </svg>
          </div>
          <label
              htmlFor={inputName}
              className="input--text-label-combo"
          >
              {`${label}${mandatory ? '*' : ''}`}
          </label>
      </Box>
  )
}

export default ComboBox;