/* eslint-disable react/prop-types */
import React from 'react';
import {Alert, AlertDescription, Box, CloseButton, Text} from "@chakra-ui/react";

const getToast = ({ type, message, duration }) => ({
    position: 'top',
    duration: duration || 3000,
    isClosable: true,
    render: ({ onClose }) => (
        <Box
            w={{ base: 'full', md: 'auto' }}
            mt={{ base: '0', md: '5' }}
            zIndex="toast"
        >
            <Alert
                display="flex"
                justifyContent="space-between"
                py={{ base: '4', md: '1' }}
                px="0"
                bg={type}
                color="background"
                fontFamily="special"
                rounded={{ base: 'none', md: 'lg' }}
                boxShadow="lg"
            >
                <AlertDescription display="flex" width="100%" textAlign="center" justifyContent="center" ml="32px" fontFamily="proxima-soft" mr="2">
                    {message}
                </AlertDescription>
                <CloseButton mr="1" onClick={onClose} />
            </Alert>
        </Box>
    ),
});

export default getToast;
