import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {fetchFreeHomeschoolPrices, fetchFreeTeacherPrices, fetchLifetimePrices, fetchPrices} from "../../api/prices";
import { DEFAULT_REDEEM_MONTHLY_PRICE_SLUG, DEFAULT_REDEEM_YEARLY_PRICE_SLUG } from "../../constants/constants";

const getPrices = createAsyncThunk(
  'prices/getPrices',
  async (country, thunkAPI) => {
    try {
      const data = await fetchPrices(country);
      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

const getLifetimePrices = createAsyncThunk(
    'prices/getLifetimePrices',
    async (country, thunkAPI) => {
      try {
        const data = await fetchLifetimePrices(country);
        return data;
      } catch (e) {
        return thunkAPI.rejectWithValue(e);
      }
    },
);

const getFreeTeacherPrices = createAsyncThunk(
    'prices/getTeacherPrices',
    async (country, thunkAPI) => {
      try {
        const data = await fetchFreeTeacherPrices(country);
        return data;
      } catch (e) {
        return thunkAPI.rejectWithValue(e);
      }
    },
);

const getFreeHomeschoolPrices = createAsyncThunk(
  'prices/getFreeHomeschoolPrices',
  async (country, thunkAPI) => {
    try {
      const data = await fetchFreeHomeschoolPrices(country);
      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

const initialState = {
  prices: null,
  loading: true,
  error: false,
};

/* eslint-disable no-param-reassign */
const planSlice = createSlice({
  name: 'prices',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getPrices.pending, state => {
      state.fulfilled = null;
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getPrices.fulfilled, (state, { payload }) => {
      state.error = false;
      state.prices = payload.sort((a, b) => a.amount - b.amount);
      state.loading = false;
    });
    builder.addCase(getPrices.rejected, state => {
      state.fulfilled = null;
      state.error = true;
      state.loading = false;
    });
    builder.addCase(getFreeTeacherPrices.pending, state => {
      state.prices = null;
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getFreeTeacherPrices.fulfilled, (state, { payload }) => {
      state.error = false;
      state.prices = payload.sort((a, b) => a.amount - b.amount);
      state.loading = false;
    });
    builder.addCase(getFreeTeacherPrices.rejected, state => {
      state.prices = null;
      state.error = true;
      state.loading = false;
    });
    builder.addCase(getFreeHomeschoolPrices.pending, state => {
      state.prices = null;
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getFreeHomeschoolPrices.fulfilled, (state, { payload }) => {
      state.error = false;
      state.prices = payload.sort((a, b) => a.amount - b.amount);
      state.loading = false;
    });
    builder.addCase(getFreeHomeschoolPrices.rejected, state => {
      state.prices = null;
      state.error = true;
      state.loading = false;
    });
    builder.addCase(getLifetimePrices.pending, state => {
      state.prices = null;
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getLifetimePrices.fulfilled, (state, { payload }) => {
      state.error = false;
      state.prices = payload.sort((a, b) => a.amount - b.amount);
      state.loading = false;
    });
    builder.addCase(getLifetimePrices.rejected, state => {
      state.prices = null;
      state.error = true;
      state.loading = false;
    });
  },
});

const { reducer } = planSlice;

export const getPricesState = state => state.prices;
export const getAllPrices = state => getPricesState(state).prices;

export { getPrices, getFreeTeacherPrices, getFreeHomeschoolPrices, getLifetimePrices };

export default reducer;
