import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { Input } from '@chakra-ui/react';

import {
  formatCardNumber,
  formatCardExpiryDate,
  formatCardCvc,
} from '../../../utils/paymentUtils';

import Field from './Field';

const CardField = ({
  id,
  type,
  label,
  placeholder,
  control,
  required,
  errors,
  ...props
}) => {
  const [input, setInput] = useState('');

  const error = errors[id];

  const formatInput = (value) => {
    const digitsOnly = value.replace(/\D/g, '');

    const formatMap = {
      cardNumber: formatCardNumber(digitsOnly),
      cardExpiryDate: formatCardExpiryDate(digitsOnly),
      cardCvc: formatCardCvc(digitsOnly),
    };

    return formatMap[type];
  };

  const defaultPlaceholder = () => {
    const placeholderMap = {
      cardNumber: '1234 1234 1234 1234',
      cardExpiryDate: 'MM/YY',
      cardCvc: 'CVC',
    };

    return placeholderMap[type];
  };

  return (
    <Field id={id} label={label} error={error} required={required} {...props}>
      <Controller
        control={control}
        name={id}
        render={({ field }) => {
          const { onChange } = field;

          const handleOnChange = (e) => {
            const formatedNumber = formatInput(e.target.value);
            setInput(formatedNumber);
            onChange(formatedNumber);
          };

          return (
            <Input
              {...field}
              id={id}
              value={input}
              onChange={handleOnChange}
              type="text"
              fontSize="md"
              bg={error && 'errorBg'}
              color={error && 'errorText'}
              borderColor={error && 'error'}
              w="100%"
              h="56px"
              pt="16px"
              placeholder={defaultPlaceholder() || placeholder}
              _focus={{ color: 'primary' }}
              _placeholder={{ color: error ? 'errorText' : 'placeholder' }}
            />
          );
        }}
      />
    </Field>
  );
};

CardField.defaultProps = {
  label: '',
  placeholder: '',
  errors: null,
  required: false,
};

CardField.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['cardNumber', 'cardExpiryDate', 'cardCvc']).isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  control: PropTypes.shape().isRequired,
  required: PropTypes.bool,
  errors: PropTypes.shape(),
};

export default CardField;
