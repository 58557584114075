import React from 'react';
import {
    Box,
    Modal,
    ModalBody, ModalCloseButton,
    ModalContent, ModalHeader,
    ModalOverlay,
} from "@chakra-ui/react";

const AFAExternalPage = ({externalPageLink, checkAFAStatus}) => {
    return (
        <Modal
            isOpen
            closeOnOverlayClick={false}
            scrollBehavior="inside"
            size="ninety-five"
            onClose={checkAFAStatus}
        >
            <ModalOverlay />
            <ModalContent height="100%" width="ninety-five" borderRadius={0}>
                <ModalHeader textAlign="center">Secure payment page</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Box display="flex" width="100%" height="100%" borderWidth="4px" >
                        <iframe width="100%" height="100%" src={externalPageLink}></iframe>
                    </Box>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default AFAExternalPage;