import { createSlice } from '@reduxjs/toolkit';
import {loadStripe} from "@stripe/stripe-js";


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const initialState = {
    promise: stripePromise,
};

const couponSlice = createSlice({
    name: 'stripe',
    initialState,
});

const { reducer } = couponSlice;

export const getStripeData = state => state.stripe;
export const getStripePromise = state => getStripeData(state).promise;

export default reducer;
