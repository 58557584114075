import React, { useState } from 'react';
import { Box, Input, Text } from '@chakra-ui/react';
import { onlyNumericValues } from '../../utils/validationUtils';

const FormInput = ({
  value,
  type = 'text',
  label,
  inputName,
  handleChange,
  maximizedFontSize = '18px',
  mandatory,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const isLabelMinified = isFocused || value;
  return (
    <Box position="relative">
      <Text
        htmlFor={inputName}
        position="absolute"
        transition="top 0.2s, font-size 0.2s"
        top={isLabelMinified ? '10px' : '21px'}
        left="29px"
        color={isLabelMinified ? 'label' : 'placeholder'}
        fontSize={isLabelMinified ? '10px' : maximizedFontSize}
        fontFamily="proxima-nova"
        lineHeight={isLabelMinified ? '12px' : 'initial'}
        p={0}
        height="initial"
        display="block"
        margin={0}
        _focus={{
          fontSize: '10px',
          lineHeight: '12px',
          alignItems: 'center',
          color: 'label',
          top: '10px',
        }}
      >
        {`${label}${(mandatory && '*') || ''}`}
      </Text>
      <Input
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        id={inputName}
        w="100%"
        h="56px"
        fontSize="16px"
        fontFamily="proxima-nova"
        pt="20px"
        pr="20px"
        pb="8px"
        pl="24px"
        type={type}
        name={inputName}
        value={value}
        onChange={handleChange}
        resize="vertical"
        {...(type === 'number' ? { onKeyDown: onlyNumericValues } : {})}
        {...props}
      />
    </Box>
  );
};

export default FormInput;
