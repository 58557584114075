import React from 'react';
import {Text} from "@chakra-ui/react";

const Terms = (props) => {
    return (
        <span {...props}>
            By clicking continue you agree to Vooks{" "}
            <a
                href="https://www.vooks.com/termsandconditions"
                rel="noopener noreferrer"
                target="_blank"
            >
                <Text as="u">
                    Terms of Service
                </Text>
            </a>{" "}
            and{" "}
            <a
                href="https://www.vooks.com/privacy"
                rel="noopener noreferrer"
                target="_blank"
            >
                <Text as="u">
                    Privacy Policy
                </Text>
            </a>
          </span>
    )
}

export default Terms;