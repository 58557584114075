import React from 'react';
import MainLayout from "../layouts/components/MainLayout/MainLayout";
import DefaultFlowContainer from "../containers/DefaultFlowContainer";
import RestrictByLocation from '../components/RestrictByLocation';
import { REDEEM_MATH_MASTERS } from '../constants/routes';
import { MATH_MASTERS_NOT_ALLOWED_COUNTRIES } from '../constants/constants';

const Default = () => {
    return (
        <MainLayout>
            <RestrictByLocation redirectUrl={REDEEM_MATH_MASTERS} restrictedLocations={MATH_MASTERS_NOT_ALLOWED_COUNTRIES}>
                <DefaultFlowContainer/>
            </RestrictByLocation>
        </MainLayout>
    );
};

export default Default;