import React, {useEffect, useState} from "react";
import {Elements} from "@stripe/react-stripe-js";
import "./Step2Start.css";
import CheckoutFormStart from "./CheckoutFormStart";
import {klaviyoEvent} from "../../utils/events";
import {useDispatch, useSelector} from "react-redux";
import {
    createSubscription,
    getCreateSubscriptionState,
    goToSection
} from "../../stores/registration";
import {REGISTRATION_SUMMARY} from "../../constants/routes";
import {EVENTS, SOURCES} from "../../constants/events";
import {PAYMENT_STATUSES} from "../../constants/stripe";
import {getStripePromise} from "../../stores/stripe";
import {getPrices, getPricesState} from "../../stores/prices";
import {selectPrice} from "../../utils/planUtils";
import {roundCentsAmount} from "../../utils/currencyUtils";
import {getPlanPricePreset} from "../../utils/decorators/planDecorator";
import StartFlowPricesList from "./StartFlowPricesList";
import UserForm from "../UserForm";
import Spinner from "../Spinner/Spinner";
import {Box, Text} from "@chakra-ui/react";
import {validateStep2Form} from "../../utils/validationUtils";
import {capitalize} from "lodash";
import AFAExternalPage from "../Modals/AFA/AFAExternalPage";
import {completeRegistration} from "../../utils/AFAUtils";
import AFAFailed from "../Modals/AFA/AFAFailed";
import useCustomToast from "../../lib/hooks/toast";
import {USER_CREATION_ENDPOINT} from "../../constants/backend-sources";
import useUTMs from "../../lib/hooks/useUTMs";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const Step2Start = ({state, setState, setSelectedPrice, selectedPrice, subscription, setSubscription}) => {
    const UTMs = useUTMs();

    const {password, email, newsletter, customerName, country} = state;

    const stripePromise = useSelector(getStripePromise);
    const dispatch = useDispatch();
    const toast = useCustomToast();
    const {prices, loading: loadingPrices} = useSelector(getPricesState);
    const [AFALink, setAFALink] = useState(null);
    const [AFASuccess, setAFASuccess] = useState(null);
    const [showSpinner, setShowSpinner] = useState(false);
    const {fulfilled, error, loading} = useSelector(getCreateSubscriptionState);
    const { executeRecaptcha } = useGoogleReCaptcha();


    const handleChange = (event) => {
        const target = event.target;
        const value = target.name === "newsletter" ? target.checked : target.value;
        const name = target.name;
        if (name === "subscription") {
            setSelectedPrice(selectPrice(prices, value));
            return;
        }
        setState({[name]: value});
    }

    useEffect(() => {
        if (fulfilled) {
            setSubscription(fulfilled.subscription);
            window.fbq("trackCustom", "Purchase - Annual", {
                currency: capitalize(selectedPrice.currency),
                value: `${roundCentsAmount(selectedPrice.amount)}`
            });
            window.fbq("trackCustom", "Purchase - Any");

            const productName = getPlanPricePreset(selectedPrice.metadata.slug).eventSlug;
            const productEventName = `${productName}_${EVENTS.PRODUCT_SELECTED_SUFFIX}`;

            klaviyoEvent(email, password, productEventName, {
                email,
                product: productName,
                subscribedToNewsletter: "" + true,
                source: SOURCES.START_FLOW.STEP_TWO,
                ...UTMs
            });
            dispatch(goToSection(REGISTRATION_SUMMARY));
        }
    }, [fulfilled])

    useEffect(() => {
        if (error) {
            const errorDetails = error?.message;
            if (errorDetails?.status === PAYMENT_STATUSES.REQUIRES_ACTION) {
                setAFALink(errorDetails?.external_url);
                setSubscription(errorDetails?.subscription);
            } else {
                toast({type: 'error', message: error.message, duration: 5000});
            }
        }
    }, [error]);

    useEffect(() => {
        setShowSpinner(loading);
    }, [loading]);

    const handleClick = async (error, paymentMethod) => {
        const validationMessage = validateStep2Form({error, customerName});
        if (validationMessage) {
            toast({type: 'error', message: validationMessage, duration: 5000});
            setShowSpinner(false);
            return;
        }

        const token = await executeRecaptcha('submit');

        dispatch(createSubscription({
            payload: {
                email,
                password,
                password_confirmation: password,
                join_newsletter: newsletter,
                country_code: country,
                price: selectedPrice.price,
                source: SOURCES.START_FLOW.WEB,
                paymentMethod: paymentMethod.id,
                UTMs,
                token,
            }, userCreationEndpoint: USER_CREATION_ENDPOINT.START
        }));
    }

    useEffect(() => {
        window.fbq("trackCustom", "Start Checkout - Page 2", {content_category: "checkout_02"});
        klaviyoEvent(email, password, EVENTS.AFTER_VALIDATION, {email, source: SOURCES.START_FLOW.AFTER_VALIDATION, ...UTMs});
    }, []);

    useEffect(() => {
        dispatch(getPrices(country));
    }, [country])

    useEffect(() => {
        if (selectedPrice) setSelectedPrice(selectPrice(prices, selectedPrice.metadata.slug));
    }, [prices])

    const checkAFAStatus = async () => setAFASuccess(await completeRegistration({
        email,
        password,
        country,
        subscription,
        UTMs
    }));

    useEffect(() => {
        if (AFASuccess !== null) {
            if (AFASuccess) {
                dispatch(goToSection(REGISTRATION_SUMMARY));
            } else {
                setAFALink(null);
                setShowSpinner(false);
            }
        }
    }, [AFASuccess]);

    return (<div>
            <div className="hero">
                <Text fontFamily="proxima-soft" fontSize="32px" fontWeight="800" margin="8px" lineHeight="110%">
                    Enter Card Information
                </Text>
            </div>
            <div className="step">
                {showSpinner && (<div className="overlay">
                        <div className="spinner"/>
                    </div>)}
                <h2 className="section-title">Step 3 of 3</h2>
                <div className="form">
                    <UserForm handleChange={handleChange} country={country}/>
                    <hr className="topic-divider"/>
                    <Box mt="16px">
                        {loadingPrices && (
                            <div className="centered-spinner">
                                <Spinner className="spinner-non-fixed"/>
                            </div>
                        )}
                        {!loadingPrices && prices && (
                            <StartFlowPricesList handleChange={handleChange} selectedPrice={selectedPrice}/>)}
                        <Elements
                            stripe={stripePromise}
                            options={{
                                fonts: [{cssSrc: "https://use.typekit.net/kbk5xha.css"}],
                            }}
                        >
                            {selectedPrice && !loadingPrices && (
                                <CheckoutFormStart
                                    state={state}
                                    selectedPrice={selectedPrice}
                                    handleChange={handleChange}
                                    clickHandler={handleClick}
                                    customerName={customerName}
                                    setShowSpinner={setShowSpinner}
                                />)}
                        </Elements>
                    </Box>
                </div>
            </div>
            {AFALink && <AFAExternalPage externalPageLink={AFALink} checkAFAStatus={checkAFAStatus}/>}
            {AFASuccess !== null && !AFASuccess && <AFAFailed/>}
        </div>);
}

export default Step2Start;
