import React from "react";
import {getAllPrices} from "../../stores/prices";
import {roundCentsAmount} from "../../utils/currencyUtils";
import {useSelector} from "react-redux";
import {selectPrice} from "../../utils/planUtils";
import {Box} from "@chakra-ui/react";

const DefaultFlowPricesList = ({ selectedPrice, redeem, handleChange, ...props }) => {
    const prices = useSelector(getAllPrices);
    const monthlyPlan = selectPrice(prices, 'monthly_1');
    const yearlyPlan = selectPrice(prices, 'yearly_1');
    const { metadata: { slug: monthlyPlanSlug }} = monthlyPlan;
    const { metadata: { slug: yearlyPlanSlug }} = yearlyPlan;
    const onlyShowYearlyPrice = redeem;

    const slug = selectedPrice ? selectedPrice.metadata.slug : null;

    return (
        <Box {...props}>
            {!(onlyShowYearlyPrice) && (
                <label
                    className={`radio-container radio-container--monthly ${
                        slug === monthlyPlanSlug ? "radio-container--checked" : ""
                    }`}
                    onClick={handleChange}
                >
                    <div className="css-isurb6">
                        <span className="css-nm28jr">Billed Monthly</span>
                    </div>
                    <input
                        type="radio"
                        name="subscription"
                        value={monthlyPlanSlug}
                        checked={slug === monthlyPlanSlug}
                        readOnly
                    />
                    {`${monthlyPlan.currencySymbol}${roundCentsAmount(monthlyPlan.amount)}`}
                    <span className="step-time">/ Month</span>
                    <span className="radio-checkmark"></span>
                </label>
            )}
            <label
                className={`radio-container radio-container--yearly ${
                    slug === yearlyPlanSlug
                        ? "radio-container--checked"
                        : ""
                }`}
                onClick={handleChange}
            >
                <div className="css-isurb6">
                          <span className="css-s6blyv">
                            Billed Yearly{" "}
                              {!(onlyShowYearlyPrice) && (
                                  <b className="css-c4ccvv">Save 17%</b>
                              )}
                          </span>
                </div>
                <input
                    type="radio"
                    name="subscription"
                    value={yearlyPlanSlug}
                    checked={slug === yearlyPlanSlug}
                    readOnly
                />
                {`${yearlyPlan.currencySymbol}${roundCentsAmount(onlyShowYearlyPrice ?  yearlyPlan.amount : yearlyPlan.amount / 12)} `}
                <span className="step-time">/ {onlyShowYearlyPrice ? "Year" : "Month"}</span>
                <span className="radio-checkmark"></span>
            </label>
        </Box>
    )
}

export default DefaultFlowPricesList;
