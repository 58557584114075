import React from 'react';
import {Header} from "../../../components/Header/Header";
import {Box} from "@chakra-ui/react";
import {useSelector} from "react-redux";
import {getBackgroundPath} from "../../../utils/decorators/backgroundDecorator";
import {getCurrentBackgroundSlug} from "../../../stores/registration";

const MainLayout = ({ children }) => {
    const backgroundSlug = useSelector(getCurrentBackgroundSlug);
    return (
        <>
            <Box
                height="100vh"
                width="100%"
                backgroundAttachment="fixed"
                backgroundImage={`url(${getBackgroundPath(backgroundSlug)})`}
                backgroundRepeat="no-repeat"
                backgroundSize={{ md: "cover", base: "contain" }}
                backgroundPosition="bottom"
                position="fixed"
                top={0}
                zIndex={-1}
                display="block"
            />
            <Box className="App" display="flex" flexDir="column">
                <Header />
                <Box flex="1" display="flex" alignItems="center" mb="4">
                {children}
                </Box>
            </Box>
        </>
    )
};

export default MainLayout;