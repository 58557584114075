import axios from 'axios';
import { KLAVIYO_DISABLED } from '../constants/constants';

const logUser = (email, password) => {
  return axios.post(process.env.REACT_APP_WEB_API + '/auth/login', {
    email,
    password,
  });
};

const executeEvent = (eventName, properties, token) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  axios
    .post(
      process.env.REACT_APP_WEB_API + '/klaviyo/events',
      {
        event_name: eventName,
        properties,
      },
      { headers },
    )
    .catch((e) => {
      console.error(e);
    });
};

const executePublicEvent = (eventName, email, properties) => {
  axios
    .post(process.env.REACT_APP_WEB_API + '/klaviyo/public/events', {
      event_name: eventName,
      email,
      properties,
    })
    .catch((e) => {
      console.error(e);
    });
};

const klaviyoEvent = (email, password, eventName, properties) => {

  if(KLAVIYO_DISABLED){
    return;
  }

  const userToken = localStorage.getItem('userToken');

  if (userToken) {
    executeEvent(eventName, properties, userToken);
  } else {
    return logUser(email, password)
      .then((res) => {
        localStorage.setItem('userToken', res.data.token);
        executeEvent(eventName, properties, res.data.token);
        return res.data.token;
      })
      .catch(() => {
        executePublicEvent(eventName, email, properties);
      });
  }
};

export { klaviyoEvent };
