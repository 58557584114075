import React from 'react';
import { Heading } from '@chakra-ui/react';

const GiftingTitle = () => (
  <Heading
    fontFamily="proxima-soft"
    margin="8px"
    w="56"
    mx="auto"
    mb="6"
    textAlign="center"
  >
    Give The Gift Of Vooks
  </Heading>
);

export default GiftingTitle;
