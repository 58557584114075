import React from 'react';
import PropTypes from 'prop-types';

import { Box, FormControl, FormLabel } from "@chakra-ui/react";

const FormField = ({ label, children, ...props }) => {
  return (
    <FormControl
      pt={label ? 6 : 3}
      pb="3"
      pl="5"
      pr="2"
      rounded="md"
      borderWidth={1}
      borderColor="border"
      backgroundColor="white"
      {...props}
    >
      <FormLabel
        position="absolute"
        color="placeholder"
        fontSize="10px"
        pl="16px"
        pt={0}
        mt="4px"
        top="0px"
        left="3px"
      >
        {label}
      </FormLabel>
      <Box>
        {children}
      </Box>
    </FormControl>
  );
};

FormField.propTypes = {
  label: PropTypes.string,
  children: PropTypes.element.isRequired,
};

export default FormField;
