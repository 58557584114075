import React from 'react';
import { Box, Button, Image } from '@chakra-ui/react';

import heartFillIcon from '../../../static/assets/images/heartFill.svg';
import RedeemTitle from './RedeemTitle';

const SUCCESS_REDIRECT = process.env.REACT_APP_WEB + '/login/';

const Success = () => {
  const handleRedirect = () => {
    window.location.href = SUCCESS_REDIRECT;
  };

  return (
    <Box>
      <Image marginY="3px" src={heartFillIcon} w="20" mx="auto" mb="8" />
      <RedeemTitle> Thank You For Subscribing!</RedeemTitle>
      <Button
        onClick={handleRedirect}
        background="highlight"
        color="white"
        mt="6"
        borderRadius="full"
        fontWeight="bold"
        fontSize="lg"
        w="full"
        size="lg"
        _hover={{ background: 'hover' }}
      >
        Start Watching
      </Button>
    </Box>
  );
};

export default Success;
