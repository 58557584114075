import React, { useState } from 'react';
import { eraseCookie, setCookie } from '../../utils/cookies';
import './RegistrationSummary.css';
import Loading from '../Loading/Loading';
import {
  decoratePaymentDateTitleOneLineText,
  decoratePaymentMethod,
  decoratePriceAmountOnelineText,
  decorateSubscriptionOnelineText,
  decorateTodayPayment,
  decorateTotalTitle,
} from '../../utils/decorators/planDecorator';
import { login } from '../../api/auth';
import { Flex, Link, Stack, StackDivider, Text } from '@chakra-ui/react';
import { USER_CREATED_MESSAGE } from '../../constants/messages';
import { getSubscriptionExpirationDate } from '../../utils/dateUtils';
import useUTMs from '../../lib/hooks/useUTMs';
import { buildURL } from '../../utils/URLBuilders';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import HearthIcon from '../Icons/heartIcon';
import { VOOKS_RESOURCES_URL } from '../../constants/routes';
import { isLifetimePrice } from '../../utils/planUtils';
import { useSelector } from 'react-redux';
import { getCouponInfoState } from '../../stores/registration';
import {
  DEFAULT_REDEEM_SLUG,
  VOOKS_LOGIN_FORCELOGOUT_URL,
} from '../../constants/constants';

const SUCCESS_REDIRECT = process.env.REACT_APP_WEB + '/login/';
const RESET_REDIRECT = process.env.REACT_APP_WEB + '/forgot_password';
const COOKIE_DOMAIN = process.env.REACT_APP_COOKIE_DOMAIN;
const SUCCESS_REDIRECT_MM =
  process.env.REACT_APP_WEB + VOOKS_LOGIN_FORCELOGOUT_URL;

const RegistrationSummary = ({
  state,
  selectedPrice,
  subscription,
  usedCode,
  percentOff,
  coupons,
  isMathMasters = false,
}) => {
  const [showSpinner, setShowSpinner] = useState(false);
  const UTMs = useUTMs();
  const { coupon } = useSelector(getCouponInfoState);
  const daysAccess = coupon?.metadata?.days_access;
  const {
    email,
    password,
    customerExists,
    isSubscription,
    isAmazonPaySubscription,
    defaultFlow,
    start,
    redeem,
    readAlong,
    buyNGive,
  } = state;

  const handleClick = async () => {
    setShowSpinner(true);
    if (isMathMasters) {
      eraseCookie('token', COOKIE_DOMAIN);
      window.location.href = SUCCESS_REDIRECT_MM;
      return;
    }
    if (customerExists) {
      window.location.href = RESET_REDIRECT;
    } else {
      eraseCookie('token', COOKIE_DOMAIN);
      try {
        const {
          data: { token },
        } = await login({ email, password });
        setCookie('token', token, COOKIE_DOMAIN, 1);
      } catch (error) {
        setShowSpinner(false);
      }
      window.location.href = buildURL({ base: SUCCESS_REDIRECT, params: UTMs });
    }
  };

  return (
    <div>
      <Stack maxW="367px" mt={0} mb="40px" mx="auto" spacing="1rem">
        <Flex justifyContent="center">
          <HearthIcon />
        </Flex>
        <Text
          fontFamily="proxima-soft"
          fontSize="32px"
          fontWeight="800"
          lineHeight="110%"
        >
          Thank You{redeem ? '' : ' For Subscribing'}!
        </Text>
        <Text fontFamily="proxima-soft">{USER_CREATED_MESSAGE}</Text>
        <Text fontFamily="proxima-soft" fontWeight={800}>
          {email}
        </Text>
        <Link
          href={VOOKS_RESOURCES_URL}
          fontWeight="800"
          color="highlight"
          isExternal
        >
          Click here to access our FREE classroom resources{' '}
          <ExternalLinkIcon mx="2px" />
        </Link>
      </Stack>
      <div className="step">
        {showSpinner && <Loading />}
        <Stack
          opacity={1}
          borderWidth="2px"
          borderStyle="solid"
          boxSizing="border-box"
          borderRadius={10}
          fontSize={16}
          spacing={4}
          mx={0}
          mb={8}
          py="1rem"
          divider={<StackDivider borderColor="gray.200" />}
        >
          {!readAlong && percentOff !== 100 && (
            <Flex justifyContent="space-between" px={4}>
              <Text textTransform="capitalize">
                {decorateSubscriptionOnelineText(selectedPrice)}
              </Text>
              <Text>{decoratePriceAmountOnelineText(selectedPrice)}</Text>
            </Flex>
          )}
          {percentOff !== 100 && (
            <Flex justifyContent="space-between" px={4}>
              <Text>Payment Method</Text>
              <Text>
                {decoratePaymentMethod(
                  redeem,
                  readAlong,
                  isSubscription,
                  isAmazonPaySubscription,
                  usedCode,
                )}
              </Text>
            </Flex>
          )}
          {!start && (usedCode || !!coupons?.length) && (
            <Flex justifyContent="space-between" px={4}>
              <Text>Code Used</Text>
              {usedCode && <Text>{usedCode || 'No'}</Text>}
              {!!coupons?.length && (
                <Text textAlign="right">
                  {coupons.length > 1 ? 'Multiple' : coupons[0].name}
                </Text>
              )}
            </Flex>
          )}
          {isSubscription && !isLifetimePrice(selectedPrice) && (
            <Flex justifyContent="space-between" px={4}>
              <Text>{decoratePaymentDateTitleOneLineText(defaultFlow)}</Text>
              <Text>
                {/* TODO: Make sure expiration date is correct for promotional subscriptions made through /redeem flow */}
                {getSubscriptionExpirationDate(
                  subscription,
                  percentOff,
                  daysAccess,
                  'YYYY-MM-DD',
                )}
              </Text>
            </Flex>
          )}
          {selectedPrice !== DEFAULT_REDEEM_SLUG && (
            <Flex justifyContent="space-between" px={4} fontWeight={800}>
              <Text>{decorateTotalTitle(selectedPrice, percentOff)}</Text>
              <Text>
                {decorateTodayPayment(selectedPrice, buyNGive, coupon)}
              </Text>
            </Flex>
          )}
        </Stack>
        {customerExists && (
          <div className="step3__customer_exists">
            <span>
              An account with your email already exists, please reset your
              password:{' '}
            </span>
            <br />
            <input
              type="button"
              value="Reset Password"
              onClick={handleClick}
              className="input--button"
            />
          </div>
        )}
        {!customerExists && (
          <input
            type="button"
            value="Start Watching"
            onClick={handleClick}
            className="input--button"
          />
        )}
      </div>
    </div>
  );
};

export default RegistrationSummary;
