import React from 'react';
import { useHistory } from 'react-router-dom';
import { Text, Button } from '@chakra-ui/react';

import { GIFT } from '../../constants/routes';

const GiftingCta = () => {
  const history = useHistory();

  const handleLink = () => history.push(GIFT);

  return (
    <Text textAlign="center" mb="4">
      Looking to share the joy of reading? Treat a loved one to a subscription
      by clicking{' '}
      <Button onClick={handleLink} variant="link" fontWeight="800">
        here!
      </Button>
    </Text>
  );
};

export default GiftingCta;
