import React from 'react';
import MainLayout from '../layouts/components/MainLayout/MainLayout';
import RedeemMathMastersFlowContainer from '../containers/RedeemMathMastersFlowContainer';

const RedeemMathMasters = () => {
  return (
    <MainLayout>
      <RedeemMathMastersFlowContainer />
    </MainLayout>
  );
};

export default RedeemMathMasters;
