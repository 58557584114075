const ERRORS = {
  accountAlreadyExists:
    'There is already a Vooks account created with that email. For the moment, only new users can receive gift codes. Sorry for the inconvenience.',
  loadingPrices:
    'Ops, something went wrong loading prices, please try again later.',
  purchaseCode:
    'Ops, something went wrong with the purchase, please try again later.',
  redeemCode:
    'Ops, something went wrong redeeming the code, please try again later.',
};

export { ERRORS };
