export const roundCentsAmount = cents => (cents / 100).toFixed(2);

export const convertToCents = number => Number(number) * 100

export const totalTodayPrice = (isLifetime, buyNGive, value, coupon) => {

    if (buyNGive && value) {
      return roundCentsAmount(value);
    }
    
    if (isLifetime && coupon) {
        const {percent_off, amount_off} = coupon
        const price = percent_off? value - ((value * percent_off) / 100): value - amount_off
        return roundCentsAmount(Math.max(0, price))
    }
    
    if(isLifetime){
      return roundCentsAmount(value)
    }

    return roundCentsAmount(0)
  };
  