import React from "react";
import {Box, Checkbox as Tickbox, Text} from "@chakra-ui/react";

const CheckBox = ({ inputName, boxProps, comboBoxProps, textProps, onChange, isChecked, text }) => {
    return (
        <Box textAlign="center" {...boxProps}>
            <Tickbox
                size="lg"
                padding={0}
                name={inputName}
                colorScheme="blue"
                onChange={onChange}
                isChecked={isChecked}
                {...comboBoxProps}
            >
                <Text fontSize="1rem" {...textProps}>
                    {text}
                </Text>
            </Tickbox>
        </Box>
    )
}

export default CheckBox;
