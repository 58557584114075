import React from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Stack, Text } from '@chakra-ui/react';

import { TFA } from '../../../../constants/routes';

import { goToSection, setGiftingRedeemData } from '../../../../stores/gifting';

import RedeemTitle from '../RedeemTitle';
import Terms from '../../../Terms/Terms';
import { InputForm, CheckBoxForm } from '../../../ReactHookForm';

import validationSchema from './validationSchema';

const Step1 = () => {
  const dispatch = useDispatch();

  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isValid },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues: { newsLetter: false },
  });

  const handleOnSubmit = async (data) => {
    dispatch(setGiftingRedeemData(data));
    dispatch(goToSection(TFA));
  };

  return (
    <Box as="form" textAlign="left" onSubmit={handleSubmit(handleOnSubmit)}>
      <RedeemTitle>Create Account to Redeem your Giftcode</RedeemTitle>
      <Text fontWeight="bold" mb="6">
        Step 1 of 3
      </Text>
      <Stack spacing="0.5rem">
        <InputForm
          id="email"
          label="Email Address"
          register={register}
          control={control}
          errors={errors}
          required
          mb="0"
        />
        <InputForm
          id="password"
          label="Password"
          type="password"
          register={register}
          control={control}
          errors={errors}
          required
        />
        <InputForm
          id="confirmPassword"
          label="Confirm password"
          type="password"
          register={register}
          control={control}
          errors={errors}
          required
        />
        <CheckBoxForm
          id="newsLetter"
          label="I'd like to receive updates from Vooks"
          register={register}
          mt="4"
          ml="4"
        />
      </Stack>
      <Terms className="terms" style={{ fontFamily: 'proxima-nova' }} />
      <Button
        type="submit"
        background="highlight"
        color="white"
        mt="8px"
        borderRadius="full"
        fontWeight="bold"
        fontSize="lg"
        w="full"
        size="lg"
        _hover={{ background: 'hover' }}
        isDisabled={!isValid}
      >
        Agree & Continue
      </Button>
    </Box>
  );
};

export default Step1;
