import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Text, Divider, Button, Image } from '@chakra-ui/react';

import { DEFAULT_APP_COUNTRY_CODE } from '../../../constants/constants';
import { GIFT_SUCCESS } from '../../../constants/routes';
import useCustomToast from '../../../lib/hooks/toast';

import {
  getGiftingData,
  getGiftingPricesState,
  getGiftingPrices,
  purchaseCode,
  getGiftingPurchaseCodeState,
  goToSection,
  goToPreviousSection,
} from '../../../stores/gifting';

import Prices from './Prices';
import Payment from './Payment';
import GiftingTitle from '../GiftingTitle';
import { ERRORS } from '../texts';
import UserForm from '../../UserForm';
import Spinner from '../../Spinner/Spinner';

import backArrow from '../../../static/assets/images/backArrow.svg';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const Step2 = ({ onCancel }) => {
  const dispatch = useDispatch();

  const toast = useCustomToast();

  const [country, setCountry] = useState(DEFAULT_APP_COUNTRY_CODE);
  const [selectedPrice, setSelectedPrice] = useState(null);

  const giftingData = useSelector(getGiftingData);
  const {
    codeData,
    loading,
    error: purchaseError,
  } = useSelector(getGiftingPurchaseCodeState);
  const {
    prices,
    loading: loadingPrices,
    error: pricesError,
  } = useSelector(getGiftingPricesState);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleSelectCountry = ({ target: { value } }) => setCountry(value);

  useEffect(() => {
    dispatch(getGiftingPrices(country));
  }, [dispatch, country]);

  useEffect(() => {
    if (pricesError)
      toast({ type: 'error', message: ERRORS.loadingPrices, duration: 5000 });
  }, [pricesError, toast]);

  useEffect(() => {
    if (purchaseError)
      toast({ type: 'error', message: ERRORS.purchaseCode, duration: 5000 });
  }, [purchaseError, toast]);

  useEffect(() => {
    if (codeData) dispatch(goToSection(GIFT_SUCCESS));
  }, [codeData, dispatch]);

  const handlePurchase = async(paymentData) => {
    const token = await executeRecaptcha('submit');
    dispatch(
      purchaseCode({
        paymentInfo: {
          type: 'card',
          ...paymentData,
        },
        giftInfo: {
          plan: selectedPrice.price,
          ...giftingData,
        },
        token,
      }),
    );
  };

  const handleBack = () => dispatch(goToPreviousSection());

  return (
    <Box textAlign="left" mt="4">
      <Button
        onClick={handleBack}
        variant="unstyled"
        display="flex"
        justifyContent="center"
        position="absolute"
        top="4"
        left="4"
        leftIcon={<Image src={backArrow} w="4" />}
      >
        <Text fontWeight="800" fontSize="lg">
          BACK
        </Text>
      </Button>
      <GiftingTitle />
      <Text fontWeight="800" mb="6">
        Step 2 of 2
      </Text>
      <Box>
        <UserForm handleChange={handleSelectCountry} country={country} />
        <Divider />
        <Box mt="6">
          {loadingPrices && (
            <Box className="centered-spinner">
              <Spinner className="spinner-non-fixed" />
            </Box>
          )}
          {!loadingPrices && prices && <Prices onChange={setSelectedPrice} />}
        </Box>

        {selectedPrice && country && (
          <Payment selectedPrice={selectedPrice} onSubmit={handlePurchase} />
        )}
      </Box>

      <Button
        onClick={onCancel}
        variant="ghost"
        color="playerBg"
        mt="8px"
        borderRadius="full"
        fontWeight="bold"
        fontSize="lg"
        w="full"
        _hover={{ background: 'none' }}
        size="lg"
        isDisabled={loading}
      >
        Cancel
      </Button>
    </Box>
  );
};

Step2.propTypes = {
  onCancel: PropTypes.func.isRequired,
};

export default Step2;
