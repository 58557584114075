import {MAX_NUMBER_TRIES, REQUEST_TIMER_TRIES, TRIES_EXPIRATION_SECS} from "../constants/TFA";
import dayjs from "dayjs";

export const getNextTryDate = (tries = 0) => {
    const nextSecondsInterval = REQUEST_TIMER_TRIES[tries < MAX_NUMBER_TRIES ? tries : MAX_NUMBER_TRIES - 1];
    return dayjs().add(nextSecondsInterval, 'second').toISOString();
};

export const getTriesExpirationDate = () => dayjs().add(TRIES_EXPIRATION_SECS, 'second').toISOString();
export const getNextTryAttempt = ({triesExpirationDate, tries}) => {
    if (!triesExpirationDate || tries === null) return 0;
    return dayjs().isBefore(dayjs(triesExpirationDate)) ? tries + 1 : 0;
};