import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Text, useRadio } from '@chakra-ui/react';

const PricingItem = ({
  currencySymbol,
  price,
  priceText,
  pillText,
  pillProps,
  discount,
  ...radio
}) => {
  const [hover, setHover] = useState(false);

  const {
    state: { isChecked },
    getInputProps,
  } = useRadio({ ...radio });

  const transition = (prop) => `${prop} 0.1s ease-in-out`;
  const borderColor = () => {
    let color = 'border';

    if (isChecked) color = 'highlight';
    if (hover) color = 'placeholder';

    return color;
  };

  return (
    <Box
      as="label"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <input {...getInputProps({})} />
      <Flex
        position="relative"
        justifyContent="center"
        border="1px solid"
        borderColor={borderColor}
        borderRadius="xl"
        p="6"
        mt="6"
        transition={transition('border-color')}
      >
        {pillText && (
          <Box
            position="absolute"
            bg="highlightLt"
            border="1px solid"
            borderColor={borderColor}
            borderRadius="full"
            top="-12px"
            px="4"
            fontSize="14px"
            color="primary"
            transition={transition('border-color')}
            {...pillProps}
          >
            <Text as="span">{pillText}</Text>{' '}
            {discount && (
              <Text as="span" color="highlight">
                Save {discount}%
              </Text>
            )}
          </Box>
        )}
        <Flex alignItems="center" w="full">
          <Flex
            justifyContent="center"
            alignItems="center"
            w="7"
            h="7"
            bg="white"
            border="3px solid"
            borderColor={isChecked ? 'highlight' : 'border'}
            borderRadius="full"
            transition={transition('border-color')}
          >
            <Box
              w="4"
              h="4"
              borderRadius="full"
              bg={isChecked ? 'highlight' : 'white'}
              transition={transition('background')}
            ></Box>
          </Flex>
          <Text fontSize="2xl" fontWeight="bold" ml="4">
            {currencySymbol}
            {price}
            <Text as="span" fontSize="md">
              {priceText}
            </Text>
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
};

PricingItem.propTypes = {
  currencySymbol: PropTypes.string.isRequired,
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  priceText: PropTypes.string,
  pillText: PropTypes.string,
  pillProps: PropTypes.shape({}),
  discount: PropTypes.string,
};

export default PricingItem;
