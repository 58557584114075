import React from "react";
import PropTypes from 'prop-types';

const Loading = ({overrideBackground}) => {
    return (
        <div className="overlay" style={{backgroundColor: overrideBackground}}>
            <div className="spinner" />
        </div>
    );
};

Loading.propTypes = {
    overrideBackground: PropTypes.string,
  };

export default Loading;
