// Validation messages

export const INVALID_EMAIL = 'Whoops! Can you double check your email address?';
export const INVALID_PASSWORD_LENGTH =
  'Your password must be at least 8 characters. The stronger, the better!';
export const INVALID_PASSWORD_CHARS =
  'Your password must have at least a symbol. The stronger, the better!';
export const CUSTOMER_ALREADY_EXISTS =
  'Sorry, that email address already has a Vooks account';

export const NO_CC_CUSTOMER_NAME = 'Please indicate the name on the card';

export const CAPTCHA_SERVICE_UNAVAILABLE = 'Captcha service not available';

export const USER_CREATED_MESSAGE = 'We’ve sent an email confirmation to';
export const USER_DELAYED_MESSAGE =
  "We’re sorry, but we're currently experiencing a high volume of new subscriptions. We’ll email you as soon as your account is ready.";

export const NO_COMMITMENT_CA = 'No commitment cancel anytime';
export const PURCHASE_AND_RECEIVE_CODES_TO_SHARE =
  'Purchase and receive codes to share';

export const HUMAN_VERIFY =
  'We need to verify that you are a human, please slide to unlock';
export const TFA_CODE_REQUEST_SENT = 'A new code was sent to your email';
export const TFA_CODE_REQUEST_FAILED =
  'Oops!, there was a problem sending your code, please try again in a few minutes';
export const TFA_CODE_VERIFICATION_FAILED =
  'The code you entered is either expired or invalid, please try again or generate a new code';
export const GENERIC_ERROR = 'There was an error, please try again later';
export const COUPON_MANDATORY = 'You must specify a coupon';
export const COUPON_APPLY_ERROR =
  'This coupon is not compatible with your current subscription. Please contact customer support.';
export const COUPON_APPLY_UNAVAILABLE =
  'The coupon you are trying to apply is not available at this time. Please try again later.';
export const DONT_FORGET_TO_APPLY_CODE =
  "Don't forget to apply your coupon/promo code!";
export const GENERIC_COUPON_APPLY_ERROR =
  'There was an error redeeming this coupon. Please contact customer support.';

// Payment methods
export const CODE = 'Code';
export const CC_WITH_CODE = 'CC with code';
export const CREDIT_CARD = 'Credit card';
