import useQuery from "./useQuery";
import { QUERY_PARAMS_IDS } from "../../constants/standards";

const useUTMs = () => {
  const searchParams = useQuery();
  const utmParams = {};
  searchParams.forEach((value, key) => {
    if (Object.values(QUERY_PARAMS_IDS.UTM).includes(key)) utmParams[key] = value
  })
  return utmParams;
}

export default useUTMs;
