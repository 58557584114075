import React, {useCallback, useState} from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement
} from "@stripe/react-stripe-js";
import "../DefaultFlow/CheckoutForm.css";
import {AMAZON_PAY, COMMON_PAY} from "../../constants/constants";
import AmazonPayButton from "../Amazon/AmazonPayButton";
import {getLegalTermsDecoration} from "../../utils/decorators/checkoutFormDecoration";
import PaymentMethodSelection from "../PaymentMethodSelection/PaymentMethodSelection";
import {cardElementDecoration} from "../../utils/decorators/paymentMethodDecoration";
import {roundCentsAmount} from "../../utils/currencyUtils";
import {getShortPeriodName} from "../../utils/couponsUtils";
import { Button, HStack, Input, VStack } from "@chakra-ui/react";
import FormField from "../Form/Field";
import {countryRequireZIPCode, isPostalCodeValid, onlyNumericValues} from "../../utils/validationUtils";
import useCustomToast from "../../lib/hooks/toast";
import { LENGTH_STANDARDS } from "../../constants/standards";

const AMAZON_PAY_BUTTON_ENABLED = process.env.REACT_APP_AMAZON_PAY_BUTTON_ENABLED === 'true';

const CheckoutFormStart = ({
  state,
  selectedPrice,
  handleChange,
  clickHandler,
  setShowSpinner,
}) => {
  const { email, password, customerName, country } = state;

  const stripe = useStripe();
  const elements = useElements();
  const toast = useCustomToast();

  const [paymentOption, setPaymentOption] = useState(!AMAZON_PAY_BUTTON_ENABLED && COMMON_PAY);
  const [postalCode, setPostalCode] = useState('');
  const [billingFieldsFulfilled, setBillingFieldsFulfilled] = useState({
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false,
  });

  const handleChangeCCField = useCallback(a => {
    const { complete, elementType } = a;
    const newFields = { ...billingFieldsFulfilled };
    newFields[elementType] = complete;
    setBillingFieldsFulfilled(newFields);
  }, [billingFieldsFulfilled]);

  const setPostalCodeHandler = useCallback(({ target: { value } }) => {
    if (value.length < LENGTH_STANDARDS.MAX_ZIPCODE_LENGTH) setPostalCode(value);
  }, []);

  const handleSubmit = useCallback(async () => {
    if (countryRequireZIPCode(country) && !isPostalCodeValid(postalCode)) {
      toast({ type: 'error', message: "Please insert a valid postal code", duration: 5000 });
      return;
    }

    setShowSpinner(true)

    // Stripe.js has not loaded yet. Make sure to disable
    // form submission until Stripe.js has loaded.
    if (!stripe || !elements) return;

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardNumberElement);

    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: { name: customerName, address: { postal_code: postalCode } },
    });

    clickHandler(error, paymentMethod);
  }, [postalCode, stripe, elements, clickHandler, country]);

    return (
        <>
          {paymentOption === COMMON_PAY && (
            <VStack spacing="0.5rem" my="1rem">
              <HStack w="full" spacing="1rem">
                <FormField label="Name on card" py={2}>
                  <Input
                    placeholder="John Doe..."
                    name="customerName"
                    value={customerName}
                    onChange={handleChange}
                    border="none"
                    _focus={{ border: 'none' }}
                    fontSize="16px"
                    fontWeight="300"
                    color="text"
                    px={0}
                    pb={0}
                    pt={3}
                  />
                </FormField>
              </HStack>
              <HStack w="full" spacing="0.5rem">
                <FormField label="Card number">
                  <CardNumberElement
                    options={cardElementDecoration}
                  />
                </FormField>
                {countryRequireZIPCode(country) && (
                    <FormField label="ZIP" w="60%" py={2}>
                      <Input
                          value={postalCode}
                          type="number"
                          onKeyDown={onlyNumericValues}
                          placeholder="ZIP"
                          fontSize="16px"
                          fontWeight="300"
                          fontFamily="proxima-soft, sans-serif"
                          onChange={zip => setPostalCodeHandler(zip)}
                          border="none"
                          _focus={{ border: 'none' }}
                          color="text"
                          px={0}
                          pb={0}
                          pt={3}
                      />
                    </FormField>
                )}
              </HStack>
              <HStack w="full" spacing="0.5rem">
                <FormField label="Expiration date">
                  <CardExpiryElement
                    onChange={handleChangeCCField}
                    options={cardElementDecoration}
                  />
                </FormField>
                <FormField label="Security code" w="60%">
                  <CardCvcElement
                    onChange={handleChangeCCField}
                    options={cardElementDecoration}
                  />
                </FormField>
              </HStack>
            </VStack>
          )}
          {paymentOption && (
              <div className="legal">
                  <div className="legal-pricing">
                      <div>
                          Today
                        <span className="paid-period">
                    {`${selectedPrice.currencySymbol}${roundCentsAmount(selectedPrice.amount)}/${getShortPeriodName(selectedPrice.interval)}`}
                  </span>
                      </div>
                  </div>
                  <div className="legal-divider"></div>
                  <span className="legal-terms">{getLegalTermsDecoration(selectedPrice)}</span>
              </div>)}
          {paymentOption === COMMON_PAY && (
            <Button
              backgroundColor="highlight"
              color="white"
              border="none"
              borderRadius="100px"
              fontFamily="proxima-soft"
              fontWeight={800}
              fontSize="18px"
              cursor="pointer"
              w="100%"
              height="56px"
              margin="16px 0"
              _hover={{background: "hover"}}
              onClick={handleSubmit}
            >
              Agree & Subscribe
            </Button>
          )}
          {paymentOption === AMAZON_PAY && (
              <AmazonPayButton
                  registrationDetails={{
                      email,
                      password,
                      plan: {amount: selectedPrice.amount, interval: selectedPrice.interval, hasTrial: true, buyNGive: true}
                  }}
                  className="amazon-checkout-btn"
              />
          )}
          {AMAZON_PAY_BUTTON_ENABLED &&
          (<PaymentMethodSelection paymentOption={paymentOption} setPaymentOption={setPaymentOption}/>)}
        </>
    );
};

export default CheckoutFormStart;
