import React from 'react';
import { Box } from '@chakra-ui/react';

import { getCouponDurationCopy } from '../../utils/couponsUtils';

const RedeemFlowPricesList = ({ durationDays, ...props }) => {
  if (!durationDays) return null;

  return (
    <Box {...props}>
      <label
        className="radio-container radio-container--checked"
        style={{ margin: '0px 0px' }}
      >
        <input type="radio" name="subscription" checked={true} readOnly />
        <span className="radio-checkmark" />
        <p>{getCouponDurationCopy(durationDays, true)}</p>
      </label>
    </Box>
  );
};

export default RedeemFlowPricesList;
