import React, { createContext } from 'react';
import Loading from '../Loading/Loading';
import useUserGeoLocation from '../../hooks/useUserGeoLocation';
import { MATH_MASTERS_NOT_ALLOWED_COUNTRIES } from '../../constants/constants';

export const LocationContext = createContext();

const RestrictByLocation = ({ children, redirectUrl, restrictedLocations = MATH_MASTERS_NOT_ALLOWED_COUNTRIES }) => {
    const { loading: loadingLocation, countryCode } = useUserGeoLocation({ redirectUrl, redirect: true, restrictedLocations });

    return (
        <LocationContext.Provider value={countryCode}>
          {loadingLocation && <Loading overrideBackground="#ffffff"/>}
          {children}
        </LocationContext.Provider>
    )
}

export default RestrictByLocation;
