import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {getCurrentSection} from "../../stores/registration";
import {CHECKOUT, FORM, REGISTRATION_SUMMARY, TFA} from "../../constants/routes";
import Start from "../../components/StartFlow/Start";
import Step2Start from "../../components/StartFlow/Step2Start";
import Step3 from "../../components/DefaultFlow/Step3";
import TwoFactorAuthentication from "../../components/TwoFactorAuthentication";
import {DEFAULT_APP_COUNTRY_CODE} from "../../constants/constants";

const StartFlowContainer = () => {
    const section = useSelector(getCurrentSection);
    const [selectedPrice, setSelectedPrice] = useState(null);
    const [subscription, setSubscription] = useState(null);
    const [validated, setValidated] = useState(null);
    const [state, setState] = useState({
        email: "",
        password: "",
        country: DEFAULT_APP_COUNTRY_CODE,
        start: true,
        newsletter: false,
        customerExists: false,
        isSubscription: false,
        trialEnd: 0,
        buyNGive: true,
        delayed: false,
        customerName: null,
    });

    const setStateFunc = (newState) => setState({ ...state, ...newState});

    return (
        <div className="steps-container">
            {section === FORM && <Start state={state} setState={setStateFunc} validated={validated} setValidated={setValidated}/>}
            {section === TFA && <TwoFactorAuthentication state={state} />}
            {section === CHECKOUT && (
                <Step2Start
                    state={state}
                    setState={setStateFunc}
                    selectedPrice={selectedPrice}
                    setSelectedPrice={setSelectedPrice}
                    setSubscription={setSubscription}
                    subscription={subscription}
                />
            )}
            {section === REGISTRATION_SUMMARY && (
                <Step3
                    state={state}
                    selectedPrice={selectedPrice}
                    subscription={subscription}
                />
            )}
        </div>
    )
}

export default StartFlowContainer;
