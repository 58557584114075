import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { RadioGroup, Text, useRadioGroup } from '@chakra-ui/react';

import { selectPrice } from '../../../utils/planUtils';
import { roundCentsAmount } from '../../../utils/currencyUtils';
import { getGiftingPricesState } from '../../../stores/gifting';
import { LIFETIME_DISABLED } from '../../../constants/constants';

import PriceItem from './PriceItem';

const Prices = ({ onChange }) => {
  const { prices } = useSelector(getGiftingPricesState);

  const yearlyPlan = selectPrice(prices, 'year_gift');
  const lifeTimePlan = selectPrice(prices, 'lifetime_1');

  const yearlyPlanSlug = yearlyPlan?.metadata?.slug;
  const lifeTimePlanSlug = lifeTimePlan?.metadata?.slug;

  const { getRadioProps, getRootProps } = useRadioGroup({
    onChange: (value) => onChange(selectPrice(prices, value)),
  });

  return (
    <>
      <Text textAlign="center" mb="10">
        Choose a one-time payment option
      </Text>
      <RadioGroup {...getRootProps()} mb="4">
        <PriceItem
          currencySymbol={yearlyPlan.currencySymbol}
          price={roundCentsAmount(yearlyPlan.amount)}
          pillText="1 Year"
          {...getRadioProps({ value: yearlyPlanSlug })}
        />
        {!LIFETIME_DISABLED && (
          <PriceItem
            currencySymbol={lifeTimePlan.currencySymbol}
            price={roundCentsAmount(lifeTimePlan.amount)}
            pillText="Lifetime"
            {...getRadioProps({ value: lifeTimePlanSlug })}
          />
        )}
      </RadioGroup>
    </>
  );
};

Prices.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default Prices;
