import logoIcon from "../../static/assets/images/logo.svg";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import { useHistory } from "react-router-dom";
import {getCurrentSection, getHasPreviousSection, goToPreviousSection} from "../../stores/registration";
import {REGISTRATION_SUMMARY, VOOKS_URL} from "../../constants/routes";
import "./Header.css"


export const Header = () => {
    const dispatch = useDispatch()
    const history = useHistory();
    const hasPreviousSection = useSelector(getHasPreviousSection);
    const section = useSelector(getCurrentSection);
    const hasPreviousPage = history.length > 1;
    const hasBackButton = (section !== REGISTRATION_SUMMARY) && (hasPreviousSection || hasPreviousPage);

    const redirectToVooksURL = () => window.location.href = VOOKS_URL;
    const goBack = () => hasPreviousSection ? dispatch(goToPreviousSection()) : history.goBack();

    return (
        <header>
            <div className="nav-bar">
                    {hasBackButton && (
                        <div className="back-button" onClick={goBack}>
                            <svg
                                viewBox="0 0 18 20"
                                focusable="false"
                                role="presentation"
                                className="back-button--img"
                            >
                                <path
                                    d="M11.6067 4.04173C12.4503 3.20861 12.4503 1.85786 11.6067 1.02474C10.7632 0.191623 9.39556 0.191623 8.55202 1.02474L0.992024 8.49141C0.148492 9.32453 0.148492 10.6753 0.992024 11.5084L8.55202 18.9751C9.39556 19.8082 10.7632 19.8082 11.6067 18.9751C12.4503 18.1419 12.4503 16.7912 11.6067 15.9581L7.73408 12.1332H15.4794C16.6723 12.1332 17.6394 11.1781 17.6394 9.9999C17.6394 8.82169 16.6723 7.86657 15.4794 7.86657H7.73408L11.6067 4.04173Z"
                                    fill="currentColor"
                                    fillRule="evenodd"
                                ></path>
                            </svg>
                            <span className="back-button--text">BACK</span>
                        </div>
                    )}
                <div className={`logo-img ${!hasBackButton ? "margin-left-mobile" : ""}`}>
                    <img src={logoIcon} alt="logo" className="logo" onClick={redirectToVooksURL} />
                </div>
                <div className="right-corner">
                    <a href="https://watch.vooks.com/login" className="login-link">
                        Log In
                    </a>
                </div>
            </div>
        </header>
    )
}
