import React from 'react';
import { Box, Text } from '@chakra-ui/react';

import {
  validatePasswordLength,
  validatePasswordEspecialCharacter,
} from '../../utils/validationUtils';

const PasswordValidation = ({ password }) => {
  const validateLength = validatePasswordLength(password);
  const validateEspecialCharacter = validatePasswordEspecialCharacter(password);

  const handleValidateColor = (validation) => (validation ? 'green' : 'red');

  return (
    <Box pl="2" fontSize="sm">
      <Text color={handleValidateColor(validateLength)}>
        At least 8 characters
      </Text>
      <Text color={handleValidateColor(validateEspecialCharacter)}>
        Contains a special character
      </Text>
    </Box>
  );
};

export default PasswordValidation;
