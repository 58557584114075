import React, {useCallback, useEffect, useState} from "react";
import { CardNumberElement, useStripe, useElements, CardCvcElement, CardExpiryElement } from "@stripe/react-stripe-js";
import "./CheckoutForm.css";
import {getCouponInfo, getShortPeriodName} from "../../utils/couponsUtils";
import {AMAZON_PAY, COMMON_PAY} from "../../constants/constants";
import AmazonPayButton from "../Amazon/AmazonPayButton";
import {getLegalTermsDecoration} from "../../utils/decorators/checkoutFormDecoration";
import PaymentMethodSelection from "../PaymentMethodSelection/PaymentMethodSelection";
import { cardElementDecoration } from "../../utils/decorators/paymentMethodDecoration";
import {roundCentsAmount} from "../../utils/currencyUtils";
import {useDispatch, useSelector} from "react-redux";
import {checkCoupon, clearAllCouponInfo, getCouponInfoState} from "../../stores/registration";
import useCustomToast from "../../lib/hooks/toast";
import useQuery from "../../lib/hooks/useQuery";
import { LENGTH_STANDARDS, QUERY_PARAMS_IDS } from "../../constants/standards";
import { Button, HStack, Input, InputGroup, InputRightElement, VStack } from "@chakra-ui/react";
import FormField from "../Form/Field";
import {countryRequireZIPCode, isPostalCodeValid, onlyNumericValues} from "../../utils/validationUtils";
import usePreApplyCode from "../../lib/hooks/usePreApplyCode";

const AMAZON_PAY_BUTTON_ENABLED = process.env.REACT_APP_AMAZON_PAY_BUTTON_ENABLED === 'true';

const CheckoutForm = ({
  state,
  selectedPrice,
  handleChange,
  clickHandler,
  setShowSpinner,
  code,
  setCode,
}) => {

  const query = useQuery();
  const promo = query.get(QUERY_PARAMS_IDS.PROMO);
  const couponCode = query.get(QUERY_PARAMS_IDS.CODE)

  const { email, password, customerName, country } = state;

  const stripe = useStripe();
  const elements = useElements();
  const toast = useCustomToast();
  const dispatch = useDispatch();

  const { coupon, loading, error } = useSelector(getCouponInfoState);
  const [promoInfo, setPromoInfo] = useState(null);
  const [paymentOption, setPaymentOption] = useState(!AMAZON_PAY_BUTTON_ENABLED && COMMON_PAY);
  const [postalCode, setPostalCode] = useState('');
  const [billingFieldsFulfilled, setBillingFieldsFulfilled] = useState({
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false,
  });

  const {
    promoText,
    promoValue,
    lastPeriodMessage,
    lastPeriodValue,
  } = promoInfo || {};

  useEffect(() => setPromoInfo(coupon ? getCouponInfo(coupon, selectedPrice) : null), [coupon, selectedPrice]);

  useEffect(() => {
    if (coupon) toast({ type: 'success', message: "Coupon applied!", duration: 5000} );
  }, [coupon]);

  useEffect(() => {
    if (error) {
      setPromoInfo(null);
      dispatch(clearAllCouponInfo());
      toast({ type: 'error', message: error.message, duration: 5000 });
    };
  }, [error]);

  useEffect(() => setShowSpinner(loading), [loading]);

  useEffect(() => handleRemove(), [selectedPrice])

  const handleChangeCode = useCallback((event) => setCode(event.target.value.trim()), []);

  const handleRemove = useCallback(() => {
    setPromoInfo(null);
    setCode('');
    dispatch(clearAllCouponInfo());
  }, [setCode]);

  const handleChangeCCField = useCallback(a => {
    const { complete, elementType } = a;
    const newFields = { ...billingFieldsFulfilled };
    newFields[elementType] = complete;
    setBillingFieldsFulfilled(newFields);
  }, [billingFieldsFulfilled]);

  const handleApply = useCallback((_,preAppliedCode) => {
    dispatch(checkCoupon({
      coupon: preAppliedCode || promo || code,
      promoCode: promo && code,
      country,
      price: selectedPrice.price,
    }))
    }, [code, promo, country, selectedPrice]);
  
  usePreApplyCode(couponCode, handleApply, code, setCode, [selectedPrice])

  const handleSubmit = useCallback(async () => {
    if (code && !coupon) {
      toast({ type: 'error', message: "Don't forget to apply your promo code!", duration: 5000 });
      return;
    }
    if (countryRequireZIPCode(country) && !isPostalCodeValid(postalCode)) {
      toast({ type: 'error', message: "Please insert a valid postal code", duration: 5000 });
      return;
    }

    setShowSpinner(true);

    // Stripe.js has not loaded yet. Make sure to disable
    // form submission until Stripe.js has loaded.
    if (!stripe || !elements) return;

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardNumberElement);

    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: { name: customerName, address: { postal_code: postalCode } },
    });

    clickHandler(error, paymentMethod);
  }, [code, coupon, postalCode, stripe, elements, clickHandler, country]);

  const setPostalCodeHandler = useCallback(({ target: { value } }) => {
    if (value.length < LENGTH_STANDARDS.MAX_ZIPCODE_LENGTH) setPostalCode(value);
  }, []);

  return (
      <>
        {paymentOption === COMMON_PAY && (
            <VStack spacing="0.5rem" my="1rem">
              <HStack w="full" spacing="1rem">
                <FormField label="Name on card" py={2}>
                  <Input
                    placeholder="John Doe..."
                    name="customerName"
                    value={customerName}
                    onChange={handleChange}
                    border="none"
                    _focus={{ border: 'none' }}
                    fontSize="16px"
                    fontWeight="300"
                    color="text"
                    px={0}
                    pb={0}
                    pt={3}
                  />
                </FormField>
              </HStack>
              <HStack w="full" spacing="0.5rem">
                <FormField label="Card number">
                  <CardNumberElement
                    options={cardElementDecoration}
                  />
                </FormField>
                {countryRequireZIPCode(country) && (
                    <FormField label="ZIP" w="60%" py={2}>
                      <Input
                          value={postalCode}
                          type="number"
                          onKeyDown={onlyNumericValues}
                          placeholder="ZIP"
                          fontSize="16px"
                          fontWeight="300"
                          fontFamily="proxima-soft, sans-serif"
                          onChange={setPostalCodeHandler}
                          border="none"
                          _focus={{ border: 'none' }}
                          color="text"
                          px={0}
                          pb={0}
                          pt={3}
                      />
                    </FormField>
                )}
              </HStack>
              <HStack w="full" spacing="0.5rem">
                <FormField label="Expiration date">
                  <CardExpiryElement
                    onChange={handleChangeCCField}
                    options={cardElementDecoration}
                  />
                </FormField>
                <FormField label="Security code" w="60%">
                  <CardCvcElement
                    onChange={handleChangeCCField}
                    options={cardElementDecoration}
                  />
                </FormField>
              </HStack>
              <HStack w="full" spacing="0.5rem">
                <FormField py={2}>
                  <InputGroup>
                    <Input
                      disabled={!!coupon}
                      placeholder="Enter promo code (optional)"
                      name="input-promo-code"
                      value={code}
                      onChange={handleChangeCode}
                      border="none"
                      _focus={{ border: 'none' }}
                      fontSize="16px"
                      fontWeight="300"
                      color="inherit"
                      p={0}
                    />
                    <InputRightElement zIndex={0}>
                      <Button
                        size='sm'
                        borderWidth={2}
                        minWidth="4rem"
                        mr="2rem"
                        onClick={coupon ? handleRemove : handleApply}
                      >
                        {coupon ? 'Remove' : 'Apply'}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </FormField>
              </HStack>
            </VStack>
        )}
        {paymentOption && (
            <div className="legal">
              <div className="legal-pricing">
                <div>
                  First 7 days
                  <span className="promo-period">{selectedPrice.currencySymbol}0.00</span>
                </div>
                {promoInfo && (
                    <div className="space-between legal-pricing--promo">
                      <div>
                        {promoText}
                      </div>
                      <div>
                        {`${selectedPrice.currencySymbol}${promoValue}`}
                      </div>
                    </div>
                )}
                {!!lastPeriodMessage && !!lastPeriodValue && (
                    <div className="space-between legal-pricing--promo">
                      <div>
                        {lastPeriodMessage}
                      </div>
                      <div>
                        {lastPeriodValue}
                      </div>
                    </div>
                )}
                <div>
                  After promo
                  <span className="paid-period">
                    {`${selectedPrice.currencySymbol}${roundCentsAmount(selectedPrice.amount)}/${getShortPeriodName(selectedPrice.interval)}`}
                  </span>
                </div>
              </div>
              <div className="legal-divider"></div>
              <span className="legal-terms">{getLegalTermsDecoration(selectedPrice)}</span>
            </div>
        )}
        {paymentOption === COMMON_PAY && (
          <Button
            backgroundColor="highlight"
            color="white"
            border="none"
            borderRadius="100px"
            fontFamily="proxima-soft"
            fontWeight={800}
            fontSize="18px"
            cursor="pointer"
            w="100%"
            height="56px"
            margin="16px 0"
            _hover={{background: "hover"}}
            onClick={handleSubmit}
          >
            Agree & Subscribe
          </Button>
        )}
        {paymentOption === AMAZON_PAY && (
            <AmazonPayButton
                registrationDetails={{email, password, plan: { amount: selectedPrice.amount, interval: selectedPrice.interval, hasTrial: true, buyNGive: false }}}
                className="amazon-checkout-btn"
            />
        )}
        {AMAZON_PAY_BUTTON_ENABLED && <PaymentMethodSelection paymentOption={paymentOption} setPaymentOption={setPaymentOption} />}
      </>

  );
};

export default CheckoutForm;
