import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Text, Button, Stack } from '@chakra-ui/react';

import { DEFAULT_APP_COUNTRY_CODE } from '../../../../constants/constants';
import { QUERY_PARAMS_IDS } from '../../../../constants/standards';
import { GIFT_SUCCESS } from '../../../../constants/routes';
import useCustomToast from '../../../../lib/hooks/toast';
import useQuery from '../../../../lib/hooks/useQuery';

import {
  getGiftingRedeemState,
  redeemCode,
  getGiftingRedeemCodeState,
  goToSection,
} from '../../../../stores/gifting';

import RedeemTitle from '../RedeemTitle';
import { ERRORS } from '../../texts';
import UserForm from '../../../UserForm';
import { InputForm } from '../../../ReactHookForm';

import validationSchema from './validationSchema';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const Step3 = () => {
  const dispatch = useDispatch();
  const toast = useCustomToast();
  const query = useQuery();

  const giftCode = query.get(QUERY_PARAMS_IDS.GIFT_CODE);

  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isValid },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues: { code: giftCode || '', country: DEFAULT_APP_COUNTRY_CODE },
  });

  const giftingRedeemUserData = useSelector(getGiftingRedeemState);
  const {
    fulfilled,
    loading,
    error: redeemError,
  } = useSelector(getGiftingRedeemCodeState);

  const { executeRecaptcha } = useGoogleReCaptcha();


  useEffect(() => {
    if (redeemError)
      toast({ type: 'error', message: ERRORS.redeemCode, duration: 5000 });
  }, [redeemError, toast]);

  useEffect(() => {
    if (fulfilled) dispatch(goToSection(GIFT_SUCCESS));
  }, [fulfilled, dispatch]);

  const handleRedeem = async (data) => {
    const token = await executeRecaptcha('submit');
    dispatch(redeemCode({ ...giftingRedeemUserData, ...data, token, }));
  };

  return (
    <Box as="form" textAlign="left" onSubmit={handleSubmit(handleRedeem)}>
      <RedeemTitle>Activate Your Subscription</RedeemTitle>
      <Text fontWeight={800} mb="6">
        Step 3 of 3
      </Text>
      <Stack spacing="0.5rem" mb="12">
        <Controller
          control={control}
          name="country"
          render={({ field: { onChange, value } }) => (
            <UserForm
              handleChange={(e) => onChange(e.target.value)}
              country={value}
              m="0"
            />
          )}
        />
        <InputForm
          id="code"
          label="Enter your code"
          register={register}
          control={control}
          errors={errors}
        />
      </Stack>
      <Button
        type="submit"
        background="highlight"
        color="white"
        mt="8px"
        borderRadius="full"
        fontWeight="bold"
        fontSize="lg"
        w="full"
        size="lg"
        _hover={{ background: 'hover' }}
        isDisabled={!isValid}
        isLoading={loading}
      >
        Agree & Continue
      </Button>
    </Box>
  );
};

export default Step3;
