import customColors from './colors';
import customSizes from "./sizes";

const customTheme = {
    colors: {
        ...customColors,
    },
    sizes: {
        ...customSizes,
    },
};

export default customTheme;
