import axios from '../lib/axios';
import { serialize } from './serializers/prices';
import {DEFAULT_APP_COUNTRY_CODE} from "../constants/constants";

export const fetchPrices = async country => {
    const {
        data: { prices },
    } = await axios.get(`/prices/${country || DEFAULT_APP_COUNTRY_CODE}`);
    return prices.map(price => serialize(price));
};

export const fetchLifetimePrices = async country => {
    const {
        data: { prices },
    } = await axios.get(`/prices/lifetime/${country || DEFAULT_APP_COUNTRY_CODE}`);
    return prices.map(price => serialize(price));
};

export const fetchFreeTeacherPrices = async country => {
    const {
        data: { prices },
    } = await axios.get(`/prices/free-teacher/${country || DEFAULT_APP_COUNTRY_CODE}`);
    return prices.map(price => serialize(price));
}

export const fetchFreeHomeschoolPrices = async country => {
    const {
        data: { prices },
    } = await axios.get(`/prices/free-homeschool/${country || DEFAULT_APP_COUNTRY_CODE}`);
    return prices.map(price => serialize(price));
}

export const fetchGiftingPrices = async country => {
    const {
        data: { prices },
    } = await axios.get(`/prices/gifting/${country || DEFAULT_APP_COUNTRY_CODE}`);
    return prices.map(price => serialize(price));
}
