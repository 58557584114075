import { useState, useEffect } from 'react';

import axios from 'axios';
import { useHistory } from 'react-router-dom';

const useUserGeoLocation = ({ redirectUrl, redirect, restrictedLocations }) => {
  const [countryCode, setCountryCode] = useState('');
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  // creating function to load ip address from the API
  const getIpData = async () => {
    const source = axios.CancelToken.source();
    const timeout = setTimeout(() => {
      source.cancel('Request timed out');
      console.log('Request timed out');
    }, 3000);

    try {
      const res = await axios.get('https://api.country.is/', {
        cancelToken: source.token,
      });
      clearTimeout(timeout);
      setCountryCode(res.data.country);
      setLoading(false);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.error('Request canceled', error.message);
      } else {
        console.error('Error getting user country', error);
      }
      setCountryCode('US');
      setLoading(false);
    }
  };

  useEffect(() => {
    getIpData();
  }, []);

  if (redirect && restrictedLocations.includes(countryCode)) {
    history.push(redirectUrl);
  }

  return {
    countryCode,
    loading,
  };
};

export default useUserGeoLocation;
