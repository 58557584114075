import lodash from 'lodash';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { GIFT_USERS_DATA } from '../../constants/routes';
import { USER_CREATION_ENDPOINT } from '../../constants/backend-sources';
import { SOURCES } from '../../constants/events';
import { stackSection, unstackSection } from '../../utils/navigationUtils';
import { getRandomBackgroundSlug } from '../../utils/decorators/backgroundDecorator';

import { fetchGiftingPrices } from '../../api/prices';
import { purchaseCodeApiCall } from '../../api/gifting';
import { createSubscriptionApiCall } from '../../api/subscriptions';

const initialState = {
  data: {},
  prices: { prices: null, loading: false, error: null },
  purchaseCode: { codeData: null, loading: false, error: null },
  redeemCode: { fulfilled: null, loading: false, error: null },
  redeemUserData: {},
  currentBackgroundSlug: getRandomBackgroundSlug(),
  navigation: [GIFT_USERS_DATA],
};

const getGiftingPrices = createAsyncThunk(
  'gifting/getGiftingPrices',
  async (country, thunkAPI) => {
    try {
      const data = await fetchGiftingPrices(country);

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const purchaseCode = createAsyncThunk(
  'gifting/purchaseCode',
  async ({ paymentInfo, giftInfo }, thunkAPI) => {
    try {
      const { data } = await purchaseCodeApiCall({
        paymentInfo,
        giftInfo: {
          first_name: giftInfo.firstName,
          last_name: giftInfo.lastName,
          giftee_first_name: giftInfo.gifteeFirstName,
          giftee_last_name: giftInfo.gifteeLastName,
          gifter_email: giftInfo.gifterEmail,
          giftee_email: giftInfo.gifteeEmail,
          plan: giftInfo.plan,
          gift_message: giftInfo.giftMessage,
        },
      });

      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const redeemCode = createAsyncThunk(
  'gifting/redeemCode',
  async (payload, thunkAPI) => {
    try {
      await createSubscriptionApiCall({
        payload: {
          email: payload.email,
          password: payload.password,
          password_confirmation: payload.confirmPassword,
          join_newsletter: payload.newsLetter,
          country_code: payload.country,
          source: SOURCES.GIFT_FLOW.WEB,
          coupon: payload.code,
          token: payload.token,
        },
        userCreationEndpoint: USER_CREATION_ENDPOINT.REDEEM,
      });
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const giftingSlice = createSlice({
  name: 'gifting',
  initialState,
  reducers: {
    setGiftingData(state, { payload }) {
      state.data = payload;
    },
    setGiftingRedeemData(state, { payload }) {
      state.redeemUserData = payload;
    },
    clearGiftingState() {
      return initialState;
    },
    goToSection(state, { payload }) {
      state.currentBackgroundSlug = getRandomBackgroundSlug(
        state.currentBackgroundSlug
      );
      state.navigation = stackSection(state.navigation, payload);
    },
    replaceSection(state, { payload }) {
      state.currentBackgroundSlug = getRandomBackgroundSlug(
        state.currentBackgroundSlug
      );
      state.navigation = stackSection(
        unstackSection(state.navigation),
        payload
      );
    },
    goToPreviousSection(state) {
      state.currentBackgroundSlug = getRandomBackgroundSlug(
        state.currentBackgroundSlug
      );
      state.navigation = unstackSection(state.navigation);
    },
    clearNavigationStack(state) {
      state.navigation = initialState.navigation;
    },
    shuffleBackgroundImage(state) {
      state.currentBackgroundSlug = getRandomBackgroundSlug(
        state.currentBackgroundSlug
      );
    },
  },
  extraReducers: ({ addCase }) => {
    addCase(getGiftingPrices.pending, (state) => {
      state.prices.prices = null;
      state.prices.loading = true;
      state.prices.error = false;
    });
    addCase(getGiftingPrices.fulfilled, (state, { payload }) => {
      state.prices.loading = false;
      state.prices.prices = payload.sort((a, b) => a.amount - b.amount);
      state.prices.error = false;
    });
    addCase(getGiftingPrices.rejected, (state) => {
      state.prices.loading = false;
      state.prices.prices = null;
      state.prices.error = true;
    });
    addCase(purchaseCode.pending, (state) => {
      state.purchaseCode.codeData = null;
      state.purchaseCode.loading = true;
      state.purchaseCode.error = false;
    });
    addCase(purchaseCode.fulfilled, (state, { payload }) => {
      state.purchaseCode.loading = false;
      state.purchaseCode.codeData = payload;
      state.purchaseCode.error = false;
    });
    addCase(purchaseCode.rejected, (state) => {
      state.purchaseCode.loading = false;
      state.purchaseCode.codeData = null;
      state.purchaseCode.error = true;
    });
    addCase(redeemCode.pending, (state) => {
      state.redeemCode.fulfilled = null;
      state.redeemCode.loading = true;
      state.redeemCode.error = false;
    });
    addCase(redeemCode.fulfilled, (state) => {
      state.redeemCode.loading = false;
      state.redeemCode.fulfilled = true;
      state.redeemCode.error = false;
    });
    addCase(redeemCode.rejected, (state) => {
      state.redeemCode.loading = false;
      state.redeemCode.fulfilled = null;
      state.redeemCode.error = true;
    });
  },
});

const {
  reducer,
  actions: {
    setGiftingData,
    setGiftingRedeemData,
    goToSection,
    replaceSection,
    goToPreviousSection,
    clearNavigationStack,
    clearGiftingState,
  },
} = giftingSlice;

export const getGiftingState = (state) => state.gifting;
export const getGiftingData = (state) => getGiftingState(state).data;
export const getNavigationState = (state) => getGiftingState(state).navigation;
export const getHasPreviousSection = (state) =>
  getNavigationState(state).length > 1;
export const getCurrentSection = (state) =>
  lodash.last(getNavigationState(state));

export const getGiftingPricesState = (state) => getGiftingState(state).prices;
export const getGiftingPurchaseCodeState = (state) =>
  getGiftingState(state).purchaseCode;
export const getGiftingRedeemState = (state) =>
  getGiftingState(state).redeemUserData;
export const getGiftingRedeemCodeState = (state) =>
  getGiftingState(state).redeemCode;

export const getCurrentBackgroundSlug = (state) =>
  getGiftingState(state).currentBackgroundSlug;

export {
  setGiftingData,
  setGiftingRedeemData,
  goToSection,
  replaceSection,
  goToPreviousSection,
  clearNavigationStack,
  clearGiftingState,
  getGiftingPrices,
  purchaseCode,
  redeemCode,
};

export default reducer;
