import React, {useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {clearAllCouponInfo, getCurrentSection} from "../../stores/registration";
import {CHECKOUT, FORM, REGISTRATION_SUMMARY, TFA} from "../../constants/routes";
import Step1 from "../../components/DefaultFlow/Step1";
import Step2 from "../../components/DefaultFlow/Step2";
import Step3 from "../../components/DefaultFlow/Step3";
import TwoFactorAuthentication from "../../components/TwoFactorAuthentication";
import {DEFAULT_APP_COUNTRY_CODE} from "../../constants/constants";
import {QUERY_PARAMS_IDS} from "../../constants/standards";
import useQuery from "../../lib/hooks/useQuery";
import { LocationContext } from '../../components/RestrictByLocation';

const DefaultFlowContainer = () => {
    const query = useQuery();
    const promo = query.get(QUERY_PARAMS_IDS.PROMO);
    const section = useSelector(getCurrentSection);
    const countryCode = useContext(LocationContext);
    const dispatch = useDispatch();
    const [validated, setValidated] = useState(null);
    const [code, setCode] = useState('');
    const [usedCode, setUsedCode] = useState('');
    const [selectedPrice, setSelectedPrice] = useState(null);
    const [subscription, setSubscription] = useState(null);
    const [state, setState] = useState({
        email: '',
        password: '',
        country: DEFAULT_APP_COUNTRY_CODE,
        defaultFlow: true,
        newsletter: false,
        customerExists: false,
        isSubscription: false,
        customerName: '',
    })

    useEffect(() => {
        return () => dispatch(clearAllCouponInfo());
    }, [])

    useEffect(() => {
        setUsedCode(code || promo);
    }, [code])

    const setStateFunc = (newState) => setState({ ...state, ...newState});

    useEffect(()=>{
        if(countryCode)
        setStateFunc({country: countryCode})
    },[countryCode])

    return (
        <div className="steps-container">
            {section === FORM && (
                <Step1
                    state={state}
                    setState={setStateFunc}
                    validated={validated}
                    setValidated={setValidated}
                />
            )}
            {section === TFA && <TwoFactorAuthentication state={state} />}
            {section === CHECKOUT && (
                <Step2
                    state={state}
                    setState={setStateFunc}
                    setSelectedPrice={setSelectedPrice}
                    selectedPrice={selectedPrice}
                    setSubscription={setSubscription}
                    code={code}
                    setCode={setCode}
                />
            )}
            {section === REGISTRATION_SUMMARY && (
                <Step3
                    state={state}
                    selectedPrice={selectedPrice}
                    subscription={subscription}
                    usedCode={usedCode}
                />
            )}
        </div>
    )
}

export default DefaultFlowContainer;
