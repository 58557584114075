import React from "react";
import './FreeTeacherForm.css';
import {Box, Divider, Stack} from "@chakra-ui/react";
import FormInput from "../Form/input";
import ComboBox from "../Form/ComboBox";
import {
    TEACHERS_FREE_STUDENTS_PER_CLASS,
    TEACHERS_FREE_GRADE_LEVEL,
    HEARD_FROM,
} from "../../constants/constants";
import {LENGTH_STANDARDS} from "../../constants/standards";
import {getCountryComboBoxList} from "../../utils/countryUtils";
import CheckBox from "../Form/CheckBox";
const { MAX_ZIPCODE_LENGTH, MAX_STRING_LENGTH, MAX_NOUN_STRING_LENGTH } = LENGTH_STANDARDS

const FreeTeacherForm = ({ state, handleChange, ...props }) => {
    const {
        country,
        firstName,
        lastName,
        schoolName,
        schoolAddress,
        schoolAddress2,
        city,
        province,
        zip,
        gradeLevel,
        studentsPerClass,
        heardFrom,
        interestedAtHomeVooks,
        emailNewsletter,
        smsNewsletter,
    } = state;
    const setZipCodeHandler = (e) => {
        if (e.target.value.length <= MAX_ZIPCODE_LENGTH) handleChange(e);
    };
    return (
        <>
            <Stack spacing="2" my="20px" {...props}>
                <FormInput
                    value={firstName}
                    label="First Name (Given)"
                    inputName="firstName"
                    handleChange={handleChange}
                    maxLength={MAX_NOUN_STRING_LENGTH}
                    mandatory
                />
                <FormInput
                    value={lastName}
                    label="Last Name (Sur)"
                    inputName="lastName"
                    handleChange={handleChange}
                    maxLength={MAX_NOUN_STRING_LENGTH}
                    mandatory
                />
                <FormInput
                    value={schoolName}
                    label="School Name"
                    inputName="schoolName"
                    handleChange={handleChange}
                    maxLength={MAX_STRING_LENGTH}
                    mandatory
                />
                <FormInput
                    value={schoolAddress}
                    label="School Address (Street)"
                    inputName="schoolAddress"
                    handleChange={handleChange}
                    maxLength={MAX_STRING_LENGTH}
                    mandatory
                />
                <FormInput
                    value={schoolAddress2}
                    label="School Address 2"
                    inputName="schoolAddress2"
                    handleChange={handleChange}
                    maxLength={MAX_STRING_LENGTH}
                />
                <ComboBox
                    value={country}
                    label="Country"
                    inputName="country"
                    values={getCountryComboBoxList()}
                    handleChange={handleChange}
                    mandatory
                />
                <FormInput
                    value={city}
                    label="City"
                    inputName="city"
                    handleChange={handleChange}
                    maxLength={MAX_STRING_LENGTH}
                />
                <FormInput
                    value={province}
                    label="State/Province"
                    inputName="province"
                    handleChange={handleChange}
                    maxLength={MAX_STRING_LENGTH}
                />
                <FormInput
                    value={zip}
                    label="Zip/Postal Code"
                    inputName="zip"
                    handleChange={setZipCodeHandler}
                    type="number"
                />
                <ComboBox
                    value={gradeLevel}
                    label="Grade do you currently teach?"
                    inputName="gradeLevel"
                    values={TEACHERS_FREE_GRADE_LEVEL}
                    handleChange={handleChange}
                    mandatory
                />
                <ComboBox
                    value={studentsPerClass}
                    label="Number of Students in Classroom"
                    inputName="studentsPerClass"
                    values={TEACHERS_FREE_STUDENTS_PER_CLASS}
                    handleChange={handleChange}
                    mandatory
                />
                <ComboBox
                    value={heardFrom}
                    label="How did you hear about Vooks?"
                    inputName="heardFrom"
                    values={HEARD_FROM}
                    handleChange={handleChange}
                    mandatory
                />
            </Stack>
            <Box my="1rem">
                <Divider />
            </Box>
            <Stack spacing="2" my="20px" >
                <CheckBox
                    boxProps={{ textAlign: "left" }}
                    isChecked={interestedAtHomeVooks}
                    text="I’d like to get Vooks for my students at home"
                    textProps={{ fontSize: "0.95rem" }}
                    inputName="interestedAtHomeVooks"
                    onChange={handleChange}
                />
                <CheckBox
                    boxProps={{ textAlign: "left" }}
                    isChecked={emailNewsletter}
                    text="I'd like to receive email updates from Vooks"
                    textProps={{ fontSize: "0.95rem" }}
                    inputName="emailNewsletter"
                    onChange={handleChange}
                />
                <CheckBox
                    boxProps={{ textAlign: "left" }}
                    isChecked={smsNewsletter}
                    text="I'd like to receive SMS updates from Vooks"
                    textProps={{ fontSize: "0.95rem" }}
                    inputName="smsNewsletter"
                    onChange={handleChange}
                />
            </Stack>
        </>
    )
}

export default FreeTeacherForm;
