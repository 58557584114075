import React from 'react';

import MainLayout from '../layouts/components/MainLayout/MainLayout';
import GiftingRedeemContainer from '../containers//GiftingRedeemContainer';

const GiftRedeem = () => {
  return (
    <MainLayout>
      <GiftingRedeemContainer />
    </MainLayout>
  );
};

export default GiftRedeem;
