import axios from 'axios';

const BACKEND_URL = process.env.REACT_APP_WEB_API

const instance = axios.create({ baseURL: BACKEND_URL });

export const setAuthHeader = token => {
    instance.defaults.headers.Authorization = `Bearer ${token}`;
};

export const backendClient = axios.create({ baseURL: BACKEND_URL });

export default instance;
