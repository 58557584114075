import React from "react";
import './NotFound.css';
import {useHistory} from "react-router-dom";
import {LANDING} from "../../constants/routes";

const NotFound = () => {
    const history = useHistory();
    return (
        <>
            <div className="not-found-bg-image"/>
            <div className="not-found-content">
                <h1 className="not-found-main-title">Oops!</h1>
                <h2 className="not-found-text">
                    You are lost, as lost as the little
                    <span className="not-found-text-i">
                {" i "}
            </span>
                    who lost his dot.
                </h2>
                <a type="button" className="not-found-button" onClick={() => history.push(LANDING)}>
                    <svg viewBox="0 0 18 20" focusable="false" role="presentation" aria-hidden="true" className="not-found-button-arrow">
                        <path
                            d="M11.6067 4.04173C12.4503 3.20861 12.4503 1.85786 11.6067 1.02474C10.7632 0.191623 9.39556 0.191623 8.55202 1.02474L0.992024 8.49141C0.148492 9.32453 0.148492 10.6753 0.992024 11.5084L8.55202 18.9751C9.39556 19.8082 10.7632 19.8082 11.6067 18.9751C12.4503 18.1419 12.4503 16.7912 11.6067 15.9581L7.73408 12.1332H15.4794C16.6723 12.1332 17.6394 11.1781 17.6394 9.9999C17.6394 8.82169 16.6723 7.86657 15.4794 7.86657H7.73408L11.6067 4.04173Z"
                            fill="currentColor" fill-rule="evenodd"></path>
                    </svg>
                    Go to main page
                </a>
            </div>
        </>
    )
};
export default NotFound;