import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Divider, Flex, Text } from '@chakra-ui/react';

import {
  getOneTimePaymentDecoration,
  getLifetimeLegalsDecoration,
} from '../../../utils/decorators/checkoutFormDecoration';
import { roundCentsAmount } from '../../../utils/currencyUtils';

import {
  getGiftingData,
  getGiftingPurchaseCodeState,
} from '../../../stores/gifting';

import { InputForm } from '../../ReactHookForm';
import CardField from '../../ReactHookForm/Payments/CardField';

import validationSchema from './paymentValidationSchema';

const Payment = ({ selectedPrice, onSubmit }) => {
  const { firstName, lastName } = useSelector(getGiftingData);
  const { loading } = useSelector(getGiftingPurchaseCodeState);

  const customerName = firstName && lastName ? `${firstName} ${lastName}` : '';

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues: { cardName: customerName },
  });

  const isLifeTime = selectedPrice.metadata.slug === 'lifetime_1';

  const handleOnSubmit = (data) => {
    const { cardName, cardNumber, cardExpiryDate, cardCvc } = data;

    const [expiryMonth, expiryYear] = cardExpiryDate.split('/');

    onSubmit({
      name: cardName,
      number: cardNumber.replace(/\s/g, ''),
      exp_month: expiryMonth,
      exp_year: expiryYear,
      cvc: cardCvc,
    });
  };
  return (
    <Box
      mt="6"
      fontFamily="proxima-soft, sans-serif"
      as="form"
      onSubmit={handleSubmit(handleOnSubmit)}
    >
      <Box>
        <InputForm
          id="cardName"
          label="Name on Card"
          placeholder="John Doe..."
          register={register}
          control={control}
          errors={errors}
        />
        <CardField
          id="cardNumber"
          type="cardNumber"
          label="Card Number"
          control={control}
          errors={errors}
        />
        <Flex>
          <CardField
            id="cardExpiryDate"
            type="cardExpiryDate"
            label="Expiration Date"
            control={control}
            errors={errors}
            mr="2"
          />
          <CardField
            id="cardCvc"
            type="cardCvc"
            label="Security Code"
            control={control}
            errors={errors}
          />
        </Flex>
      </Box>
      <Box
        border="1px solid"
        borderColor="border"
        borderRadius="lg"
        px="6"
        py="4"
        mt="10"
      >
        <Flex justifyContent="space-between" mb="4">
          <Text>Total</Text>
          <Text>
            {`${selectedPrice.currencySymbol}${roundCentsAmount(
              selectedPrice.amount,
            )}`}
          </Text>
        </Flex>
        <Divider />
        <Text color="placeholder" fontSize="sm" mt="4">
          {isLifeTime
            ? getLifetimeLegalsDecoration(selectedPrice)
            : getOneTimePaymentDecoration(selectedPrice)}
        </Text>
      </Box>
      <Button
        type="submit"
        background="highlight"
        color="white"
        mt="8"
        borderRadius="full"
        fontWeight="bold"
        fontSize="lg"
        w="full"
        size="lg"
        _hover={{ background: 'hover' }}
        isDisabled={!isValid}
        isLoading={loading}
      >
        Continue
      </Button>
    </Box>
  );
};

Payment.propTypes = {
  selectedPrice: PropTypes.shape({
    metadata: PropTypes.shape({ slug: PropTypes.string.isRequired }),
    currencySymbol: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default Payment;
