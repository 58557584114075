import {
    Button,
    Input,
    InputGroup,
    InputLeftAddon,
    InputRightAddon,
    Stack,
    Text,
} from "@chakra-ui/react";
import { LockIcon } from '@chakra-ui/icons';
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {replaceSection} from "../../stores/registration";
import {
    clearRequestRestrictionsState,
    clearRequestState,
    getRequestRestrictionsState,
    getRequestState,
    getVerificationState,
    requestTFAToken,
    verifyTFAToken
} from "../../stores/TFA";
import useCustomToast from "../../lib/hooks/toast";
import {
    TFA_CODE_REQUEST_FAILED,
    TFA_CODE_REQUEST_SENT,
    TFA_CODE_VERIFICATION_FAILED
} from "../../constants/messages";
import Loading from "../Loading/Loading";
import {isSameEmail, onlyNumericValues} from "../../utils/validationUtils";
import {CHECKOUT} from "../../constants/routes";
import {MAX_TFA_CODE_LENGTH} from "../../constants/constants";
import {formatSecondsToMin, getRemainingSecondsUntilDate} from "../../utils/dateUtils";

const TwoFactorAuthentication = ({state, replaceSectionCallback}) => {

    const { email } = state;
    const [code, setCode] = useState('');
    const isCodeFilled = code.length >= MAX_TFA_CODE_LENGTH;
    const dispatch = useDispatch();
    const toast = useCustomToast();
    const requestRestrictions = useSelector(getRequestRestrictionsState);
    const { fulfilled: requestFulfilled, loading: requestLoading, error: requestError } = useSelector(getRequestState);
    const { fulfilled: verificationFulfilled, loading: verificationLoading, error: verificationError } = useSelector(getVerificationState);
    const nextTryDate = requestRestrictions?.[email]?.nextTryDate;
    const [remainingSeconds, setRemainingSeconds] = useState(getRemainingSecondsUntilDate(nextTryDate));
    const verified = verificationFulfilled && isSameEmail({ currentEmail: email, savedRequest: verificationFulfilled});

    const updateTime = () => setRemainingSeconds(getRemainingSecondsUntilDate(nextTryDate));

    useEffect(() => {
        setRemainingSeconds(getRemainingSecondsUntilDate(nextTryDate));
        const timer = setInterval(updateTime, 1000);
        return () => clearTimeout(timer);
    }, [nextTryDate]);

    useEffect(() => {
        if (requestFulfilled) toast({type: 'warning', message: TFA_CODE_REQUEST_SENT, duration: 7000});
    },[requestFulfilled]);

    useEffect(() => {
        if (requestError) toast({type: 'error', message: requestError.message ?? TFA_CODE_REQUEST_FAILED, duration: 5000});
    }, [requestError])

    useEffect(() => {
        if (verified) {
            dispatch(clearRequestState());
            dispatch(clearRequestRestrictionsState(email));
            
            if(replaceSectionCallback) dispatch(replaceSectionCallback())
            else { dispatch(replaceSection(CHECKOUT));}
        };
    }, [verificationFulfilled]);

    useEffect(() => {
        if (verificationError) toast({type: 'error', message: TFA_CODE_VERIFICATION_FAILED, duration: 5000});
    }, [verificationError])

    useEffect(() => {
        if(!verified) requestCode();
    }, [verified]);

    useEffect(() => () => dispatch(clearRequestState()), []);

    useEffect(() => {
        if (isCodeFilled) dispatch(verifyTFAToken({ email, code }));
    }, [code]);

    const handleChangeCode = ({ target: { value }}) => {
        if (value.toString().length <= MAX_TFA_CODE_LENGTH) setCode(value);
    };

    const requestCode = () => {
        if (!remainingSeconds) dispatch(requestTFAToken({email}));
    };

    return (
        <div>
            {verificationLoading && <Loading />}
            <div className="hero">
                <Text fontFamily="proxima-soft" fontSize="32px" fontWeight="800" margin="8px" lineHeight="110%">
                    Please Verify Your Email
                </Text>
            </div>
            <div className="step">
                <Text fontFamily="proxima-soft" fontSize="18px" fontWeight="800" mb={5}>Step 2 of 3</Text>
                <Stack spacing="18px" textAlign="center">
                    <InputGroup h="56px">
                        <InputLeftAddon
                            h="100%"
                            w="5rem"
                            justifyContent="center"
                            pointerEvents="none"
                            fontSize="19px"
                        >
                            <LockIcon />
                        </InputLeftAddon>
                        <Input
                            borderRadius={0}
                            letterSpacing={code ? "6px" : "inherit"}
                            h="100%"
                            onChange={handleChangeCode}
                            onKeyDown={onlyNumericValues}
                            type="number"
                            value={code}
                            textAlign="center"
                            placeholder="Enter your code"
                        />
                        <InputRightAddon
                            h="100%"
                            p={0}
                            width="5rem"
                        >
                            <Button
                                h="100%"
                                w="100%"
                                backgroundColor={!remainingSeconds && 'cyanCustom'}
                                isLoading={requestLoading}
                                onClick={requestCode}
                                disabled={remainingSeconds}
                                color={!remainingSeconds && 'white'}
                                fontSize="16px"
                                borderLeftRadius={0}
                                fontWeight={800}
                                _hover={{backgroundColor: !remainingSeconds && 'title' }}
                            >
                                {remainingSeconds ? formatSecondsToMin(remainingSeconds) : 'Resend'}
                            </Button>
                        </InputRightAddon>
                    </InputGroup>
                    <Text fontFamily="proxima-soft" fontSize="16px" fontWeight="400">
                        Please enter the code sent to your email:
                    </Text>
                    <Text fontFamily="proxima-soft" fontSize="16px" fontWeight="800">
                        {email}
                    </Text>
                </Stack>
            </div>
        </div>
    );
}

export default TwoFactorAuthentication;
