import { countries } from 'countries-list';

const COUNTRY_CHAR_LIMIT = 32; // Default value / set it according to the form width

export const getCountryList = () => {
  const { AQ, ...rest } = countries;
  return rest;
};

export const getCountryComboBoxList = (
  countryCharLimit = COUNTRY_CHAR_LIMIT
) => {
  const countryList = getCountryList();
  const countries = [];
  Object.entries(countryList)
    .sort(([_, { name: a }], [__, { name: b }]) => a.localeCompare(b))
    .forEach(([code, { name, emoji }]) => {
      countries.push({
        key: code,
        value: code,
        label: `${emoji} ${truncateName(name, countryCharLimit)}`,
      });
    });
  return countries;
};

export const truncateName = (name, charLimit) =>
  name.length > charLimit ? `${name.substr(0, charLimit)}...` : name;
