import { head as first, shuffle } from 'lodash';
const DYNAMIC_BACKGROUNDS_DISABLED = process.env.REACT_APP_DYNAMIC_BACKGROUNDS_DISABLED === 'true';
export const BACKGROUND_SLUGS = ['bird', ...(DYNAMIC_BACKGROUNDS_DISABLED ? [] : ['norb', 'yeti', 'unicorn'])];
const backgroundPath = '/static/assets/backgrounds';

export const getBackgroundPath = (slug, ext = 'png') => `${backgroundPath}/${slug}.${ext}`;

export const getRandomBackgroundSlug = currentBackgroundSlug =>
    first(DYNAMIC_BACKGROUNDS_DISABLED ?
        BACKGROUND_SLUGS :
        shuffle(BACKGROUND_SLUGS.filter(slug => slug !== currentBackgroundSlug)))
