const sizes = {
  'ninety-five': '95%',
  half: '50%',
  third: '33.333%',
  quarter: '25%',
  fifteenth: '15%',
  tenth: '10%',
  nothing: '0%',
};

export default sizes;
