export const formatCardNumber = (value) =>
  value
    .replace(/(.{4})/g, '$1 ')
    .trim()
    .slice(0, 19);

export const formatCardExpiryDate = (value) => {
  const month = value.slice(0, 2);
  const year = value.slice(2, 4);

  if (month && year) return `${month}/${year}`;

  return value;
};

export const formatCardCvc = (value) => value.slice(0, 4);
