import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Stack, Text } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import dayjs from 'dayjs';

import { fetchCurrentSubscription } from '../../api/subscriptions';

import useCustomToast from '../../lib/hooks/toast';
import { QUERY_PARAMS_IDS } from '../../constants/standards';
import { getRedeemDecoration } from '../../utils/decorators/checkoutFormDecoration';
import usePreApplyCode from '../../lib/hooks/usePreApplyCode';
import useQuery from '../../lib/hooks/useQuery';
import {
  getCouponDurationCopy,
  getExpirationDate,
} from '../../utils/couponsUtils';

import {
  checkRedeemCoupon,
  clearAllCouponInfo,
  getCouponInfoState,
} from '../../stores/registration';

import PricesList from './PricesList';
import colors from '../../layouts/components/ThemeProvider/colors';

const CheckoutForm = ({
  state,
  handleSubmit,
  setShowSpinner,
  coupons,
  setCoupons,
}) => {
  const query = useQuery();
  const toast = useCustomToast();
  const dispatch = useDispatch();

  const couponCode = query.get(QUERY_PARAMS_IDS.CODE);
  const inputRef = useRef();
  const { country } = state;

  const [couponInput, setCouponInput] = useState('');
  const [totalDurationDays, setTotalDurationDays] = useState(0);
  const [subCurrentExpirationDate, setSubCurrentExpirationDate] = useState(
    dayjs().valueOf(),
  );

  const userToken = localStorage.getItem('userToken');

  const { coupon, loading, error } = useSelector(getCouponInfoState);

  const normalizeBaseExpirationDate = (date) => {
    const dateMS = date * 1000;
    const isAfter = dayjs().isAfter(dayjs(dateMS));
    if (!isAfter) setSubCurrentExpirationDate(dateMS);
  };

  const fetchSubscriptionData = useCallback(async () => {
    const { cancel_at: cancelAt, current_period_end: currentPeriodEnd, status } = await fetchCurrentSubscription(userToken);
    if (status !== 'trialing' && !cancelAt) {
      normalizeBaseExpirationDate(currentPeriodEnd)
    }
    if (cancelAt) {
      normalizeBaseExpirationDate(cancelAt);
    }
  }, [userToken]);

  useEffect(() => {
    if (userToken) {
      fetchSubscriptionData();
    }
  }, [fetchSubscriptionData, userToken]);

  useEffect(() => {
    if (coupons) {
      const total = coupons.reduce((sum, coupon) => {
        const { duration_days: days } = coupon;
        return sum + Number(days);
      }, 0);

      if (total > 0) {
        setTotalDurationDays(Math.floor(total));
      }
    }
  }, [coupons]);

  useEffect(() => {
    if (error) {
      dispatch(clearAllCouponInfo());
      toast({ type: 'error', message: error.message, duration: 5000 });
    }
  }, [error]);

  useEffect(() => setShowSpinner(loading), [loading]);

  useEffect(() => {
    if (coupon) {
      // If coupon id is in coupons array, throw toast error
      if (coupons.find((c) => c.id === coupon.id)) {
        toast({
          type: 'error',
          message: 'You already applied this code',
          duration: 5000,
        });
        handleRemoveCurrentCoupon();
        return;
      }
      setCoupons((prev) => [...prev, coupon]);

      handleRemoveCurrentCoupon();
    }
  }, [coupon]);

  const handleChangeCode = (event) => setCouponInput(event.target.value.trim());

  const handleRemoveCurrentCoupon = () => {
    setCouponInput('');
    dispatch(clearAllCouponInfo());
    inputRef.current.focus();
  };

  const handleRemoveSingleCoupon = (couponId) => {
    setCoupons((prev) => prev.filter((coupon) => coupon.id !== couponId));
    if (couponId === couponInput) {
      handleRemoveCurrentCoupon();
    }
  };

  const handleApply = (e) => {
    if (e) e.preventDefault();

    dispatch(checkRedeemCoupon({ coupon: couponInput || couponCode, country }));
  };

  usePreApplyCode(couponCode, handleApply, couponInput, setCouponInput);

  return (
    <>
      <Stack flexDir="column">
        {coupons.map(
          ({ id: couponId, name: couponName, duration_days: durationDays }) => {
            // Suggestion: flag months_access insetad of days_access
            const durationText = getCouponDurationCopy(durationDays);

            return (
              <Stack
                key={couponId}
                flexDirection="row"
                justify="space-between"
                alignItems="center"
                justifyItems="center"
                px="6px"
                cursor="default"
              >
                <Text>{couponName}</Text>
                <Box
                  flexDir="row"
                  display="flex"
                  style={{ gap: '8px', marginTop: 0 }}
                  alignItems="center"
                >
                  <Text>{durationText}</Text>
                  <CloseIcon
                    color={colors.delete}
                    cursor="pointer"
                    height="12px"
                    width="12px"
                    onClick={() => handleRemoveSingleCoupon(couponId)}
                    data-testid="remove-coupon-btn"
                  />
                </Box>
              </Stack>
            );
          },
        )}
      </Stack>
      <form className="input-with-label" onSubmit={handleApply}>
        <input
          id="input-promo-code"
          type="text"
          value={couponInput}
          onChange={handleChangeCode}
          name="input-promo-code"
          className={`input--text input--nameoncard ${
            coupon ? 'input--promo-applied' : ''
          }`}
          ref={inputRef}
          data-testid="coupon-input"
        />
        <label
          htmlFor="input-promo-code"
          className={`input--text-nameoncard-label ${
            couponInput ? 'input--text-nameoncard-label-active' : ''
          }`}
        >
          Enter Code
        </label>
        <button
          id="apply"
          type="submit"
          onClick={handleApply}
          className={`span-promo-code ${
            couponInput
              ? ' span-promo-code--apply'
              : 'span-promo-code--disabled'
          }`}
          disabled={!couponInput}
          data-testid="coupon-input-btn"
        >
          Apply
        </button>
        {coupons?.length > 0 && (
          <Text fontFamily="proxima-soft" fontSize="0.85rem">
            You can apply another code
          </Text>
        )}
      </form>
      {coupons.length > 0 && (
        <div data-testid="price-list">
          <PricesList mt="2rem" durationDays={totalDurationDays} />
          <div className="legal">
            <div className="legal-pricing">
              <div>
                Expiration
                <span className="paid-period">
                  {getExpirationDate(
                    totalDurationDays,
                    subCurrentExpirationDate,
                  )}
                </span>
              </div>
            </div>
            <div className="legal-divider"></div>
            <span className="legal-terms">{getRedeemDecoration()}</span>
          </div>
          <button
            onClick={() => handleSubmit(couponInput)}
            className="input--button"
            data-testid="agree-btn"
          >
            Agree & {userToken ? 'Apply' : 'Create'}
          </button>
        </div>
      )}
    </>
  );
};

export default CheckoutForm;
