import React, {useEffect, useState} from "react";
import "./FreeTeacher.css";
import SwipeButton from "../SwipeButton/SwipeButton";
import {useDispatch} from "react-redux";
import {goToSection} from "../../stores/registration";
import {TFA} from "../../constants/routes";
import {HUMAN_VERIFY} from "../../constants/messages";
import {EVENTS, SOURCES} from "../../constants/events";
import {klaviyoEvent} from "../../utils/events";
import {validateBasicForm} from "../../utils/validationUtils";
import Loading from "../Loading/Loading";
import Terms from "../Terms/Terms";
import NewsletterCheckbox from "../NewsletterCheckbox";
import {Button, Stack, Text} from "@chakra-ui/react";
import useCustomToast from "../../lib/hooks/toast";
import useUTMs from "../../lib/hooks/useUTMs";
import FormInput from "../Form/input";
import {LENGTH_STANDARDS} from "../../constants/standards";
const { MAX_STRING_LENGTH } = LENGTH_STANDARDS

const FreeTeacher = ({ state, setState, validated, setValidated }) => {

    const { email, password, newsletter } = state;
    const UTMs = useUTMs();

    const dispatch = useDispatch();
    const toast = useCustomToast();
    const [showSpinner, setShowSpinner] = useState(false);
    const notValidated = validated !== null && !validated;

    const handleChange = (event) => {
        const target = event.target;
        const value = target.name === "newsletter" ? target.checked : target.value;
        const name = target.name;
        setState({ [name]: value });
    }

    const onClick = async () => {
        setShowSpinner(true);
        const validationAlertMessage = await validateBasicForm({ state, email, password });
        if (validationAlertMessage) {
            toast({type: 'error', message: validationAlertMessage, duration: 5000});
            setShowSpinner(false);
        } else {
            klaviyoEvent(email, password, EVENTS.BEFORE_VALIDATION, {
                email: "" + email,
                newsletter: "" + newsletter,
                source: SOURCES.FREE_TEACHER_FLOW.BEFORE_VALIDATION,
                ...UTMs
            });
            dispatch(goToSection(TFA));
        }
    }

    useEffect(() => {
        if (notValidated) toast({type: 'warning', message: HUMAN_VERIFY, duration: 5000});
    }, [validated]);

    return (
        <div>
            <div className="hero">
                <Text
                    fontFamily="proxima-soft"
                    fontSize="32px"
                    fontWeight="800"
                    margin="8px"
                    lineHeight="110%"
                >
                    Create Free Account
                </Text>
                <Text>For Teacher In-Class Use</Text>
                <Text>Always Free</Text>
            </div>
            <div className="step">
                {showSpinner && <Loading />}
                <Text fontSize="1.3rem" fontWeight={800} mb="0.5rem">
                    Step 1 of 3
                </Text>
                <Stack spacing="1.5rem" mb="1.5rem">
                    <Stack spacing="0.5rem">
                        <FormInput
                            value={email}
                            label="Email Address"
                            inputName="email"
                            handleChange={handleChange}
                            maxLength={MAX_STRING_LENGTH}
                            mandatory
                        />
                        <FormInput
                            value={password}
                            label="Password"
                            inputName="password"
                            type="password"
                            handleChange={handleChange}
                            maxLength={MAX_STRING_LENGTH}
                            mandatory
                        />
                    </Stack>
                    <NewsletterCheckbox newsletter={newsletter} handleChange={handleChange} my={5} />
                    <Terms className="terms" />
                </Stack>
                {notValidated ?
                    <SwipeButton className="input--button" color="#2452f6" onSuccess={() => setValidated(true)}/> :
                    <Button
                        onClick={onClick}
                        background="highlight"
                        color="white"
                        mt="8px"
                        borderRadius="100px"
                        fontFamily="proxima-soft"
                        fontWeight={800}
                        fontSize="18px"
                        h="56px"
                        w="100%"
                        border="none"
                        outline="none"
                        cursor="pointer"
                        _hover={{background: "hover"}}
                    >
                        Agree &amp; Continue
                    </Button>}
            </div>
        </div>
    );
}

export default FreeTeacher;
