import React from 'react';
import PropTypes from 'prop-types';
import { Heading } from '@chakra-ui/react';

const RedeemTitle = ({ children }) => (
  <Heading
    fontFamily="proxima-soft"
    margin="8px"
    mx="auto"
    mb="6"
    textAlign="center"
    fontSize="3xl"
  >
    {children}
  </Heading>
);

RedeemTitle.propTypes = { children: PropTypes.string.isRequired };

export default RedeemTitle;
