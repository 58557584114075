const serialize = ({
  amount,
  currency,
  interval,
  interval_count,
  metadata,
  price,
  price_symbol: currencySymbol,
  type,
}) => ({
  amount,
  currency,
  interval,
  interval_count,
  metadata,
  price,
  currencySymbol,
  type,
});

export { serialize };
