import { capitalize } from 'lodash';
import { roundCentsAmount } from './currencyUtils';
import {
  FULL_PERIOD_NAMES,
  SHORT_PERIOD_NAMES,
} from '../constants/planPeriods';
import dayjs from 'dayjs';

export const getCouponInfo = (coupon, plan) => {
  const couponInfo = {};
  const {
    percent_off: percentOff,
    amount_off: amountOff,
    duration_in_months: durationInMonths,
  } = coupon;
  let { amount: planAmount, interval, currencySymbol } = plan;
  if (percentOff) {
    couponInfo.promoText = `${percentOff}% Off, ${durationInMonths} months`;
    couponInfo.promoValue = roundCentsAmount(
      planAmount - (planAmount * percentOff) / 100,
    );
  } else if (amountOff) {
    let periodsApplied = amountOff / planAmount;
    let promoPeriods = Math.floor(periodsApplied) + 1;
    if (periodsApplied > 1) {
      periodsApplied = Math.floor(periodsApplied);
      promoPeriods = periodsApplied;
      couponInfo.lastPeriodMessage = `${capitalize(interval)} ${periodsApplied + 1
        }`;
      couponInfo.lastPeriodValue = `${currencySymbol}${(planAmount - (amountOff - periodsApplied * planAmount)) / 100
        }`;
    }
    couponInfo.promoValue =
      amountOff < planAmount ? roundCentsAmount(planAmount - amountOff) : 0;
    couponInfo.promoText = `${currencySymbol}${roundCentsAmount(
      amountOff,
    )} Off, next ${promoPeriods} ${interval}s`;
  }
  return couponInfo;
};

export const daysToYearsMonthsAndDays = (days) => {
  if (!days) {
    return {
      years: 0,
      months: 0,
      days: 0,
    };
  }

  const years = Math.floor(days / 365);
  const remainingDays = days % 365;
  const months = Math.floor(remainingDays / 30);
  const daysLeft = remainingDays % 30;

  return {
    years: years,
    months: months,
    days: daysLeft,
  };
};

export const getExpirationDate = (
  daysUntilExpiration,
  subCurrentExpirationDate,
) => {
  return dayjs(subCurrentExpirationDate || dayjs().valueOf())
  .add(daysUntilExpiration || 0, 'day')
  .format('YYYY-MM-DD');
};

export const getCouponDurationCopy = (days, showAccess) => {
  if (!days) return null;
  const { years, months, days: remainingDays } = daysToYearsMonthsAndDays(days);

  const yearString = years !== 1 ? 'Years' : 'Year';
  const monthString = months !== 1 ? 'Months' : 'Month';
  const access = showAccess ? 'Access' : '';

  if (+years > 0) {
    return `${years} ${yearString}${months ? ` & ${months} ${monthString}` : ``
      } ${access}`;
  }

  if (+months > 0) {
    return `${months} ${monthString} ${access}`;
  }

  if (+remainingDays > 0) {
    const daysString = +remainingDays !== 1 ? 'Days' : 'Day';
    return `${remainingDays} ${daysString} ${access}`;
  }

  return null;
};

export const convertMonthsToDays = (months) => {
  const now = dayjs();
  const future = now.add(months, 'month');
  const days = future.diff(now, 'day');
  return days;
};

// Transforms e.g "Year" on "yr"
export const getShortPeriodName = (interval) => {
  switch (interval) {
    case FULL_PERIOD_NAMES.YEAR:
      return SHORT_PERIOD_NAMES.YEAR;
    case FULL_PERIOD_NAMES.MONTH:
      return SHORT_PERIOD_NAMES.MONTH;
    default:
      return '';
  }
};
