import axios from "axios";

const BASE_API = process.env.REACT_APP_WEB_API;

export const getSign = async (payload) => {
    const { data: { signature } } = await axios.post(`${BASE_API}/amazon/sign`, payload);
    return signature;
}

export const getPayURL = async ({ checkoutSessionId, ...payload }) => {
    const { data: { payURL } } = await axios.post(`${BASE_API}/amazon/checkout/${checkoutSessionId}/pay-url`, payload);
    return payURL;
}