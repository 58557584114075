import MainLayout from "../layouts/components/MainLayout/MainLayout";
import FreeTeacherContainer from "../containers/FreeTeacherContainer";
import React from "react";
import RestrictByLocation from "../components/RestrictByLocation";
import { NOT_FOUND } from "../constants/routes";
import { MATH_MASTERS_NOT_ALLOWED_COUNTRIES } from "../constants/constants";

const FreeTeacher = () => {
    return (
        <MainLayout>
            <RestrictByLocation redirectUrl={NOT_FOUND} restrictedLocations={MATH_MASTERS_NOT_ALLOWED_COUNTRIES}>
                <FreeTeacherContainer/>
            </RestrictByLocation>
        </MainLayout>
    );
};

export default FreeTeacher;
