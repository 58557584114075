import React from "react";
import CheckBox from "../Form/CheckBox";


const NewsletterCheckbox = ({ handleChange, newsletter, ...props }) =>
    <CheckBox
        onChange={handleChange}
        isChecked={newsletter}
        text="I'd like to receive updates from Vooks"
        inputName="newsletter"
        {...props}
    />

export default NewsletterCheckbox;
