import axios from 'axios';
import { backendClient } from '../lib/axios';
import { API_AUTH } from '../constants/backend-sources';

export const login = async ({ email, password }) =>
  axios.post(process.env.REACT_APP_WEB_API + '/auth/login', {
    email,
    password,
  });

export const verifyEmail = async (email) =>
  backendClient.get(`${API_AUTH}/verify/${email}`);
