import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button, Stack, Text } from '@chakra-ui/react';

import { CHECKOUT, TFA } from '../../constants/routes';
import { HUMAN_VERIFY } from '../../constants/messages';
import { EVENTS, SOURCES } from '../../constants/events';
import { klaviyoEvent } from '../../utils/events';
import { LENGTH_STANDARDS } from '../../constants/standards';
import useCustomToast from '../../lib/hooks/toast';
import useUTMs from '../../lib/hooks/useUTMs';
import {
  validateBasicForm,
  validateLoginPasswordChars,
  validatePasswordChars,
} from '../../utils/validationUtils';

import { goToSection } from '../../stores/registration';

import Loading from '../Loading/Loading';
import Terms from '../Terms/Terms';
import NewsletterCheckbox from '../NewsletterCheckbox';
import FormInput from '../Form/input';
import GiftingCta from '../Gifting/GiftingCta';
import SwipeButton from '../SwipeButton/SwipeButton';
import PasswordValidation from './PasswordValidation';

import './Redeem.css';

const { MAX_STRING_LENGTH } = LENGTH_STANDARDS;

const UserDataForm = ({
  state,
  setState,
  validated,
  setValidated,
  isMathMaster = false,
}) => {
  const UTMs = useUTMs();
  const dispatch = useDispatch();
  const toast = useCustomToast();

  const { REDEEM_FLOW, MATH_MASTERS_FLOW } = SOURCES;

  const [showSpinner, setShowSpinner] = useState(false);

  const { email, password, newsletter } = state;
  const notValidated = validated !== null && !validated;

  const isPasswordValid = validatePasswordChars(password);
  const isLoginPasswordValid = validateLoginPasswordChars(password);

  const handleChange = (event) => {
    const target = event.target;
    const value = target.name === 'newsletter' ? target.checked : target.value;
    const name = target.name;
    setState({ [name]: value });
  };

  const invalidLoginAlert = () => {
    toast({
      type: 'error',
      message: 'Invalid login credentials or account does not exist.',
      duration: 5000,
    });
    setShowSpinner(false);
  };

  const onClick = async (validateUser = true) => {
    setShowSpinner(true);

    const validationAlertMessage = await validateBasicForm({
      state,
      email,
      password,
      validateUser,
    });

    if (validationAlertMessage) {
      toast({ type: 'error', message: validationAlertMessage, duration: 5000 });
      setShowSpinner(false);
    } else {
      setState({ isSubscription: true, customerExists: false });
      const token = await klaviyoEvent(
        email,
        password,
        EVENTS.BEFORE_VALIDATION,
        {
          email: '' + email,
          newsletter: '' + newsletter,
          source: isMathMaster
            ? MATH_MASTERS_FLOW.BEFORE_VALIDATION
            : REDEEM_FLOW.BEFORE_VALIDATION,
          ...UTMs,
        },
      );

      if ((!token && validateUser) || (token && !validateUser)) {
        dispatch(goToSection(isMathMaster ? CHECKOUT : TFA));
      } else {
        invalidLoginAlert();
      }
    }
  };

  useEffect(() => {
    if (notValidated)
      toast({ type: 'warning', message: HUMAN_VERIFY, duration: 5000 });
  }, [notValidated]);

  return (
    <Box>
      <Box className="hero">
        <Text
          fontFamily="proxima-soft"
          fontSize="32px"
          fontWeight="800"
          margin="8px"
          lineHeight="110%"
        >
          Create Account
        </Text>
        <Text as="span">Redeem your gift code</Text>
      </Box>
      <Box className="step">
        {showSpinner && <Loading />}
        <Text fontSize="1.3rem" fontWeight={800} mb="0.5rem">
          Step 1 of {`${isMathMaster ? 2 : 3}`}
        </Text>
        <Stack spacing="1.5rem" mb="1.5rem">
          <Stack spacing="0.5rem">
            <FormInput
              value={email}
              label="Email Address"
              inputName="email"
              handleChange={handleChange}
              maxLength={MAX_STRING_LENGTH}
              mandatory
              data-testid="email-input"
            />
            <FormInput
              value={password}
              label="Password"
              inputName="password"
              type="password"
              handleChange={handleChange}
              maxLength={MAX_STRING_LENGTH}
              mandatory
              data-testid="password-input"
            />
            <PasswordValidation password={password} />
          </Stack>
          <NewsletterCheckbox
            newsletter={newsletter}
            handleChange={handleChange}
            my={5}
          />
          <Terms className="terms" />
        </Stack>
        <GiftingCta />
        {notValidated ? (
          <SwipeButton
            className="input--button"
            color="#2452f6"
            onSuccess={() => setValidated(true)}
          />
        ) : (
          <>
            <Button
              onClick={onClick}
              background="highlight"
              color="white"
              mt="8px"
              borderRadius="100px"
              fontFamily="proxima-soft"
              fontWeight={800}
              fontSize="18px"
              h="56px"
              w="100%"
              border="none"
              outline="none"
              cursor="pointer"
              _hover={{ background: 'hover' }}
              isDisabled={!isPasswordValid}
            >
              Agree &amp; Continue
            </Button>
            <Button
              onClick={() => onClick(false)}
              background="highlight"
              color="white"
              mt="8px"
              borderRadius="100px"
              fontFamily="proxima-soft"
              fontWeight={800}
              fontSize="18px"
              h="56px"
              w="100%"
              border="none"
              outline="none"
              cursor="pointer"
              _hover={{ background: 'hover' }}
              isDisabled={!isLoginPasswordValid}
            >
              Login
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
};

export default UserDataForm;
