import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Text,
  Divider,
  Button,
  Heading,
  Image,
  Flex,
} from '@chakra-ui/react';

import { roundCentsAmount } from '../../utils/currencyUtils';

import {
  getGiftingData,
  getGiftingPurchaseCodeState,
  clearGiftingState,
} from '../../stores/gifting';

import heartFillIcon from '../../static/assets/images/heartFill.svg';

const Success = ({ onCancel }) => {
  const dispatch = useDispatch();

  const {
    codeData: { subscriptionPrice },
  } = useSelector(getGiftingPurchaseCodeState);
  const { gifteeEmail } = useSelector(getGiftingData);

  const isLifeTime = subscriptionPrice?.metadata?.slug === 'lifetime_1';
  const planValue = `${
    subscriptionPrice?.metadata?.symbol || ''
  }${roundCentsAmount(subscriptionPrice.amount)}`;

  const sendAnotherGift = () => dispatch(clearGiftingState());

  const Items = ({ title, value, ...props }) => (
    <Flex p="4" justifyContent="space-between" {...props}>
      <Text>{title}</Text>
      <Text>{value}</Text>
    </Flex>
  );

  return (
    <Box>
      <Image marginY="3px" src={heartFillIcon} w="20" mx="auto" mb="8" />
      <Heading
        fontFamily="proxima-soft"
        margin="8px"
        w="60"
        mx="auto"
        mb="6"
        textAlign="center"
      >
        Thank You For Gifting Vooks
      </Heading>
      <Text mb="12" fontFamily="proxima-nova">
        We've sent a confirmation to <strong>{gifteeEmail}</strong>
      </Text>
      <Box rounded="xl" border="1px solid" borderColor="border">
        <Items
          title={`${isLifeTime ? 'Lifetime' : 'Year'} Subscription`}
          value={planValue}
        />
        <Divider />
        <Items title="Payment Method" value="Credit Card" />
        <Divider />
        <Items title="Total Today" value={planValue} fontWeight="bold" />
      </Box>
      <Button
        onClick={sendAnotherGift}
        background="highlight"
        color="white"
        mt="8"
        borderRadius="full"
        fontWeight="bold"
        fontSize="lg"
        w="full"
        size="lg"
        _hover={{ background: 'hover' }}
      >
        Send Another Gift
      </Button>
      <Button
        onClick={onCancel}
        variant="ghost"
        color="playerBg"
        mt="8px"
        borderRadius="full"
        fontWeight="bold"
        fontSize="lg"
        w="full"
        _hover={{ background: 'none' }}
        size="lg"
      >
        All Done
      </Button>
    </Box>
  );
};

Success.propTypes = {
  onCancel: PropTypes.func.isRequired,
};

export default Success;
